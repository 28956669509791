import { BaseApi } from '../../Core/BaseApi';
import { formatForApiUsage } from '../../Core/Date';
import { toEuropeanFormat } from '../../../utils/dates';

class SitterTaxReportApi extends BaseApi {
    getTaxReport = (jwtToken, startDate, endDate) => {
        return this.returnAsByteStream(
            `sitter/tax-report/${formatForApiUsage(startDate)}/${formatForApiUsage(endDate)}`,
            jwtToken,
            `my-pawshake-booking-overview-${toEuropeanFormat(startDate, '-')}-${toEuropeanFormat(endDate, '-')}.pdf`
        );
    };
}

export default new SitterTaxReportApi();
