import { BaseApi } from '../Core/BaseApi';

class FavoritesApi extends BaseApi {
    getFavorites = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/user/favorite-sitters');
    };

    toggleUserFavoriteSitter = (jwtToken, sitterId, isFavorite) => {
        return this.sendCommand(jwtToken, 'Pawshake.User.FavoriteSitter.WriteModel.ToggleUserFavoriteSitter', {
            sitterId: sitterId,
            isFavorite: isFavorite,
        });
    };
}

export default new FavoritesApi();
