import React from 'react';
import first from 'lodash/first';
import last from 'lodash/last';
import FakeInput from '../../Shared/FakeInput';
import { withApplicationAwareness } from '../../../contexts/ApplicationContext';
import { withTranslation } from '../../../i18n';

const MultipleDateFakeInput = ({ t, className, onClick, isOpen, selectedDays, application }) => {
    const renderValue = () => {
        if (selectedDays.length === 0) {
            return t('datePicker.multipleDates.placeholder');
        }
        const { locale } = application;
        return t('datePicker.multipleDates.value', {
            totalDays: selectedDays.length,
            firstDay: first(selectedDays).toLocaleDateString(locale),
            lastDay: last(selectedDays).toLocaleDateString(locale),
        });
    };

    return <FakeInput className={className} value={renderValue()} onClick={onClick} isOpen={isOpen} isSelect={true} />;
};

export default withApplicationAwareness(withTranslation('common')(MultipleDateFakeInput));
