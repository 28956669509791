import { fromJS } from 'immutable';
import { SCREEN_SIZE_CHANGED } from './constants';

export const initialState = fromJS({
    width: undefined,
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case SCREEN_SIZE_CHANGED:
            return state.set('width', payload.width);
        default:
            return state;
    }
};
