import Link from 'next/link';
import styled from 'styled-components';
import { policeCheckFeatureEnabled } from '../../../../../utils/featureFlags';
import PsNavBarLink from '../../templates/navbar-link';

const ProfileActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-left: 1px solid #9bcced; //brand.200 in design-system
    margin-left: 1.75rem;
    padding-left: 0.75rem;
`;

const ProfileSitterActions = ({
    locale,
    isVisible,
    hasACompletedSitterApplication,
    t,
    closeDrawer,
}: {
    locale: string;
    isVisible: boolean;
    hasACompletedSitterApplication: boolean;
    t: (_key: string) => string;
    closeDrawer: () => void;
}) => {
    const items = [
        {
            text: t('profile:profile.navigation.account.aboutMe'),
            href: `/profile/aboutMe?localeString=${locale}`,
            as: '/profile/about-me',
        },
        {
            text: t('profile:profile.navigation.account.myPets'),
            href: `/profile/pets/overview?localeString=${locale}`,
            as: '/profile/my-pets',
        },
        {
            text: t('profile:profile.navigation.sitterListing.address'),
            href: `/profile/address?localeString=${locale}`,
            as: '/profile/address',
        },
        {
            text: t('profile:profile.navigation.account.smsVerification'),
            href: `/profile/smsVerification?localeString=${locale}`,
            as: '/profile/sms-verification',
        },
        {
            text: t('profile:profile.navigation.sitterListing.services'),
            href: '/profile/sitter-listing/services',
        },
        {
            text: t('profile:profile.navigation.sitterListing.petPreferences'),
            href: `/profile/sitterListing/petPreferences?localeString=${locale}`,
            as: '/profile/sitter-listing/pet-preferences',
        },
        {
            text: t('profile:profile.navigation.sitterListing.myHomeAndArea'),
            href: `/profile/sitterListing/myHomeArea?localeString=${locale}`,
            as: '/profile/sitter-listing/my-home-area',
        },
        {
            text: t('profile:profile.navigation.sitterListing.description'),
            href: `/profile/sitterListing/description?localeString=${locale}`,
            as: '/profile/sitter-listing/description',
        },
        {
            text: t('profile:profile.navigation.sitterListing.photos'),
            href: `/profile/sitterListing/profileGallery?localeString=${locale}`,
            as: '/profile/sitter-listing/profile-gallery',
        },
        {
            text: t('profile:profile.navigation.sitterListing.experience'),
            href: `/profile/sitterListing/experience?localeString=${locale}`,
            as: '/profile/sitter-listing/experience',
        },
        {
            text: t('profile:profile.navigation.sitterListing.payoutMethod'),
            href: `/profile/sitterListing/payoutInformation?localeString=${locale}`,
            as: '/profile/sitter-listing/payout-information',
        },
        {
            text: t(
                `profile:profile.navigation.sitterListing.${
                    hasACompletedSitterApplication ? 'finishedSubmission' : 'partiallyFinishedSubmission'
                }`
            ),
            href: `/profile/sitterListing/submission?localeString=${locale}`,
            as: '/profile/sitter-listing/submission',
        },
    ];

    if (policeCheckFeatureEnabled(locale)) {
        items.push({
            text: t('profile:profile.navigation.sitterListing.policeCheck'),
            href: `/profile/sitterListing/policeCheck?localeString=${locale}`,
            as: '/profile/sitter-listing/police-check',
        });
    }

    if (!isVisible) {
        return null;
    }

    return (
        <ProfileActionsWrapper>
            {items.map((item, key) => (
                <Link key={item.text + ' - ' + key} href={item.href} as={item.as} passHref={true}>
                    <PsNavBarLink onClick={closeDrawer}>{item.text}</PsNavBarLink>
                </Link>
            ))}
        </ProfileActionsWrapper>
    );
};

export default ProfileSitterActions;
