import React from 'react';
import styled from 'styled-components';
import Message from './Message';

const RedMessage = styled(Message)`
    color: #eb4c52;
    border: 1px solid #eb4c52;
    background-color: rgba(235, 76, 82, 0.09);
`;

const ErrorMessage = ({ className, error }) => {
    return <RedMessage className={className} message={error} />;
};

export default ErrorMessage;
