import { fromJS } from 'immutable';
import { FAVORITE_SITTERS_FETCHED, TOGGLE_FAVORITE_SITTER_SUCCESS } from './constants';

export const initialState = fromJS({
    favorites: [],
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case FAVORITE_SITTERS_FETCHED:
            return state.set('favorites', fromJS(payload.map((item) => item.sitterId)));
        case TOGGLE_FAVORITE_SITTER_SUCCESS:
            if (payload.isFavorite) {
                return state.set('favorites', fromJS([...state.get('favorites'), payload.sitterId]));
            } else {
                return state.set(
                    'favorites',
                    fromJS(state.get('favorites').filter((item) => item !== payload.sitterId))
                );
            }
        default:
            return state;
    }
};
