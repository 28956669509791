import axios from '../Core/Api';
import { buildCommand } from '../Core/CommandBuilder';

class SitterApi {
    getProfile = (sitterId) => {
        return axios
            .get(`sitter/${sitterId}/profile`)
            .then((response) => {
                return {
                    status: 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                if (!error.response) {
                    return {
                        status: 401,
                    };
                }
                if (error.response.status === 303) {
                    return {
                        status: error.response.status,
                        data: error.response.data,
                    };
                }
                return {
                    status: error.response.status,
                };
            });
    };

    getProfileAccessedByUrl = (sitterId, accessedByUrl) => {
        return axios
            .get(`sitter/${sitterId}/profile?accessedByUrl=${encodeURIComponent(accessedByUrl)}`)
            .then((response) => {
                return {
                    status: 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                if (!error.response) {
                    return {
                        status: 401,
                    };
                }
                if (error.response.status === 303) {
                    return {
                        status: error.response.status,
                        data: error.response.data,
                    };
                }
                return {
                    status: error.response.status,
                };
            });
    };

    getSitterProfileBookableServiceUnavailableDates = (sitterId, bookableService, year, month) => {
        return axios
            .get(`sitter/${sitterId}/profile/unavailable-dates/${bookableService}/${year}/${month}`)
            .then((response) => response.data);
    };

    getReviews = (values) => {
        const { sitterId, pageNumber, pageSize } = values;

        return axios
            .get(`sitter/${sitterId}/reviews?page[number]=${pageNumber}&page[size]=${pageSize}`)
            .then((response) => {
                return {
                    status: 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                if (!error.response) {
                    return {
                        status: 401,
                    };
                }
                return {
                    status: error.response.status,
                };
            });
    };

    registerProfileView = (sitterId) => {
        const command = buildCommand('Pawshake.Core.User.Sitter.RegisterProfileView', {
            sitterId: sitterId,
        });
        axios.post('command/queue', command);
    };
}

export default new SitterApi();
