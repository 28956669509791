import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: absolute;
    height: 100%;
    z-index: 1;
    width: 1.5rem;
    left: 12px;
    top: 12px;
`;

const SearchIcon = () => {
    return (
        <Container>
            <img alt={'location'} width={25} height={25} src={'https://static1.pawshakecdn.com/global/location.svg'} />
        </Container>
    );
};

export default SearchIcon;
