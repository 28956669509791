import { BaseApi } from '../../Core/BaseApi';

class SitterMarketingMaterialsApi extends BaseApi {
    getFlyer = (jwtToken, locale) => {
        return this.returnAsByteStream(`sitter/marketing/flyer/${locale}`, jwtToken, 'my-pawshake-flyer.pdf');
    };

    getBusinessCard = (jwtToken, locale) => {
        return this.returnAsByteStream(
            `sitter/marketing/business-card/${locale}`,
            jwtToken,
            'my-pawshake-business-card.pdf'
        );
    };
}

export default new SitterMarketingMaterialsApi();
