import { createAction } from 'redux-actions';
import {
    DASHBOARD_SITTER_STATISTICS_SUCCESS,
    DASHBOARD_SITTER_STATISTICS_FAILURE,
    DASHBOARD_SITTER_STATISTICS_REQUEST,
    DASHBOARD_NOTIFICATIONS_FAILURE,
    DASHBOARD_NOTIFICATIONS_REQUEST,
    DASHBOARD_NOTIFICATIONS_SUCCESS,
    DASHBOARD_RECENT_SITTERS_REQUEST,
    DASHBOARD_RECENT_SITTERS_FAILURE,
    DASHBOARD_RECENT_SITTERS_SUCCESS,
    DASHBOARD_FAVORITE_SITTERS_FAILURE,
    DASHBOARD_FAVORITE_SITTERS_REQUEST,
    DASHBOARD_FAVORITE_SITTERS_SUCCESS,
    DASHBOARD_SITTER_STATUS_FAILURE,
    DASHBOARD_SITTER_STATUS_SUCCESS,
    DASHBOARD_SITTER_STATUS_REQUEST,
    DASHBOARD_ACTION_CREATED,
    DOWNLOAD_SITTER_MARKET_MATERIALS_REQUEST,
    DOWNLOAD_SITTER_MARKET_MATERIALS_SUCCESS,
    DOWNLOAD_SITTER_MARKET_MATERIALS_FAILURE,
    DASHBOARD_CONVERSATION_PENALTY_SUMMARY_FAILURE,
    DASHBOARD_CONVERSATION_PENALTY_SUMMARY_SUCCESS,
    DASHBOARD_CONVERSATION_PENALTY_SUMMARY_REQUEST,
    DOWNLOAD_SITTER_TAX_REPORT_REQUEST,
    DOWNLOAD_SITTER_TAX_REPORT_SUCCESS,
    DOWNLOAD_SITTER_TAX_REPORT_FAILURE,
    DASHBOARD_REVIEWABLE_BOOKINGS_FAILURE,
    DASHBOARD_REVIEWABLE_BOOKINGS_SUCCESS,
    DASHBOARD_REVIEWABLE_BOOKINGS_REQUEST,
} from './constants';

export const dashboardActionCreated = createAction(DASHBOARD_ACTION_CREATED);

export const dashboardSitterStatusRequest = createAction(DASHBOARD_SITTER_STATUS_REQUEST);
export const dashboardSitterStatusSuccess = createAction(DASHBOARD_SITTER_STATUS_SUCCESS);
export const dashboardSitterStatusFailure = createAction(DASHBOARD_SITTER_STATUS_FAILURE);

export const dashboardConversationPenaltySummaryRequest = createAction(DASHBOARD_CONVERSATION_PENALTY_SUMMARY_REQUEST);
export const dashboardConversationPenaltySummarySuccess = createAction(DASHBOARD_CONVERSATION_PENALTY_SUMMARY_SUCCESS);
export const dashboardConversationPenaltySummaryFailure = createAction(DASHBOARD_CONVERSATION_PENALTY_SUMMARY_FAILURE);

export const dashboardSitterStatisticsRequest = createAction(DASHBOARD_SITTER_STATISTICS_REQUEST);
export const dashboardSitterStatisticsSuccess = createAction(DASHBOARD_SITTER_STATISTICS_SUCCESS);
export const dashboardSitterStatisticsFailure = createAction(DASHBOARD_SITTER_STATISTICS_FAILURE);
export const dashboardNotificationsRequest = createAction(DASHBOARD_NOTIFICATIONS_REQUEST);
export const dashboardNotificationsSuccess = createAction(DASHBOARD_NOTIFICATIONS_SUCCESS);
export const dashboardNotificationsFailure = createAction(DASHBOARD_NOTIFICATIONS_FAILURE);

export const dashboardRecentSittersRequest = createAction(DASHBOARD_RECENT_SITTERS_REQUEST);

export const dashboardRecentSittersSuccess = createAction(DASHBOARD_RECENT_SITTERS_SUCCESS);

export const dashboardRecentSittersFailure = createAction(DASHBOARD_RECENT_SITTERS_FAILURE);

export const dashboardFavoriteSittersRequest = createAction(DASHBOARD_FAVORITE_SITTERS_REQUEST);

export const dashboardFavoriteSittersSuccess = createAction(DASHBOARD_FAVORITE_SITTERS_SUCCESS);

export const dashboardFavoriteSittersFailure = createAction(DASHBOARD_FAVORITE_SITTERS_FAILURE);

export const dashboardReviewableBookingsRequest = createAction(DASHBOARD_REVIEWABLE_BOOKINGS_REQUEST);

export const dashboardReviewableBookingsSuccess = createAction(DASHBOARD_REVIEWABLE_BOOKINGS_SUCCESS);

export const dashboardReviewableBookingsFailure = createAction(DASHBOARD_REVIEWABLE_BOOKINGS_FAILURE);

export const downloadSitterMarketMaterials = createAction(DOWNLOAD_SITTER_MARKET_MATERIALS_REQUEST);

export const downloadSitterMarketMaterialsSucceeded = createAction(DOWNLOAD_SITTER_MARKET_MATERIALS_SUCCESS);

export const downloadSitterMarketMaterialsFailure = createAction(DOWNLOAD_SITTER_MARKET_MATERIALS_FAILURE);

export const downloadSitterTaxReport = createAction(DOWNLOAD_SITTER_TAX_REPORT_REQUEST);

export const downloadSitterTaxReportSucceeded = createAction(DOWNLOAD_SITTER_TAX_REPORT_SUCCESS);

export const downloadSitterTaxReportFailure = createAction(DOWNLOAD_SITTER_TAX_REPORT_FAILURE);
