import Immutable from 'immutable';
import { createSelector } from 'reselect';

const selectRoot = (state) => state.getIn(['domain', 'conversations', 'conversation'], new Immutable.Map());

export const selectConversationTotalMessages = createSelector(selectRoot, (root) => root.get('total'));

export const selectConversationMessages = createSelector(selectRoot, (root) => root.get('messages'));

export const selectConversationParticipants = createSelector(selectRoot, (root) => root.get('participants'));

export const selectHasFinishedInitialRequests = createSelector(
    selectRoot,
    (root) =>
        root.get('participantsRequestStatus') !== undefined &&
        root.get('allowedInteractionsRequestStatus') !== undefined
);

export const selectHasAccessToConversation = createSelector(
    selectRoot,
    (root) => root.get('participantsRequestStatus') === 200 && root.get('allowedInteractionsRequestStatus') === 200
);

export const selectPageNumber = createSelector(selectRoot, (root) => root.get('pageNumber'));

export const selectLoadedConversationId = createSelector(selectRoot, (root) => root.get('loadedConversationId'));

export const selectAllowedInteractions = createSelector(selectRoot, (root) => root.get('allowedInteractions'));

export const selectConversationIsClosed = createSelector(
    selectRoot,
    (root) =>
        root.get('allowedInteractionsRequestStatus') === 200 &&
        root.getIn(['allowedInteractions', 'sendTextMessage']) === false &&
        root.getIn(['allowedInteractions', 'sendImage']) === false
);

export const selectConversationIsArchived = createSelector(
    selectRoot,
    (root) =>
        root.get('allowedInteractionsRequestStatus') === 200 &&
        root.getIn(['allowedInteractions', 'archive']) === false &&
        root.getIn(['allowedInteractions', 'unarchive']) === true
);
