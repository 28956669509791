import { fromJS } from 'immutable';
import { USER_PETS_FAILURE, USER_PETS_REQUEST, USER_PETS_SUCCESS } from '../../User/Pets/constants';

export const initialState = fromJS({
    userPetsFetch: {
        requested: false,
        done: false,
    },
});

export default (state = initialState, { type }) => {
    switch (type) {
        case USER_PETS_REQUEST:
            return state.mergeDeep(
                fromJS({
                    userPetsFetch: {
                        requested: true,
                    },
                })
            );

        case USER_PETS_FAILURE:
        case USER_PETS_SUCCESS:
            return state.mergeDeep(
                fromJS({
                    userPetsFetch: {
                        done: true,
                    },
                })
            );

        default:
            return state;
    }
};
