import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dropdown from '../Shared/Dropdown';
import PetsFakeInput from './PetsFakeInput';
import PetsSelect from './PetsSelect';
import { withTranslation } from '../../i18n';

const Menu = styled.div`
    width: 25rem;
    max-width: 100%;
`;

class PetsDropdown extends Dropdown {
    state = {
        isOpen: false,
    };

    handleClear() {
        const { onChange, handleApply } = this.props;

        onChange('smallDog', 0);
        onChange('mediumDog', 0);
        onChange('largeDog', 0);
        onChange('giantDog', 0);
        onChange('cat', 0);
        onChange('smallAnimal', 0);

        handleApply({
            smallDog: 0,
            mediumDog: 0,
            largeDog: 0,
            giantDog: 0,
            cat: 0,
            smallAnimal: 0,
        });

        super.handleClear();
    }

    handleApply() {
        const { handleApply, smallDog, mediumDog, largeDog, giantDog, cat, smallAnimal } = this.props;

        handleApply({
            smallDog,
            mediumDog,
            largeDog,
            giantDog,
            cat,
            smallAnimal,
        });
        super.handleApply();
    }

    renderInput() {
        const { isOpen } = this.state;
        const { smallDog, mediumDog, largeDog, giantDog, cat, smallAnimal } = this.props;
        const totalPets = smallDog + mediumDog + largeDog + giantDog + cat + smallAnimal;
        return <PetsFakeInput withLabel={false} totalPets={totalPets} isOpen={isOpen} />;
    }

    renderMenu() {
        const { onChange, smallDog, mediumDog, largeDog, giantDog, cat, smallAnimal } = this.props;
        return (
            <Menu>
                <PetsSelect
                    onChange={onChange}
                    smallDog={smallDog}
                    mediumDog={mediumDog}
                    largeDog={largeDog}
                    giantDog={giantDog}
                    cat={cat}
                    smallAnimal={smallAnimal}
                />
            </Menu>
        );
    }
}

PetsDropdown.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    handleApply: PropTypes.func.isRequired,
    smallDog: PropTypes.number.isRequired,
    mediumDog: PropTypes.number.isRequired,
    largeDog: PropTypes.number.isRequired,
    giantDog: PropTypes.number.isRequired,
    cat: PropTypes.number.isRequired,
    smallAnimal: PropTypes.number.isRequired,
};

export default withTranslation('common')(PetsDropdown);
