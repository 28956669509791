import { BaseApi } from '../Core/BaseApi';
import { formatForApiUsage } from '../Core/Date';

class BookingApi extends BaseApi {
    getUnconvertedBooking = (jwtToken, sitterId) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/booking/unconverted/${sitterId}`);
    };

    getBookingDetailsSummary = (jwtToken, bookingId) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/booking/${bookingId}/details-summary`);
    };

    getBookingPriceBreakdown = (jwtToken, bookingId) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/booking/${bookingId}/price-breakdown-v2`);
    };

    getBookingCheckoutDetails = (jwtToken, bookingId) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/booking/${bookingId}/checkout-details`);
    };

    getBookableRates = (jwtToken, sitterId) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/sitter/${sitterId}/bookable-rates`);
    };

    getBookingVas = (jwtToken, service, sitterId) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/booking/vas?service=${service}&sitterId=${sitterId}`);
    };

    getBookingDetailsVas = (jwtToken, bookingId) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/booking/${bookingId}/vas`);
    };

    requestBooking = (jwtToken, sitterId, message, bookableService, petIds, dates, suburb, vasIds) => {
        return this.sendCommand(jwtToken, 'Pawshake.Booking.RequestBooking.WriteModel.RequestBooking', {
            sitterId,
            message,
            bookableService: bookableService.id,
            petIds,
            dates: dates.map((date) => formatForApiUsage(date)),
            suburb,
            vasIds,
        });
    };

    acceptBooking = (jwtToken, bookingId) => {
        return this.sendCommand(jwtToken, 'Pawshake.Booking.AcceptBooking.WriteModel.AcceptBooking', { bookingId });
    };

    declineBooking = (jwtToken, bookingId) => {
        return this.sendCommand(jwtToken, 'Pawshake.Booking.DeclineBooking.WriteModel.DeclineBooking', { bookingId });
    };

    declineBookingAsPetOwner = (jwtToken, bookingId) => {
        return this.sendCommand(jwtToken, 'Pawshake.Booking.DeclineBooking.WriteModel.DeclineBookingAsPetOwner', {
            bookingId,
        });
    };

    retryPayout = (jwtToken, bookingId) => {
        return this.sendCommand(jwtToken, 'Pawshake.Booking.BookingPayout.RetryPayoutAsPetSitter', { bookingId });
    };

    preferNotToReviewBooking = (jwtToken, bookingId) => {
        return this.sendCommand(jwtToken, 'Pawshake.Booking.ReviewBooking.WriteModel.PreferNotToReviewBooking', {
            bookingId,
        });
    };

    reviewBooking = (
        jwtToken,
        bookingId,
        comment,
        valueStarRating,
        profileAccuracyStarRating,
        communicationStarRating,
        conscientiousStarRating,
        animalTreatmentStarRating
    ) => {
        return this.sendCommand(jwtToken, 'Pawshake.Booking.ReviewBooking.WriteModel.ReviewBooking', {
            bookingId,
            comment,
            valueStarRating,
            profileAccuracyStarRating,
            communicationStarRating,
            conscientiousStarRating,
            animalTreatmentStarRating,
        });
    };

    giveDiscount = (jwtToken, bookingId, discountPercentage) => {
        return this.sendCommand(jwtToken, 'Pawshake.Booking.GiveDiscount.WriteModel.GiveDiscount', {
            bookingId,
            discountPercentage,
        });
    };

    removeDiscount = (jwtToken, bookingId) => {
        return this.sendCommand(jwtToken, 'Pawshake.Booking.RemoveDiscount.WriteModel.RemoveDiscount', {
            bookingId,
        });
    };

    alterBooking = (jwtToken, bookingId, dateCollection) => {
        return this.sendCommand(jwtToken, 'Pawshake.Booking.AlterBooking.WriteModel.AlterBooking', {
            bookingId,
            dateCollection: dateCollection.map((date) => formatForApiUsage(date)),
        });
    };

    updateVasForBooking = (jwtToken, bookingId, vas) => {
        return this.performPutRequest(
            this.getWorldRegionBasedUponJwtToken(jwtToken),
            `/booking/${bookingId}/vas`,
            {
                items: vas,
            },
            {
                headers: {
                    authorization: `Bearer ${jwtToken}`,
                },
            }
        );
    };

    cancelAndRefundBookingByPetOwner = (jwtToken, bookingId, reason, clarificationForSitter, otherReason) => {
        return this.sendCommand(jwtToken, 'Pawshake.Core.Booking.CancelBooking.CancelAndRefundBookingByPetOwner', {
            bookingId: bookingId,
            reason: reason,
            clarificationForSitter: clarificationForSitter,
            otherReason: otherReason,
        });
    };

    cancelAndPartialRefundBookingByPetOwner = (jwtToken, bookingId, reason, clarificationForSitter, otherReason) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Core.Booking.CancelBooking.CancelAndPartialRefundBookingByPetOwner',
            {
                bookingId: bookingId,
                reason: reason,
                clarificationForSitter: clarificationForSitter,
                otherReason: otherReason,
            }
        );
    };
}

export default new BookingApi();
