import React from 'react';
import FakeInput from '../Shared/FakeInput/FakeInput';
import { withTranslation } from '../../i18n';

const PetsFakeInput = ({ t, className, totalPets, isOpen, withLabel, onClick }) => {
    return (
        <FakeInput
            className={className}
            onClick={onClick}
            label={withLabel ? t('pages.search.filters.overview.labels.pets') : null}
            placeholder={t('pages.search.filters.overview.placeholders.pets')}
            value={
                totalPets
                    ? t(`pages.search.filters.overview.values.pets`, {
                          count: totalPets,
                      })
                    : null
            }
            isSelect={true}
            isOpen={isOpen}
        />
    );
};

export default withTranslation('pages.search.filters')(PetsFakeInput);
