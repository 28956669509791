import { call, fork, put, takeLatest, select } from 'redux-saga/effects';
import { LOG_SITTER_SEARCH_CLICK, SEARCH_REQUEST } from './constants';
import { searchFailure, searchSuccess } from './actions';
import SearchApi from '../../Pawshake/Search/SearchApi';
import { isJwtAuthenticated, jwtTokenSelect } from '../User/Authentication/selectors';
import { selectSitterSearchId } from './selectors';

function* searchFlow({ payload }) {
    const isAuthenticated = yield select(isJwtAuthenticated);

    let response;
    if (isAuthenticated) {
        const jwtToken = yield select(jwtTokenSelect);
        response = yield call(SearchApi.authenticatedSearch, jwtToken, payload);
    } else {
        response = yield call(SearchApi.search, payload);
    }

    if (response.status === 200) {
        yield put(searchSuccess(response));
    } else {
        yield put(searchFailure(response.status));
    }
}

function* searchFlowWatcher() {
    yield takeLatest(SEARCH_REQUEST, searchFlow);
}

function* logSearchSitterClickFlow({ payload }) {
    const sitterSearchId = yield select(selectSitterSearchId);
    if (sitterSearchId) {
        yield call(SearchApi.logSearchSitterClick, sitterSearchId.substring(0, 2), sitterSearchId, payload.sitterId);
    }
}

function* logSearchSitterClickFlowWatcher() {
    yield takeLatest(LOG_SITTER_SEARCH_CLICK, logSearchSitterClickFlow);
}

export default [fork(searchFlowWatcher), fork(logSearchSitterClickFlowWatcher)];
