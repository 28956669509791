import Link from 'next/link';
import PsBadge from '../components/badge';
import { NavBarLink, PsNavBarLinkProps } from './navbar-link';

const PsNavBarIconLink = (props: PsNavBarLinkProps) => {
    const { isMain, badge, href, as, children, ...rest } = props;
    return (
        <Link href={href ?? ''} as={as}>
            <a {...rest} style={{ position: 'relative', width: '2.5rem' }}>
                <NavBarLink className={'icon ' + (isMain ? 'main' : '')}>{children}</NavBarLink>
                {!!badge && badge !== 0 && <PsBadge amount={badge} />}
            </a>
        </Link>
    );
};
export default PsNavBarIconLink;
