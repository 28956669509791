import { fromJS } from 'immutable';
import { SEARCH_HIGHLIGHT_SITTER, SEARCH_SUCCESS, SEARCH_REQUEST } from './constants';

export const initialState = fromJS({
    status: 200,
    canonicalSearchPage: '',
    data: {
        sitters: [],
        total: 0,
        sitterSearchId: '',
    },
    highlightedSitter: 0,
    searchType: '',
    searchInProgress: false,
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case SEARCH_REQUEST:
            return state.set('searchInProgress', true);
        case SEARCH_SUCCESS:
            return state
                .set('searchInProgress', false)
                .setIn(['data', 'sitters'], fromJS(payload.data.sitters))
                .setIn(['data', 'total'], payload.data.total)
                .setIn(['data', 'sitterSearchId'], payload.data.sitterSearchId)
                .set('highlightedSitter', 0);
        case SEARCH_HIGHLIGHT_SITTER:
            return state.set('highlightedSitter', payload);
        default:
            return state;
    }
};
