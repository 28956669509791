import { call, cancelled, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import i18n from '../../../../i18n';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import {
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_REQUEST,
    AVAILABILITY_CALENDAR_SUMMARY_REQUEST,
    BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_REQUEST,
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_REQUEST,
    CALENDAR_AVAILABILITY_DATE_DETAIL_REQUEST,
    CONFIRM_AVAILABILITY_REQUEST,
    CONFIRM_AVAILABILITY_SUCCESS,
    INACTIVE_MODE_SETTING_REQUEST,
    MARK_AS_AVAILABLE_REQUEST,
    MARK_AS_AVAILABLE_SUCCESS,
    MARK_AS_UNAVAILABLE_REQUEST,
    MARK_AS_UNAVAILABLE_SUCCESS,
    MOST_RECENT_AVAILABILITY_UPDATE_REQUEST,
    OFFERED_BOOKABLE_SERVICES_REQUEST,
    ONE_BOOKING_AT_A_TIME_SETTING_REQUEST,
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_REQUEST,
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS,
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST,
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
    TOGGLE_INACTIVE_MODE_REQUEST,
    TOGGLE_INACTIVE_MODE_SUCCESS,
    TOGGLE_ONE_BOOKING_AT_A_TIME_REQUEST,
    TOGGLE_ONE_BOOKING_AT_A_TIME_SUCCESS,
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_REQUEST,
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_SUCCESS,
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST,
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
} from './constants';
import { jwtTokenSelect } from '../../../User/Authentication/selectors';
import {
    bookableServiceRepeatClientsOnlyFailure,
    bookableServiceRepeatClientsOnlySuccess,
    bookableServiceWeekdayAvailableSlotsFailure,
    bookableServiceWeekdayAvailableSlotsSuccess,
    confirmAvailabilityFailure,
    confirmAvailabilitySuccess,
    dashboardSitterAvailabilityCalendarSummaryFailure,
    dashboardSitterAvailabilityCalendarSummaryForBookableServiceFailure,
    dashboardSitterAvailabilityCalendarSummaryForBookableServiceRequest,
    dashboardSitterAvailabilityCalendarSummaryForBookableServiceSuccess,
    dashboardSitterAvailabilityCalendarSummaryRequest,
    dashboardSitterAvailabilityCalendarSummarySuccess,
    dashboardSitterCalendarAvailabilityDateDetailFailure,
    dashboardSitterCalendarAvailabilityDateDetailSuccess,
    inactiveModeSettingFailure,
    inactiveModeSettingSuccess,
    markAsAvailableFailure,
    markAsAvailableSuccess,
    markAsUnavailableFailure,
    markAsUnavailableSuccess,
    mostRecentAvailableUpdateFailure,
    mostRecentAvailableUpdateSuccess,
    offeredBookableServicesFailure,
    offeredBookableServicesSuccess,
    oneBookingAtATimeSettingFailure,
    oneBookingAtATimeSettingSuccess,
    specifyBookableServiceWeekdayAvailableSlotsFailure,
    specifyBookableServiceWeekdayAvailableSlotsSuccess,
    specifyDateRangeAvailableSlotsForBookableServicesFailure,
    specifyDateRangeAvailableSlotsForBookableServicesSuccess,
    toggleInactiveModeFailure,
    toggleInactiveModeSuccess,
    toggleOneBookingAtATimeFailed,
    toggleOneBookingAtATimeSuccess,
    toggleRepeatClientsOnlyForBookableServiceFailure,
    toggleRepeatClientsOnlyForBookableServiceSuccess,
    unspecifyDateRangeAvailableSlotsForBookableServicesFailure,
    unspecifyDateRangeAvailableSlotsForBookableServicesSuccess,
} from './actions';
import DashboardSitterAvailabilityApi from '../../../../Pawshake/Dashboard/Sitter/Availability/DashboardSitterAvailabilityApi';
import { selectShowingBookableService, selectShowingMonthAndYear } from './selectors';

export const showSavingToast = (translationKey = 'common:savingIndication.isSaving', parameters = {}) => {
    const { hide } = cogoToast.loading(i18n.i18n.t(translationKey, parameters), {
        position: 'top-right',
        hideAfter: 0,
    });

    return hide;
};

export const showSuccessToast = (translationKey = 'common:savingIndication.isSaved', parameters = {}) => {
    cogoToast.success(i18n.i18n.t(translationKey, parameters), {
        position: 'top-right',
    });
};

export const showFailedToast = (translationKey = 'common:savingIndication.isFailed', parameters = {}) => {
    cogoToast.error(i18n.i18n.t(translationKey, parameters), {
        position: 'top-right',
    });
};

function* dashboardSitterCalendarAvailabilityDateDetailRequestFlow({ payload }) {
    const { date } = payload;
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardSitterAvailabilityApi.getCalendarAvailabilityDateDetail, jwtToken, date);

    if (response.status === 200) {
        yield put(dashboardSitterCalendarAvailabilityDateDetailSuccess(response.data));
    } else {
        yield put(dashboardSitterCalendarAvailabilityDateDetailFailure());
    }
}

function* dashboardSitterCalendarAvailabilityDateDetailRequestFlowWatcher() {
    yield takeLatest(
        CALENDAR_AVAILABILITY_DATE_DETAIL_REQUEST,
        dashboardSitterCalendarAvailabilityDateDetailRequestFlow
    );
}

function* dashboardSitterCalendarAvailabilitySummaryForBookableServiceRequestFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const { bookableService, date } = payload;

    const response = yield call(
        DashboardSitterAvailabilityApi.getCalendarAvailabilitySummaryForBookableService,
        jwtToken,
        bookableService,
        getYear(date),
        getMonth(date) + 1
    );

    if (response.status === 200) {
        yield put(dashboardSitterAvailabilityCalendarSummaryForBookableServiceSuccess(response.data));
    } else {
        yield put(dashboardSitterAvailabilityCalendarSummaryForBookableServiceFailure());
    }
}

function* dashboardSitterCalendarAvailabilitySummaryForBookableServiceRequestFlowWatcher() {
    yield takeLatest(
        AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_REQUEST,
        dashboardSitterCalendarAvailabilitySummaryForBookableServiceRequestFlow
    );
}

function* dashboardSitterCalendarAvailabilityRequestFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(
        DashboardSitterAvailabilityApi.getCalendarAvailabilitySummary,
        jwtToken,
        getYear(payload),
        getMonth(payload) + 1
    );

    if (response.status === 200) {
        yield put(dashboardSitterAvailabilityCalendarSummarySuccess(response.data));
    } else {
        yield put(dashboardSitterAvailabilityCalendarSummaryFailure());
    }
}

function* dashboardSitterCalendarAvailabilityRequestFlowWatcher() {
    yield takeLatest(AVAILABILITY_CALENDAR_SUMMARY_REQUEST, dashboardSitterCalendarAvailabilityRequestFlow);
}

function* specifyBookableServiceWeekdayAvailableSlotsFlow({ payload }) {
    const hide = showSavingToast();
    try {
        const jwtToken = yield select(jwtTokenSelect);
        const { bookableService, weekday, availableSlots } = payload;
        const response = yield call(
            DashboardSitterAvailabilityApi.specifyBookableServiceWeekdayAvailableSlots,
            jwtToken,
            bookableService,
            weekday,
            availableSlots
        );

        hide();
        if (response.status === 204) {
            yield put(specifyBookableServiceWeekdayAvailableSlotsSuccess());
            showSuccessToast();
        } else {
            yield put(specifyBookableServiceWeekdayAvailableSlotsFailure());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* specifyBookableServiceWeekdayAvailableSlotsFlowWatcher() {
    yield takeEvery(
        SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_REQUEST,
        specifyBookableServiceWeekdayAvailableSlotsFlow
    );
}

function* specifyDateRangeAvailableSlotsForBookableServicesFlow({ payload }) {
    const hide = showSavingToast();

    try {
        const jwtToken = yield select(jwtTokenSelect);
        const { from, till, availableSlotsByBookableService } = payload;

        const response = yield call(
            DashboardSitterAvailabilityApi.specifyDateRangeAvailableSlotsForBookableServices,
            jwtToken,
            from,
            till,
            availableSlotsByBookableService
        );

        hide();
        if (response.status === 204) {
            yield put(specifyDateRangeAvailableSlotsForBookableServicesSuccess());
            showSuccessToast();
        } else {
            yield put(specifyDateRangeAvailableSlotsForBookableServicesFailure());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* specifyDateRangeAvailableSlotsForBookableServicesFlowWatcher() {
    yield takeEvery(
        SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST,
        specifyDateRangeAvailableSlotsForBookableServicesFlow
    );
}

function* unspecifyDateRangeAvailableSlotsForBookableServicesFlow({ payload }) {
    const hide = showSavingToast();

    try {
        const jwtToken = yield select(jwtTokenSelect);
        const { from, till, bookableServices } = payload;

        const response = yield call(
            DashboardSitterAvailabilityApi.unspecifyDateRangeAvailableSlotsForBookableServices,
            jwtToken,
            from,
            till,
            bookableServices
        );

        hide();
        if (response.status === 204) {
            yield put(unspecifyDateRangeAvailableSlotsForBookableServicesSuccess());
            showSuccessToast();
        } else {
            yield put(unspecifyDateRangeAvailableSlotsForBookableServicesFailure());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* unspecifyDateRangeAvailableSlotsForBookableServicesFlowWatcher() {
    yield takeEvery(
        UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST,
        unspecifyDateRangeAvailableSlotsForBookableServicesFlow
    );
}

function* dashboardSitterBookableServiceWeekdayAvailableSlotsRequestFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardSitterAvailabilityApi.getBookableServiceWeekdayAvailableSlots, jwtToken);

    if (response.status === 200) {
        yield put(bookableServiceWeekdayAvailableSlotsSuccess(response.data));
    } else {
        yield put(bookableServiceWeekdayAvailableSlotsFailure());
    }
}

function* dashboardSitterBookableServiceWeekdayAvailableSlotsRequestFlowWatcher() {
    yield takeLatest(
        BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_REQUEST,
        dashboardSitterBookableServiceWeekdayAvailableSlotsRequestFlow
    );
}

function* requestMostRecentAvailabilityUpdateFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardSitterAvailabilityApi.getMostRecentAvailabilityUpdate, jwtToken);

    if (response.status === 200) {
        yield put(mostRecentAvailableUpdateSuccess(response.data));
    } else {
        yield put(mostRecentAvailableUpdateFailure());
    }
}

function* requestMostRecentAvailabilityUpdateFlowWatcher() {
    yield takeLatest(
        [
            MOST_RECENT_AVAILABILITY_UPDATE_REQUEST,
            CONFIRM_AVAILABILITY_SUCCESS,
            UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
            SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS,
            SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
            TOGGLE_ONE_BOOKING_AT_A_TIME_SUCCESS,
            TOGGLE_INACTIVE_MODE_SUCCESS,
            TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_SUCCESS,
        ],
        requestMostRecentAvailabilityUpdateFlow
    );
}

function* reloadCalendarSummaryFlow() {
    const showingMonthAndYear = yield select(selectShowingMonthAndYear);
    const showingBookableService = yield select(selectShowingBookableService);

    if (showingBookableService) {
        yield put(
            dashboardSitterAvailabilityCalendarSummaryForBookableServiceRequest({
                bookableService: showingBookableService,
                date: showingMonthAndYear,
            })
        );
    } else {
        yield put(dashboardSitterAvailabilityCalendarSummaryRequest(showingMonthAndYear));
    }
}

function* reloadCalendarSummaryFlowWatcher() {
    yield takeLatest(
        [
            UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
            SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS,
            SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
            TOGGLE_ONE_BOOKING_AT_A_TIME_SUCCESS,
            TOGGLE_INACTIVE_MODE_SUCCESS,
            MARK_AS_AVAILABLE_SUCCESS,
            MARK_AS_UNAVAILABLE_SUCCESS,
        ],
        reloadCalendarSummaryFlow
    );
}

function* toggleInactiveModeFlow({ payload }) {
    const hide = showSavingToast();

    try {
        const jwtToken = yield select(jwtTokenSelect);
        const response = yield call(DashboardSitterAvailabilityApi.toggleInactiveMode, jwtToken, payload);

        hide();
        if (response.status === 204) {
            yield put(toggleInactiveModeSuccess());
            showSuccessToast();
        } else {
            yield put(toggleInactiveModeFailure());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* toggleInactiveModeFlowWatcher() {
    yield takeEvery(TOGGLE_INACTIVE_MODE_REQUEST, toggleInactiveModeFlow);
}

function* inactiveModeSettingRequestFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardSitterAvailabilityApi.getInactiveModeSetting, jwtToken);
    if (response.status === 200) {
        yield put(inactiveModeSettingSuccess(response.data));
    } else {
        yield put(inactiveModeSettingFailure());
    }
}

function* inactiveModeSettingRequestFlowWatcher() {
    yield takeLatest(INACTIVE_MODE_SETTING_REQUEST, inactiveModeSettingRequestFlow);
}

function* oneBookingAtATimeSettingRequestFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardSitterAvailabilityApi.getOneBookingAtATimeSetting, jwtToken);
    if (response.status === 200) {
        yield put(oneBookingAtATimeSettingSuccess(response.data));
    } else {
        yield put(oneBookingAtATimeSettingFailure());
    }
}

function* oneBookingAtATimeSettingRequestFlowWatcher() {
    yield takeLatest(ONE_BOOKING_AT_A_TIME_SETTING_REQUEST, oneBookingAtATimeSettingRequestFlow);
}

function* toggleOneBookingAtATimeFlow({ payload }) {
    const hide = showSavingToast();

    try {
        const jwtToken = yield select(jwtTokenSelect);
        const response = yield call(DashboardSitterAvailabilityApi.toggleOneBookingAtATime, jwtToken, payload);
        hide();
        if (response.status === 204) {
            yield put(toggleOneBookingAtATimeSuccess());
            showSuccessToast();
        } else {
            yield put(toggleOneBookingAtATimeFailed());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* toggleOneBookingAtATimeFlowWatcher() {
    yield takeEvery(TOGGLE_ONE_BOOKING_AT_A_TIME_REQUEST, toggleOneBookingAtATimeFlow);
}

function* toggleRepeatClientsForBookableServiceFlow({ payload }) {
    const hide = showSavingToast();
    try {
        const { bookableService, enabled } = payload;
        const jwtToken = yield select(jwtTokenSelect);

        const response = yield call(
            DashboardSitterAvailabilityApi.toggleRepeatClientsForBookableService,
            jwtToken,
            bookableService,
            enabled
        );

        hide();

        if (response.status === 204) {
            yield put(toggleRepeatClientsOnlyForBookableServiceSuccess());
            showSuccessToast();
        } else {
            yield put(toggleRepeatClientsOnlyForBookableServiceFailure());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* toggleRepeatClientsForBookableServiceFlowWatcher() {
    yield takeEvery(TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_REQUEST, toggleRepeatClientsForBookableServiceFlow);
}

function* confirmAvailabilityFlow() {
    const hide = showSavingToast();
    try {
        const jwtToken = yield select(jwtTokenSelect);
        const response = yield call(DashboardSitterAvailabilityApi.confirmAvailability, jwtToken);

        hide();

        if (response.status === 204) {
            yield put(confirmAvailabilitySuccess());
            showSuccessToast();
        } else {
            yield put(confirmAvailabilityFailure());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* confirmAvailabilityFlowWatcher() {
    yield takeEvery(CONFIRM_AVAILABILITY_REQUEST, confirmAvailabilityFlow);
}

function* bookableServiceRepeatClientsOnlyFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardSitterAvailabilityApi.getBookableServiceRepeatClientsOnly, jwtToken);

    if (response.status === 200) {
        yield put(bookableServiceRepeatClientsOnlySuccess(response.data));
    } else {
        yield put(bookableServiceRepeatClientsOnlyFailure());
    }
}

function* bookableServiceRepeatClientsOnlyFlowWatcher() {
    yield takeLatest(BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_REQUEST, bookableServiceRepeatClientsOnlyFlow);
}

function* offeredBookableServicesRequestFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardSitterAvailabilityApi.getOfferedBookableServices, jwtToken);
    if (response.status === 200) {
        yield put(offeredBookableServicesSuccess(response.data));
    } else {
        yield put(offeredBookableServicesFailure());
    }
}

function* offeredBookableServicesRequestFlowWatcher() {
    yield takeLatest(OFFERED_BOOKABLE_SERVICES_REQUEST, offeredBookableServicesRequestFlow);
}

function* markAsAvailableRequestFlow({ payload }) {
    const hide = showSavingToast();
    try {
        const jwtToken = yield select(jwtTokenSelect);
        const response = yield call(DashboardSitterAvailabilityApi.markAsAvailable, jwtToken, payload);

        hide();

        if (response.status === 204) {
            yield put(markAsAvailableSuccess());
            showSuccessToast();
        } else {
            yield put(markAsAvailableFailure());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* markAsAvailableRequestFlowWatcher() {
    yield takeLatest(MARK_AS_AVAILABLE_REQUEST, markAsAvailableRequestFlow);
}

function* markAsUnavailableRequestFlow({ payload }) {
    const hide = showSavingToast();
    try {
        const jwtToken = yield select(jwtTokenSelect);
        const response = yield call(DashboardSitterAvailabilityApi.markAsUnavailable, jwtToken, payload);

        hide();
        if (response.status === 204) {
            yield put(markAsUnavailableSuccess());
            showSuccessToast();
        } else {
            yield put(markAsUnavailableFailure());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* markAsUnavailableRequestFlowWatcher() {
    yield takeLatest(MARK_AS_UNAVAILABLE_REQUEST, markAsUnavailableRequestFlow);
}

export default [
    fork(offeredBookableServicesRequestFlowWatcher),
    fork(unspecifyDateRangeAvailableSlotsForBookableServicesFlowWatcher),
    fork(confirmAvailabilityFlowWatcher),
    fork(toggleRepeatClientsForBookableServiceFlowWatcher),
    fork(requestMostRecentAvailabilityUpdateFlowWatcher),
    fork(reloadCalendarSummaryFlowWatcher),
    fork(toggleOneBookingAtATimeFlowWatcher),
    fork(toggleInactiveModeFlowWatcher),
    fork(inactiveModeSettingRequestFlowWatcher),
    fork(oneBookingAtATimeSettingRequestFlowWatcher),
    fork(dashboardSitterCalendarAvailabilityRequestFlowWatcher),
    fork(dashboardSitterCalendarAvailabilityDateDetailRequestFlowWatcher),
    fork(dashboardSitterBookableServiceWeekdayAvailableSlotsRequestFlowWatcher),
    fork(bookableServiceRepeatClientsOnlyFlowWatcher),
    fork(specifyBookableServiceWeekdayAvailableSlotsFlowWatcher),
    fork(dashboardSitterCalendarAvailabilitySummaryForBookableServiceRequestFlowWatcher),
    fork(specifyDateRangeAvailableSlotsForBookableServicesFlowWatcher),
    fork(markAsAvailableRequestFlowWatcher),
    fork(markAsUnavailableRequestFlowWatcher),
];
