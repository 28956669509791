import { createAction } from 'redux-actions';
import {
    INBOX_REQUEST,
    INBOX_SUCCESS,
    INBOX_FAILURE,
    INBOX_ARCHIVE_REQUEST,
    INBOX_ARCHIVE_SUCCESS,
    INBOX_ARCHIVE_FAILURE,
    INBOX_UNREAD_COUNTER_REQUEST,
    INBOX_UNREAD_COUNTER_SUCCESS,
    INBOX_UNREAD_COUNTER_FAILURE,
} from './constants';

export const inboxRequest = createAction(INBOX_REQUEST);
export const inboxSuccess = createAction(INBOX_SUCCESS);
export const inboxFailure = createAction(INBOX_FAILURE);

export const inboxArchiveRequest = createAction(INBOX_ARCHIVE_REQUEST);
export const inboxArchiveSuccess = createAction(INBOX_ARCHIVE_SUCCESS);
export const inboxArchiveFailure = createAction(INBOX_ARCHIVE_FAILURE);

export const inboxUnreadCounterRequest = createAction(INBOX_UNREAD_COUNTER_REQUEST);
export const inboxUnreadCounterSuccess = createAction(INBOX_UNREAD_COUNTER_SUCCESS);
export const inboxUnreadCounterFailure = createAction(INBOX_UNREAD_COUNTER_FAILURE);
