import React, { PureComponent } from 'react';
import Service from './Service';
import styled from 'styled-components';

const Option = styled.div`
    margin-bottom: 0;
    padding: 1rem;
    position: relative;

    &:hover {
        cursor: pointer;
    }
`;

class ServiceSelectOption extends PureComponent {
    render() {
        const { service, checked, onClick } = this.props;

        return (
            <Option key={'service_select_option_' + service.id}>
                <Service service={service} onClick={onClick}>
                    <Service.Icon />
                    <Service.LabelWithShortDescriptionUnderneath />
                    {checked && <Service.Checked />}
                </Service>
            </Option>
        );
    }
}

export default ServiceSelectOption;
