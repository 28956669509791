import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Breakpoints from '../../../../../styles/breakpoints';
import { HelpIcon, LoginIcon, RegisterIcon, SearchIcon } from '../../../../../styles/icons';
import PsNavBarButton from '../../templates/navbar-button';
import PsNavBarLink from '../../templates/navbar-link';
import PsDivider from '../divider';
import PsNavBarBecomeSitterLink from '../navbar/become-sitter-link';

const Divider = styled.div`
    display: block;

    @media ${Breakpoints.md} {
        display: none;
    }
`;
const AnonymousActions = ({
    t,
    locale,
    zendeskLanguage,
    trackEvent,
    openSearch,
    closeDrawer,
}: {
    locale: string;
    zendeskLanguage: string;
    openSearch: () => void;
    closeDrawer: () => void;
    trackEvent: (fsCategory: any, gaCategory: any, action: any, eventLabel: any) => void;
    t: (key: string) => string;
}) => {
    const router = useRouter();
    const rawDestination = router.query.destination ?? router.asPath;
    const destination = encodeURIComponent(rawDestination as string);

    const openSearchAndTrackEvent = () => {
        openSearch();
        trackEvent('SEARCH_SITTERS_HEADER_ANONYMOUS', 'Homepage - Search Popup', 'Search', 'Anonymous');
    };

    const onSearchButtonClick = () => {
        openSearchAndTrackEvent();
        closeDrawer();
    };

    return (
        <>
            <PsNavBarButton isMain={true} onClick={onSearchButtonClick}>
                <div className='icon_wrapper'>
                    <SearchIcon />
                </div>
                {t('user.menu.searchSitters')}
            </PsNavBarButton>
            <PsNavBarBecomeSitterLink t={t} closeDrawer={closeDrawer} />
            <Link href={`/user/sign-up?destination=${destination}`} passHref={true}>
                <PsNavBarLink onClick={closeDrawer}>
                    <div className='icon_wrapper'>
                        <RegisterIcon />
                    </div>
                    {t('user.menu.register')}
                </PsNavBarLink>
            </Link>
            <Link href={`/user/login?destination=${destination}`} passHref={true}>
                <PsNavBarLink onClick={closeDrawer}>
                    <div className='icon_wrapper'>
                        <LoginIcon />
                    </div>
                    {t('user.menu.login')}
                </PsNavBarLink>
            </Link>
            <Divider>
                <PsDivider />
            </Divider>
            <Link href={`https://pawshake.zendesk.com/hc/${zendeskLanguage}`} passHref={true}>
                <PsNavBarLink onClick={closeDrawer} title={t('user.menu.help')}>
                    <div className='icon_wrapper'>
                        <HelpIcon />
                    </div>
                    {t('user.menu.help')}
                </PsNavBarLink>
            </Link>
        </>
    );
};

export default AnonymousActions;
