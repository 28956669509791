import { delay } from 'redux-saga';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { trackConversationEvent } from '../../Application/Conversation/Analytics';
import ConversationApi from '../../Pawshake/Conversation/ConversationApi';
import { i18n } from '../../i18n';
import { trackException } from '../../lib/analytics';
import { AnalyticKeys } from '../../utils/constants/analytics.constants';
import { showFailedToast } from '../../utils/toasts';
import {
    ACCEPT_BOOKING_SUCCESS,
    ALTER_BOOKING_SUCCESS,
    CANCEL_BOOKING_SUCCESS,
    DECLINE_BOOKING_SUCCESS,
    GIVE_DISCOUNT_SUCCESS,
    REMOVE_DISCOUNT_SUCCESS,
} from '../Booking/constants';
import { jwtTokenSelect } from '../User/Authentication/selectors';
import {
    archiveConversationFailure,
    archiveConversationSuccess,
    conversationAllowedInteractionsFailure,
    conversationAllowedInteractionsRequest,
    conversationAllowedInteractionsSuccess,
    conversationMessagesFailure,
    conversationMessagesSuccess,
    conversationParticipantsFailure,
    conversationParticipantsSuccess,
    sendImageFailure,
    sendImageSuccess,
    sendTextMessageFailure,
    sendTextMessageSuccess,
    unarchiveConversationFailure,
    unarchiveConversationSuccess,
} from './actions';
import {
    ARCHIVE_CONVERSATION_REQUEST,
    ARCHIVE_CONVERSATION_SUCCESS,
    CONVERSATION_ALLOWED_INTERACTIONS_REQUEST,
    CONVERSATION_MESSAGES_REQUEST,
    CONVERSATION_PARTICIPANTS_REQUEST,
    SEND_IMAGE_REQUEST,
    SEND_IMAGE_SUCCESS,
    SEND_TEXT_MESSAGE_REQUEST,
    SEND_TEXT_MESSAGE_SUCCESS,
    UNARCHIVE_CONVERSATION_REQUEST,
    UNARCHIVE_CONVERSATION_SUCCESS,
} from './constants';
import { selectConversationIsArchived } from './selectors';
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const newrelic = require('newrelic');

function* conversationMessagesRequestFlow({ payload }) {
    const isInitialPageOfMessages = payload.pageNumber === 1;
    const jwtToken = yield select(jwtTokenSelect);

    if (isInitialPageOfMessages) {
        trackConversationEvent('Messages', 'Request attempting');
    }

    const response = yield call(
        ConversationApi.getConversationMessages,
        jwtToken,
        payload.conversationId,
        payload.pageNumber,
        payload.pageSize,
        payload.latestMessageId
    );

    if (response.status === 200) {
        trackConversationEvent('Messages', 'Request successful');

        yield put(conversationMessagesSuccess(response.data));
    } else {
        yield put(conversationMessagesFailure(response.status));
        if (isInitialPageOfMessages) {
            trackConversationEvent('Messages', 'Request failed');
        }
    }
}

function* conversationMessagesRequestFlowWatcher() {
    yield takeLatest([CONVERSATION_MESSAGES_REQUEST], conversationMessagesRequestFlow);
}

function* conversationParticipantsRequest({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    trackConversationEvent('Participants', 'Request attempting');

    const response = yield call(ConversationApi.getConversationParticipants, jwtToken, payload.conversationId);

    if (response.status === 200) {
        yield put(conversationParticipantsSuccess(response.data));
        trackConversationEvent('Participants', 'Request successful');
    } else {
        yield put(conversationParticipantsFailure(response.status));
        trackConversationEvent('Participants', 'Request failed');
    }
}

function* conversationParticipantsRequestFlowWatcher() {
    yield takeLatest(CONVERSATION_PARTICIPANTS_REQUEST, conversationParticipantsRequest);
}

function* conversationAllowedInteractionsRequestFlow({ type, payload }) {
    if (type === ARCHIVE_CONVERSATION_SUCCESS || UNARCHIVE_CONVERSATION_SUCCESS === type) {
        if (payload.origin !== 'conversationDetail') {
            return;
        }
    }

    const jwtToken = yield select(jwtTokenSelect);
    trackConversationEvent('Allowed interactions', 'Request attempting');

    const response = yield call(ConversationApi.getConversationAllowedInteractions, jwtToken, payload.conversationId);

    if (response.status === 200) {
        yield put(conversationAllowedInteractionsSuccess(response.data));
        trackConversationEvent('Allowed interactions', 'Request successful');
    } else {
        yield put(conversationAllowedInteractionsFailure(response.status));
        trackConversationEvent('Allowed interactions', 'Request failed');
    }
}

function* conversationAllowedInteractionsRequestFlowWatcher() {
    yield takeLatest(
        [CONVERSATION_ALLOWED_INTERACTIONS_REQUEST, ARCHIVE_CONVERSATION_SUCCESS, UNARCHIVE_CONVERSATION_SUCCESS],
        conversationAllowedInteractionsRequestFlow
    );
}

function* sendTextMessageRequestFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    trackConversationEvent('Interaction: text message', 'Request attempting');

    try {
        const response = yield call(
            ConversationApi.sendTextMessage,
            jwtToken,
            payload.conversationId,
            payload.message,
            payload.recipientUserId
        );

        if (response.status === 200) {
            yield put(sendTextMessageSuccess({ message: response.data, uuid: payload.uuid }));
            trackConversationEvent('Interaction: text message', 'Request successful');
        } else {
            trackConversationEvent('Interaction: text message', 'Request failed');
            trackException(response.data);
            payload.trackingService.trackEvent(AnalyticKeys.SEND_TEXT_MESSAGE_REQUEST, 'Message could not be sent', {
                status: response.status,
                data: response.data,
            });
            yield delay(300);
            yield put(sendTextMessageFailure({ uuid: payload.uuid }));

            if (response.data === 'Network Error') {
                showFailedToast(i18n.t('common:errors.errorPage.networkError'));
            } else {
                showFailedToast(i18n.t('common:errors.conversation.sendMessage'));
            }
        }
    } catch (error) {
        trackConversationEvent('Interaction: text message', 'Request failed catch');
        trackException(error);
        payload.trackingService.trackEvent(AnalyticKeys.SEND_TEXT_MESSAGE_FAILURE, 'SendMessage request failed', {
            error: error,
        });
        yield delay(300);
        yield put(sendTextMessageFailure({ uuid: payload.uuid }));
        showFailedToast(i18n.t('common:errors.error'));
    }
}

function* sendTextMessageRequestFlowWatcher() {
    yield takeLatest(SEND_TEXT_MESSAGE_REQUEST, sendTextMessageRequestFlow);
}

function* sendImageRequestFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);

    trackConversationEvent('Interaction: image', 'Request attempting');

    const response = yield call(ConversationApi.sendImage, jwtToken, payload.conversationId, payload.image);

    if (response.status === 200) {
        yield put(sendImageSuccess({ message: response.data, uuid: payload.uuid }));
        trackConversationEvent('Interaction: image', 'Request successful');
    } else {
        trackConversationEvent('Interaction: image', 'Request failed');
        trackException(response.data);
        yield put(sendImageFailure({ uuid: payload.uuid }));
    }
}

function* sendImageRequestFlowWatcher() {
    yield takeLatest(SEND_IMAGE_REQUEST, sendImageRequestFlow);
}

function* archiveConversationRequestFlow({ payload }) {
    trackConversationEvent('Interaction: archive', 'Request attempt');
    const jwtToken = yield select(jwtTokenSelect);

    const response = yield call(ConversationApi.archiveConversation, jwtToken, payload.conversationId);

    if (response.status === 204) {
        yield put(
            archiveConversationSuccess({
                conversationId: payload.conversationId,
                origin: payload.origin,
                originParameters: payload.originParameters,
            })
        );
        trackConversationEvent('Interaction: archive', 'Request successful');
    } else {
        trackConversationEvent('Interaction: archive', 'Request failed');
        trackException(response.data);
        yield put(archiveConversationFailure());
    }
}

function* archiveConversationRequestFlowWatcher() {
    yield takeLatest(ARCHIVE_CONVERSATION_REQUEST, archiveConversationRequestFlow);
}

function* unarchiveConversationRequestFlow({ payload }) {
    trackConversationEvent('Interaction: unarchive', 'Request attempt');
    const jwtToken = yield select(jwtTokenSelect);

    const response = yield call(ConversationApi.unarchiveConversation, jwtToken, payload.conversationId);

    if (response.status === 204) {
        yield put(
            unarchiveConversationSuccess({
                conversationId: payload.conversationId,
                origin: payload.origin,
                originParameters: payload.originParameters,
            })
        );
        trackConversationEvent('Interaction: unarchive', 'Request successful');
    } else {
        trackConversationEvent('Interaction: unarchive', 'Request failed');
        trackException(response.data);
        yield put(unarchiveConversationFailure());
    }
}

function* unarchiveConversationRequestFlowWatcher() {
    yield takeLatest(UNARCHIVE_CONVERSATION_REQUEST, unarchiveConversationRequestFlow);
}

function* triggerAllowedInteractionsRefreshFlow({ payload }) {
    const conversationIsArchived = yield select(selectConversationIsArchived);

    if (conversationIsArchived) {
        yield put(
            conversationAllowedInteractionsRequest({
                conversationId: payload.conversationId ?? payload,
            })
        );
    }
}

function* triggerAllowedInteractionsRefreshFlowWatcher() {
    yield takeLatest(
        [
            SEND_IMAGE_SUCCESS,
            SEND_TEXT_MESSAGE_SUCCESS,
            ACCEPT_BOOKING_SUCCESS,
            ALTER_BOOKING_SUCCESS,
            CANCEL_BOOKING_SUCCESS,
            DECLINE_BOOKING_SUCCESS,
            GIVE_DISCOUNT_SUCCESS,
            REMOVE_DISCOUNT_SUCCESS,
        ],
        triggerAllowedInteractionsRefreshFlow
    );
}

export default [
    fork(conversationMessagesRequestFlowWatcher),
    fork(conversationAllowedInteractionsRequestFlowWatcher),
    fork(conversationParticipantsRequestFlowWatcher),
    fork(sendTextMessageRequestFlowWatcher),
    fork(sendImageRequestFlowWatcher),
    fork(archiveConversationRequestFlowWatcher),
    fork(unarchiveConversationRequestFlowWatcher),
    fork(triggerAllowedInteractionsRefreshFlowWatcher),
];
