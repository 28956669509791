import axios from '../Core/Api';
import uniq from 'lodash/uniq';
import { CoreApi } from '../../api/CoreApi';

class SearchApi extends CoreApi {
    buildData = (values) => {
        const { address, googlePlaceId, boundingBox, locale, page, location, usedSearchPageUrl, sitterSearchId } =
            values;

        let data = {
            locale: locale.toString(),
            filters: this.buildFilters(values),
            page: page,
        };

        if (usedSearchPageUrl) {
            data.usedSearchPageUrl = usedSearchPageUrl;
        }

        if (sitterSearchId) {
            data.sitterSearchId = sitterSearchId;
        }

        if (address) {
            data.address = address;
        }

        if (location) {
            data.location = location;
        }

        if (googlePlaceId) {
            data.googlePlaceId = googlePlaceId;
        }

        if (boundingBox) {
            data.boundingBox = boundingBox;
        }

        return data;
    };

    buildFilters = (values) => {
        const {
            service,
            selectedDays,
            smallDog,
            mediumDog,
            largeDog,
            giantDog,
            cat,
            smallAnimal,
            sitterHasNoDogs,
            sitterHasNoChildren,
            sitterHasFullyFencedBackyard,
        } = values;

        let filters = {};

        if (service) {
            filters.service = service.id;
        }

        if (selectedDays && selectedDays.length > 0) {
            filters.sitterIsAvailableOnDates = uniq(selectedDays);
        }

        const totalPets = smallDog + mediumDog + largeDog + giantDog + cat + smallAnimal;
        if (totalPets > 0) {
            let petsToBeServiced = {};

            if (smallDog) {
                petsToBeServiced.smallDog = smallDog;
            }

            if (mediumDog) {
                petsToBeServiced.mediumDog = mediumDog;
            }

            if (largeDog) {
                petsToBeServiced.largeDog = largeDog;
            }

            if (giantDog) {
                petsToBeServiced.giantDog = giantDog;
            }

            if (cat) {
                petsToBeServiced.cat = cat;
            }

            if (smallAnimal) {
                petsToBeServiced.smallAnimal = smallAnimal;
            }

            filters.petsToBeServiced = petsToBeServiced;
        }

        const count = [sitterHasNoDogs, sitterHasNoChildren, sitterHasFullyFencedBackyard].filter((x) => x).length;

        if (count > 0) {
            filters.characteristics = [];
            if (sitterHasNoDogs) {
                filters.characteristics.push('sitterHasNoDog');
            }

            if (sitterHasNoChildren) {
                filters.characteristics.push('sitterHasNoChildren');
            }

            if (sitterHasFullyFencedBackyard) {
                filters.characteristics.push('sitterHasFullyFencedBackyard');
            }
        }

        return filters;
    };

    logSearchSitterClick = (wr, sitterSearchId, sitterId) => {
        return this.sendAnonQueueCommand(wr, 'Pawshake.Search.SearchResult.Tracking.LogSitterSearchResultClick', {
            sitterSearchId,
            sitterId,
        });
    };

    search = (values) => {
        return axios
            .post('search', this.buildData(values))
            .then((response) => {
                return {
                    status: 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: error.response.status,
                    data: {},
                };
            });
    };

    authenticatedSearch = (jwtToken, values) => {
        return axios
            .post('authenticated-search', this.buildData(values), {
                headers: {
                    authorization: `Bearer ${jwtToken}`,
                },
            })
            .then((response) => {
                return {
                    status: 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: error.response.status,
                    data: {},
                };
            });
    };
}

export default new SearchApi();
