import { select, call, put, takeLatest, fork } from 'redux-saga/effects';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import {
    REGISTER_PROFILE_VIEW,
    SITTER_DETAIL_REQUEST,
    SITTER_DETAIL_REVIEWS_REQUEST,
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_REQUEST,
    SITTER_CAN_BE_CONTACTED_REQUEST,
} from './constants';
import {
    sitterDetailSuccess,
    sitterDetailFailure,
    sitterDetailReviewsSuccess,
    sitterDetailReviewsFailure,
    sitterDetailRedirectToPage,
    sitterProfileBookableServiceUnavailableDatesSuccess,
    sitterProfileBookableServiceUnavailableDatesFailure,
    sitterCanBeContactedSuccess,
    sitterCanBeContactedFailure,
} from './actions';

import sitterApi from '../../Pawshake/Sitter/sitterApi';
import SitterCanBeContactedApi from '../../Pawshake/Sitter/SitterCanBeContactedApi';
import { jwtTokenSelect } from '../User/Authentication/selectors';

function* fetchSitterDetailFlow({ payload }) {
    const { sitterId, accessedByUrl } = payload;
    let response;

    if (__CONFIG__.appendSitterProfileAccessedUrl && accessedByUrl) {
        response = yield call(sitterApi.getProfileAccessedByUrl, sitterId, accessedByUrl);
    } else {
        response = yield call(sitterApi.getProfile, sitterId);
    }

    switch (response.status) {
        case 200:
            yield put(sitterDetailSuccess(response));
            return;
        case 303:
            yield put(sitterDetailRedirectToPage(response));
            return;
        default:
            yield put(sitterDetailFailure(response));
            return;
    }
}

function* fetchSitterDetailFlowWatcher() {
    yield takeLatest(SITTER_DETAIL_REQUEST, fetchSitterDetailFlow);
}

function* fetchSitterDetailReviewsFlow({ payload }) {
    const response = yield call(sitterApi.getReviews, payload);

    if (response.status === 200) {
        yield put(sitterDetailReviewsSuccess(response.data));
    } else {
        yield put(sitterDetailReviewsFailure(response.error));
    }
}

function* fetchSitterDetailReviewsFlowWatcher() {
    yield takeLatest(SITTER_DETAIL_REVIEWS_REQUEST, fetchSitterDetailReviewsFlow);
}

function* registerProfileViewFlow({ payload }) {
    const { sitterId } = payload;
    yield call(sitterApi.registerProfileView, sitterId);
}

function* registerProfileViewFlowWatcher() {
    yield takeLatest(REGISTER_PROFILE_VIEW, registerProfileViewFlow);
}

function* sitterCanBeContactedRequestFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(SitterCanBeContactedApi.byPetOwner, jwtToken, payload.sitterId);

    if (response.status === 200) {
        yield put(sitterCanBeContactedSuccess(response.data));
    } else {
        yield put(sitterCanBeContactedFailure(response.data));
    }
}

function* sitterCanBeContactedRequestFlowWatcher() {
    yield takeLatest(SITTER_CAN_BE_CONTACTED_REQUEST, sitterCanBeContactedRequestFlow);
}

function* sitterProfileBookableServiceUnavailableDatesRequestFlow({ payload }) {
    try {
        const { sitterId, date, bookableService } = payload;

        const data = yield call(
            sitterApi.getSitterProfileBookableServiceUnavailableDates,
            sitterId,
            bookableService,
            getYear(date),
            getMonth(date) + 1
        );
        yield put(sitterProfileBookableServiceUnavailableDatesSuccess(data));
    } catch (e) {
        yield put(sitterProfileBookableServiceUnavailableDatesFailure());
    }
}

function* sitterProfileBookableServiceUnavailableDatesRequestFlowWatcher() {
    yield takeLatest(
        SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_REQUEST,
        sitterProfileBookableServiceUnavailableDatesRequestFlow
    );
}

export default [
    fork(sitterProfileBookableServiceUnavailableDatesRequestFlowWatcher),
    fork(sitterCanBeContactedRequestFlowWatcher),
    fork(fetchSitterDetailFlowWatcher),
    fork(fetchSitterDetailReviewsFlowWatcher),
    fork(registerProfileViewFlowWatcher),
];
