import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ENRICH_USERS_WITH_DETAILS_REQUEST, USER_JWT_TOKEN_SUCCESS, USER_LOGOUT_REQUEST } from './constants';
import { enrichUsersWithDetailsFailure, enrichUsersWithDetailsSuccess, userLogOutSuccess } from './actions';
import userApi from '../../../Pawshake/User/userApi';
import { jwtTokenSelect } from './selectors';
import authenticationApi from '../../../api/AuthenticationApi';

function* logoutFlow() {
    sessionStorage.removeItem('hasNotifiedApiAboutSignIn');
    yield put(userLogOutSuccess());
}

function* logoutFlowWatcher() {
    yield takeLatest(USER_LOGOUT_REQUEST, logoutFlow);
}

function* enrichUsersWithDetailsRequestFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(userApi.enrichUsers, jwtToken, payload);

    if (response.status === 200) {
        yield put(enrichUsersWithDetailsSuccess(response.data));
    } else {
        yield put(enrichUsersWithDetailsFailure());
    }
}

function* enrichUsersWithDetailsRequestFlowWatcher() {
    yield takeLatest(ENRICH_USERS_WITH_DETAILS_REQUEST, enrichUsersWithDetailsRequestFlow);
}

function* notifyAboutSignInFlow() {
    const hasNotifiedApiAboutSignIn = sessionStorage.getItem('hasNotifiedApiAboutSignIn') || false;
    if (hasNotifiedApiAboutSignIn === false) {
        const jwtToken = yield select(jwtTokenSelect);
        const response = yield call(authenticationApi.notifyUserSignedIn, jwtToken);
        if (response.status === 204) {
            sessionStorage.setItem('hasNotifiedApiAboutSignIn', 'true');
        }
    }
}

function* notifyAboutSignInFlowWatcher() {
    yield takeLatest(USER_JWT_TOKEN_SUCCESS, notifyAboutSignInFlow);
}

export default [
    fork(enrichUsersWithDetailsRequestFlowWatcher),
    fork(logoutFlowWatcher),
    fork(notifyAboutSignInFlowWatcher),
];
