import { fromJS } from 'immutable';
import { FEATURED_TOP_REVIEW_SUCCESS, TOP_REVIEWS_SERVICE_SUCCESS, TOP_REVIEWS_SUCCESS } from './constants';

export const initialState = fromJS({
    featuredTopReview: {},
    byDomain: {
        reviews: [],
        total: 0,
        aggregatedRating: {
            ratingValue: 0,
            reviewCount: 0,
            bestRating: 0,
            worstRating: 0,
        },
    },
    byDomainAndService: [],
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case FEATURED_TOP_REVIEW_SUCCESS:
            return state.set('featuredTopReview', fromJS(payload));
        case TOP_REVIEWS_SUCCESS:
            return state
                .setIn(['byDomain', 'reviews'], fromJS(payload.reviews.reviews))
                .setIn(['byDomain', 'total'], fromJS(payload.reviews.total))
                .setIn(['byDomain', 'aggregatedRating'], fromJS(payload.aggregatedRating));
        case TOP_REVIEWS_SERVICE_SUCCESS:
            return state.set('byDomainAndService', fromJS(payload));
        default:
            return state;
    }
};
