import createRequestActionStrings from '../../utils/createRequestActionStrings';

export const [BOOKING_DETAILS_SUMMARY_REQUEST, BOOKING_DETAILS_SUMMARY_SUCCESS, BOOKING_DETAILS_SUMMARY_FAILURE] =
    createRequestActionStrings('BOOKING_DETAILS_SUMMARY');

export const [CANCEL_BOOKING_REQUEST, CANCEL_BOOKING_SUCCESS, CANCEL_BOOKING_FAILURE] =
    createRequestActionStrings('CANCEL_BOOKING');

export const [REQUEST_BOOKING_REQUEST, REQUEST_BOOKING_SUCCESS, REQUEST_BOOKING_FAILURE] =
    createRequestActionStrings('REQUEST_BOOKING');

export const [ACCEPT_BOOKING_REQUEST, ACCEPT_BOOKING_SUCCESS, ACCEPT_BOOKING_FAILURE] =
    createRequestActionStrings('ACCEPT_BOOKING');

export const [RETRY_PAYOUT_REQUEST, RETRY_PAYOUT_SUCCESS, RETRY_PAYOUT_FAILURE] =
    createRequestActionStrings('RETRY_PAYOUT');

export const [DECLINE_BOOKING_REQUEST, DECLINE_BOOKING_SUCCESS, DECLINE_BOOKING_FAILURE] =
    createRequestActionStrings('DECLINE_BOOKING');

export const [REVIEW_BOOKING_REQUEST, REVIEW_BOOKING_SUCCESS, REVIEW_BOOKING_FAILURE] =
    createRequestActionStrings('REVIEW_BOOKING');

export const [
    PREFER_NOT_TO_REVIEW_BOOKING_REQUEST,
    PREFER_NOT_TO_REVIEW_BOOKING_SUCCESS,
    PREFER_NOT_TO_REVIEW_BOOKING_FAILURE,
] = createRequestActionStrings('PREFER_NOT_TO_REVIEW_BOOKING');

export const [ALTER_BOOKING_REQUEST, ALTER_BOOKING_SUCCESS, ALTER_BOOKING_FAILURE] =
    createRequestActionStrings('ALTER_BOOKING');

export const [GIVE_DISCOUNT_REQUEST, GIVE_DISCOUNT_SUCCESS, GIVE_DISCOUNT_FAILURE] =
    createRequestActionStrings('GIVE_DISCOUNT');

export const [REMOVE_DISCOUNT_REQUEST, REMOVE_DISCOUNT_SUCCESS, REMOVE_DISCOUNT_FAILURE] =
    createRequestActionStrings('REMOVE_DISCOUNT');

export const [UNCONVERTED_BOOKING_REQUEST, UNCONVERTED_BOOKING_SUCCESS, UNCONVERTED_BOOKING_FAILURE] =
    createRequestActionStrings('UNCONVERTED_BOOKING');

export const [BOOKABLE_RATES_REQUEST, BOOKABLE_RATES_SUCCESS, BOOKABLE_RATES_FAILURE] =
    createRequestActionStrings('BOOKABLE_RATES');

export const RESET_BOOKING_REQUEST = 'RESET_BOOKING_REQUEST';
