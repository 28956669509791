import * as Sentry from '@sentry/react';
import { fromJS } from 'immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import { combineReducers } from 'redux-immutable';
import createSagaMiddleware, { END } from 'redux-saga';
import deviceReducer from '../Application/Device/reducer';
import blogReducer from '../domain/Blog/reducer';
import bookingEnquiryReducer from '../domain/Booking/Enquiry/reducer';
import bookingReducer from '../domain/Booking/reducer';
import conversationReducer from '../domain/Conversation/reducer';
import dashboardSitterAvailabilityReducer from '../domain/Dashboard/Sitter/Availability/reducer';
import dashBoardReducer from '../domain/Dashboard/reducer';
import favoritesReducer from '../domain/Favorites/reducer';
import inboxReducer from '../domain/Inbox/reducer';
import petReducer from '../domain/Pet/reducer';
import ProfileSitterListingReducer from '../domain/Profile/SitterListing/reducer';
import reviewsReducer from '../domain/Reviews/reducer';
import searchReducer from '../domain/Search/reducer';
import sitterReducer from '../domain/Sitter/reducer';
import userAuthenticationReducer from '../domain/User/Authentication/reducer';
import petsReducer from '../domain/User/Pets/reducer';
import rootSaga from './../domain/sagas';

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { composeWithDevTools } = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

export default (initialState = fromJS({})) => {
    const rootReducer = combineReducers({
        domain: combineReducers({
            search: searchReducer,
            sitter: sitterReducer,
            pet: petReducer,
            profile: combineReducers({
                sitterListing: ProfileSitterListingReducer,
            }),
            user: combineReducers({
                pets: petsReducer,
                authentication: userAuthenticationReducer,
            }),
            reviews: reviewsReducer,
            booking: combineReducers({
                booking: bookingReducer,
                enquiry: bookingEnquiryReducer,
            }),
            conversations: combineReducers({
                conversation: conversationReducer,
            }),
            inbox: inboxReducer,
            blog: blogReducer,
            dashboard: combineReducers({
                general: dashBoardReducer,
                sitter: combineReducers({
                    availability: dashboardSitterAvailabilityReducer,
                }),
            }),
            favorites: favoritesReducer,
        }),
        application: combineReducers({
            device: deviceReducer,
        }),
    });

    const store = createStore(
        rootReducer,
        initialState,
        compose(bindMiddleware([sagaMiddleware]), sentryReduxEnhancer)
    );

    /**
     * next-redux-saga depends on `runSagaTask` and `sagaTask` being attached to the store.
     *
     *   `runSagaTask` is used to rerun the rootSaga on the client when in sync mode (default)
     *   `sagaTask` is used to await the rootSaga task before sending results to the client
     *
     */
    store.runSagaTask = () => {
        store.sagaTask = sagaMiddleware.run(rootSaga);
    };

    store.stopSagaTask = async () => {
        store.dispatch(END);
        await store.sagaTask.done;
    };

    store.runSagaTask();

    return store;
};
