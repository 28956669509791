import styled from 'styled-components';

export const Avatar = styled.div<{ size: string; placeholder: string }>`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    overflow: hidden;
    border-radius: 50%;
    background-size: cover;
    background: url(${(props) => props.placeholder}) center center;
`;

export const AvatarImage = styled.div`
    height: 100%;
    width: 100%;
    background-size: cover;
`;

const PsAvatar = ({ src, label, size }: { src: string; label: string; size: string }) => {
    return (
        <Avatar
            aria-label={label}
            size={size}
            placeholder={'https://static1.pawshakecdn.com/icons/icon-placeholder-user.svg'}
        >
            <AvatarImage style={{ backgroundImage: `url(${src})` }} />
        </Avatar>
    );
};

export default PsAvatar;
