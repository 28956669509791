import { createAction } from 'redux-actions';
import {
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_REQUEST,
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_FAILURE,
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS,
    AVAILABILITY_CALENDAR_SUMMARY_FAILURE,
    AVAILABILITY_CALENDAR_SUMMARY_REQUEST,
    AVAILABILITY_CALENDAR_SUMMARY_SUCCESS,
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_REQUEST,
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_FAILURE,
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST,
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_FAILURE,
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
    CALENDAR_AVAILABILITY_DATE_DETAIL_FAILURE,
    CALENDAR_AVAILABILITY_DATE_DETAIL_SUCCESS,
    CALENDAR_AVAILABILITY_DATE_DETAIL_REQUEST,
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS,
    TOGGLE_ONE_BOOKING_AT_A_TIME_REQUEST,
    TOGGLE_ONE_BOOKING_AT_A_TIME_SUCCESS,
    TOGGLE_ONE_BOOKING_AT_A_TIME_FAILED,
    MOST_RECENT_AVAILABILITY_UPDATE_REQUEST,
    MOST_RECENT_AVAILABILITY_UPDATE_SUCCESS,
    MOST_RECENT_AVAILABILITY_UPDATE_FAILURE,
    CONFIRM_AVAILABILITY_FAILURE,
    CONFIRM_AVAILABILITY_REQUEST,
    CONFIRM_AVAILABILITY_SUCCESS,
    TOGGLE_INACTIVE_MODE_REQUEST,
    TOGGLE_INACTIVE_MODE_SUCCESS,
    TOGGLE_INACTIVE_MODE_FAILED,
    INACTIVE_MODE_SETTING_REQUEST,
    INACTIVE_MODE_SETTING_SUCCESS,
    INACTIVE_MODE_SETTING_FAILURE,
    ONE_BOOKING_AT_A_TIME_SETTING_REQUEST,
    ONE_BOOKING_AT_A_TIME_SETTING_SUCCESS,
    ONE_BOOKING_AT_A_TIME_SETTING_FAILURE,
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_REQUEST,
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_SUCCESS,
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_FAILED,
    BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_REQUEST,
    BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_SUCCESS,
    BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_FAILURE,
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_REQUEST,
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_SUCCESS,
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_FAILURE,
    OFFERED_BOOKABLE_SERVICES_REQUEST,
    OFFERED_BOOKABLE_SERVICES_SUCCESS,
    OFFERED_BOOKABLE_SERVICES_FAILURE,
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_FAILURE,
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST,
    MARK_AS_UNAVAILABLE_REQUEST,
    MARK_AS_AVAILABLE_REQUEST,
    MARK_AS_AVAILABLE_SUCCESS,
    MARK_AS_AVAILABLE_FAILURE,
    MARK_AS_UNAVAILABLE_SUCCESS,
    MARK_AS_UNAVAILABLE_FAILURE,
} from './constants';

export const toggleRepeatClientsOnlyForBookableServiceRequest = createAction(
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_REQUEST
);

export const toggleRepeatClientsOnlyForBookableServiceSuccess = createAction(
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_SUCCESS
);

export const toggleRepeatClientsOnlyForBookableServiceFailure = createAction(
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_FAILED
);

export const oneBookingAtATimeSettingRequest = createAction(ONE_BOOKING_AT_A_TIME_SETTING_REQUEST);

export const oneBookingAtATimeSettingSuccess = createAction(ONE_BOOKING_AT_A_TIME_SETTING_SUCCESS);

export const oneBookingAtATimeSettingFailure = createAction(ONE_BOOKING_AT_A_TIME_SETTING_FAILURE);

export const inactiveModeSettingRequest = createAction(INACTIVE_MODE_SETTING_REQUEST);

export const inactiveModeSettingSuccess = createAction(INACTIVE_MODE_SETTING_SUCCESS);

export const inactiveModeSettingFailure = createAction(INACTIVE_MODE_SETTING_FAILURE);

export const toggleInactiveModeRequest = createAction(TOGGLE_INACTIVE_MODE_REQUEST);

export const toggleInactiveModeSuccess = createAction(TOGGLE_INACTIVE_MODE_SUCCESS);

export const toggleInactiveModeFailure = createAction(TOGGLE_INACTIVE_MODE_FAILED);

export const toggleOneBookingAtATimeRequest = createAction(TOGGLE_ONE_BOOKING_AT_A_TIME_REQUEST);

export const toggleOneBookingAtATimeSuccess = createAction(TOGGLE_ONE_BOOKING_AT_A_TIME_SUCCESS);

export const toggleOneBookingAtATimeFailed = createAction(TOGGLE_ONE_BOOKING_AT_A_TIME_FAILED);

export const confirmAvailabilityRequest = createAction(CONFIRM_AVAILABILITY_REQUEST);

export const confirmAvailabilityFailure = createAction(CONFIRM_AVAILABILITY_FAILURE);

export const confirmAvailabilitySuccess = createAction(CONFIRM_AVAILABILITY_SUCCESS);

export const requestMostRecentAvailabilityUpdate = createAction(MOST_RECENT_AVAILABILITY_UPDATE_REQUEST);

export const mostRecentAvailableUpdateSuccess = createAction(MOST_RECENT_AVAILABILITY_UPDATE_SUCCESS);

export const mostRecentAvailableUpdateFailure = createAction(MOST_RECENT_AVAILABILITY_UPDATE_FAILURE);

export const dashboardSitterAvailabilityCalendarSummaryForBookableServiceRequest = createAction(
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_REQUEST
);

export const dashboardSitterAvailabilityCalendarSummaryForBookableServiceSuccess = createAction(
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_SUCCESS
);

export const dashboardSitterAvailabilityCalendarSummaryForBookableServiceFailure = createAction(
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_FAILURE
);

export const dashboardSitterAvailabilityCalendarSummaryRequest = createAction(AVAILABILITY_CALENDAR_SUMMARY_REQUEST);

export const dashboardSitterAvailabilityCalendarSummaryFailure = createAction(AVAILABILITY_CALENDAR_SUMMARY_FAILURE);

export const dashboardSitterAvailabilityCalendarSummarySuccess = createAction(AVAILABILITY_CALENDAR_SUMMARY_SUCCESS);

export const dashboardSitterCalendarAvailabilityDateDetailRequest = createAction(
    CALENDAR_AVAILABILITY_DATE_DETAIL_REQUEST
);

export const dashboardSitterCalendarAvailabilityDateDetailSuccess = createAction(
    CALENDAR_AVAILABILITY_DATE_DETAIL_SUCCESS
);

export const dashboardSitterCalendarAvailabilityDateDetailFailure = createAction(
    CALENDAR_AVAILABILITY_DATE_DETAIL_FAILURE
);

export const bookableServiceWeekdayAvailableSlotsRequest = createAction(
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_REQUEST
);

export const bookableServiceWeekdayAvailableSlotsSuccess = createAction(
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS
);

export const bookableServiceWeekdayAvailableSlotsFailure = createAction(
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_FAILURE
);

export const bookableServiceRepeatClientsOnlyRequest = createAction(BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_REQUEST);

export const bookableServiceRepeatClientsOnlySuccess = createAction(BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_SUCCESS);

export const bookableServiceRepeatClientsOnlyFailure = createAction(BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_FAILURE);

export const specifyBookableServiceWeekdayAvailableSlots = createAction(
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_REQUEST
);

export const specifyBookableServiceWeekdayAvailableSlotsFailure = createAction(
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_FAILURE
);

export const specifyBookableServiceWeekdayAvailableSlotsSuccess = createAction(
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS
);

export const specifyDateRangeAvailableSlotsForBookableServices = createAction(
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST
);

export const specifyDateRangeAvailableSlotsForBookableServicesSuccess = createAction(
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS
);

export const specifyDateRangeAvailableSlotsForBookableServicesFailure = createAction(
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_FAILURE
);

export const unspecifyDateRangeAvailableSlotsForBookableServices = createAction(
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST
);

export const unspecifyDateRangeAvailableSlotsForBookableServicesSuccess = createAction(
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS
);

export const unspecifyDateRangeAvailableSlotsForBookableServicesFailure = createAction(
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_FAILURE
);

export const offeredBookableServicesRequest = createAction(OFFERED_BOOKABLE_SERVICES_REQUEST);

export const offeredBookableServicesSuccess = createAction(OFFERED_BOOKABLE_SERVICES_SUCCESS);

export const offeredBookableServicesFailure = createAction(OFFERED_BOOKABLE_SERVICES_FAILURE);

export const markAsAvailableRequest = createAction(MARK_AS_AVAILABLE_REQUEST);

export const markAsAvailableSuccess = createAction(MARK_AS_AVAILABLE_SUCCESS);

export const markAsAvailableFailure = createAction(MARK_AS_AVAILABLE_FAILURE);

export const markAsUnavailableRequest = createAction(MARK_AS_UNAVAILABLE_REQUEST);

export const markAsUnavailableSuccess = createAction(MARK_AS_UNAVAILABLE_SUCCESS);

export const markAsUnavailableFailure = createAction(MARK_AS_UNAVAILABLE_FAILURE);
