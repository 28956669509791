import { trackEvent, trackEventV2 } from '../../lib/analytics';

export const trackBookingEvent = (action, eventLabel) => {
    trackEvent('Booking', action, eventLabel);
};

export const trackBookingConfirmed = (bookingAnalyticsProperties, email) => {
    window.dataLayer.push({ ecommerce: null });
    trackEventV2({
        eventName: 'purchase',
        properties: {
            ecommerce: {
                transaction_id: bookingAnalyticsProperties.bookingId,
                value: bookingAnalyticsProperties.bookingPrice.bookingValue,
                currency: bookingAnalyticsProperties.bookingPrice.currency,
                discount: bookingAnalyticsProperties.bookingPrice.discount,
            },
            email: email,
        },
    });
};
