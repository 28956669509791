import createRequestActionStrings from '../../../../utils/createRequestActionStrings';

export const [
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST,
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_FAILURE,
] = createRequestActionStrings('SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES');

export const [
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST,
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
    UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_FAILURE,
] = createRequestActionStrings('UNSPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES');

export const [
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_REQUEST,
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_SUCCESS,
    TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE_FAILED,
] = createRequestActionStrings('TOGGLE_REPEAT_CLIENTS_ONLY_FOR_BOOKABLE_SERVICE');

export const [
    TOGGLE_ONE_BOOKING_AT_A_TIME_REQUEST,
    TOGGLE_ONE_BOOKING_AT_A_TIME_SUCCESS,
    TOGGLE_ONE_BOOKING_AT_A_TIME_FAILED,
] = createRequestActionStrings('TOGGLE_ONE_BOOKING_AT_A_TIME');

export const [TOGGLE_INACTIVE_MODE_REQUEST, TOGGLE_INACTIVE_MODE_SUCCESS, TOGGLE_INACTIVE_MODE_FAILED] =
    createRequestActionStrings('TOGGLE_INACTIVE_MODE');

export const [INACTIVE_MODE_SETTING_REQUEST, INACTIVE_MODE_SETTING_SUCCESS, INACTIVE_MODE_SETTING_FAILURE] =
    createRequestActionStrings('INACTIVE_MODE_SETTING');

export const [
    ONE_BOOKING_AT_A_TIME_SETTING_REQUEST,
    ONE_BOOKING_AT_A_TIME_SETTING_SUCCESS,
    ONE_BOOKING_AT_A_TIME_SETTING_FAILURE,
] = createRequestActionStrings('ONE_BOOKING_AT_A_TIME_SETTING');

export const [
    MOST_RECENT_AVAILABILITY_UPDATE_REQUEST,
    MOST_RECENT_AVAILABILITY_UPDATE_SUCCESS,
    MOST_RECENT_AVAILABILITY_UPDATE_FAILURE,
] = createRequestActionStrings('MOST_RECENT_AVAILABILITY_UPDATE');

export const [CONFIRM_AVAILABILITY_REQUEST, CONFIRM_AVAILABILITY_SUCCESS, CONFIRM_AVAILABILITY_FAILURE] =
    createRequestActionStrings('CONFIRM_AVAILABILITY');

export const [
    AVAILABILITY_CALENDAR_SUMMARY_REQUEST,
    AVAILABILITY_CALENDAR_SUMMARY_SUCCESS,
    AVAILABILITY_CALENDAR_SUMMARY_FAILURE,
] = createRequestActionStrings('AVAILABILITY_CALENDAR_SUMMARY');

export const [OFFERED_BOOKABLE_SERVICES_REQUEST, OFFERED_BOOKABLE_SERVICES_SUCCESS, OFFERED_BOOKABLE_SERVICES_FAILURE] =
    createRequestActionStrings('OFFERED_BOOKABLE_SERVICES');

export const [
    CALENDAR_AVAILABILITY_DATE_DETAIL_REQUEST,
    CALENDAR_AVAILABILITY_DATE_DETAIL_SUCCESS,
    CALENDAR_AVAILABILITY_DATE_DETAIL_FAILURE,
] = createRequestActionStrings('CALENDAR_AVAILABILITY_DATE_DETAIL');

export const [
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_REQUEST,
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS,
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_FAILURE,
] = createRequestActionStrings('BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS');

export const [
    BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_REQUEST,
    BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_SUCCESS,
    BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_FAILURE,
] = createRequestActionStrings('BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY');

export const [
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_REQUEST,
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS,
    SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_FAILURE,
] = createRequestActionStrings('SPECIFY_BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS');

export const [
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_REQUEST,
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_SUCCESS,
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_FAILURE,
] = createRequestActionStrings('AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE');

export const [MARK_AS_AVAILABLE_REQUEST, MARK_AS_AVAILABLE_SUCCESS, MARK_AS_AVAILABLE_FAILURE] =
    createRequestActionStrings('MARK_AS_AVAILABLE');

export const [MARK_AS_UNAVAILABLE_REQUEST, MARK_AS_UNAVAILABLE_SUCCESS, MARK_AS_UNAVAILABLE_FAILURE] =
    createRequestActionStrings('MARK_AS_UNAVAILABLE');
