import { init } from 'next-firebase-auth';
import absoluteUrl from 'next-absolute-url';

const initAuth = () => {
    init({
        authPageURL: ({ ctx }) => {
            const isServerSide = typeof window === 'undefined';
            const origin = isServerSide ? absoluteUrl(ctx.req).origin : window.location.origin;
            const destPath = isServerSide ? ctx.resolvedUrl : window.location.href;
            const destURL = new URL(destPath, origin);
            const path = destPath.replace(origin, '');
            const encodedDestination = encodeURIComponent(destURL);

            if (path === '/sitter/application') {
                return `/user/sign-up?destination=${encodedDestination}`;
            }
            return `/user/login?destination=${encodedDestination}`;
        },
        appPageURL: ({ ctx }) => {
            const isServerSide = typeof window === 'undefined';
            const origin = isServerSide ? absoluteUrl(ctx.req).origin : window.location.origin;
            const params = isServerSide
                ? new URL(ctx.req.url, origin).searchParams
                : new URLSearchParams(window.location.search);

            const dest = params.get('destination')
                ? decodeURIComponent(params.get('destination'))
                : window.location.pathname;

            if (dest === '/user/login' || dest === '/user/sign-up' || dest === '/user/forgot-password') {
                return '/';
            }

            return dest;
        },
        loginAPIEndpoint: '/api/login', // required
        logoutAPIEndpoint: '/api/logout', // required
        onLoginRequestError: (err) => {
            console.error(err);
        },
        onLogoutRequestError: (err) => {
            console.error(err);
        },
        firebaseAdminInitConfig: {
            credential: {
                projectId: process.env.FIREBASE_PROJECT_ID,
                clientEmail: process.env.FIREBASE_CLIENT_EMAIL,
                // The private key must not be accessible on the client side.
                privateKey: process.env.FIREBASE_PRIVATE_KEY,
            },
            databaseURL: process.env.FIREBASE_DATABASE_URL,
        },
        // Use application default credentials (takes precedence over fireaseAdminInitConfig if set)
        // useFirebaseAdminDefaultCredential: true,
        firebaseClientInitConfig: __CONFIG__.firebaseClientConfig,
        cookies: {
            name: 'Pawshake', // required
            // Keys are required unless you set `signed` to `false`.
            // The keys cannot be accessible on the client side.
            keys: [process.env.COOKIE_SECRET_CURRENT, process.env.COOKIE_SECRET_PREVIOUS],
            httpOnly: true,
            maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
            overwrite: true,
            path: '/',
            sameSite: 'strict',
            // convert this to a env variable
            secure: process.env.COOKIE_SECURE, // set this to false in local (non-HTTPS) development
            signed: true,
        },
        onVerifyTokenError: (err) => {
            console.error(err);
        },
        onTokenRefreshError: (err) => {
            console.error(err);
        },
    });
};

export default initAuth;
