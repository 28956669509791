import { fromJS } from 'immutable';
import {
    BOOKING_DETAILS_SUMMARY_SUCCESS,
    BOOKING_DETAILS_SUMMARY_FAILURE,
    CANCEL_BOOKING_FAILURE,
    CANCEL_BOOKING_SUCCESS,
    REQUEST_BOOKING_SUCCESS,
    REQUEST_BOOKING_FAILURE,
    REQUEST_BOOKING_REQUEST,
    UNCONVERTED_BOOKING_SUCCESS,
    UNCONVERTED_BOOKING_FAILURE,
    UNCONVERTED_BOOKING_REQUEST,
    RESET_BOOKING_REQUEST,
    BOOKABLE_RATES_SUCCESS,
    BOOKABLE_RATES_REQUEST,
} from './constants';
import { RESET_CONVERSATION_AND_BOOKING_STORES, SEND_TEXT_MESSAGE_REQUEST } from '../Conversation/constants';

export const initialState = fromJS({
    bookingWebPaymentLink: undefined,
    bookingDetailsSummary: {},
    bookingDetailsSummaryStatus: undefined,
    cancelBookingSucceeded: undefined,
    requestBookingSucceeded: undefined,
    requestBookingConversationId: undefined,
    requestBookingError: false,
    requestBookingErrorMessage: '',
    unconvertedBookingRequest: {
        requested: false,
        done: false,
    },
    unconvertedBooking: {
        hasOne: false,
        data: {},
    },
    bookableRatesRequest: {
        requested: false,
        done: false,
    },
    bookableRates: {},
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case RESET_CONVERSATION_AND_BOOKING_STORES:
            return state
                .set('bookingDetailsSummary', {})
                .set('bookingDetailsSummaryStatus', undefined)
                .set('cancelBookingSucceeded', undefined)
                .set('bookingWebPaymentLink', undefined);

        case BOOKABLE_RATES_REQUEST:
            return state.set(
                'bookableRatesRequest',
                fromJS({
                    requested: true,
                    done: false,
                })
            );
        case BOOKABLE_RATES_SUCCESS:
            return state.set('bookableRates', fromJS(payload)).set(
                'bookableRatesRequest',
                fromJS({
                    requested: true,
                    done: true,
                })
            );
        case UNCONVERTED_BOOKING_REQUEST:
            return state.set(
                'unconvertedBookingRequest',
                fromJS({
                    requested: true,
                    done: false,
                })
            );
        case UNCONVERTED_BOOKING_SUCCESS:
            return state.setIn(['unconvertedBookingRequest', 'done'], true).set('unconvertedBooking', payload);
        case UNCONVERTED_BOOKING_FAILURE:
            return state
                .setIn(['unconvertedBookingRequest', 'done'], true)
                .set('unconvertedBookingRequestStatus', payload);
        case SEND_TEXT_MESSAGE_REQUEST:
            return state.setIn(['bookingDetailsSummary', 'sitterMustRespondTimeLeft'], null);
        case RESET_BOOKING_REQUEST:
            return state
                .set('unconvertedBookingRequest', fromJS({ requested: false, done: false }))
                .set('bookableRatesRequest', fromJS({ requested: false, done: false }))
                .set('requestBookingSucceeded', undefined)
                .set('requestBookingConversationId', undefined)
                .set('requestBookingError', false)
                .set('requestBookingErrorMessage', '');
        case REQUEST_BOOKING_REQUEST:
            return state
                .set('requestBookingSucceeded', undefined)
                .set('requestBookingConversationId', undefined)
                .set('requestBookingError', false)
                .set('requestBookingErrorMessage', '');
        case REQUEST_BOOKING_FAILURE:
            return state
                .set('requestBookingSucceeded', false)
                .set('requestBookingError', true)
                .set('requestBookingErrorMessage', payload);
        case REQUEST_BOOKING_SUCCESS:
            return state.set('requestBookingSucceeded', true).set('requestBookingConversationId', fromJS(payload));
        case CANCEL_BOOKING_SUCCESS:
            return state.set('cancelBookingSucceeded', true);
        case CANCEL_BOOKING_FAILURE:
            return state.set('cancelBookingSucceeded', false);
        case BOOKING_DETAILS_SUMMARY_SUCCESS:
            return state.set('bookingDetailsSummaryStatus', 200).set('bookingDetailsSummary', fromJS(payload));
        case BOOKING_DETAILS_SUMMARY_FAILURE:
            return state.set('bookingDetailsSummaryStatus', payload);
        default:
            return state;
    }
};
