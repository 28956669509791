import { createSelector } from 'reselect';
import Immutable from 'immutable';

export const selectRoot = (state) => state.getIn(['domain', 'inbox'], new Immutable.Map());

export const selectInboxEntries = createSelector(selectRoot, (root) => root.get('inboxEntries'));

export const selectInboxEntriesFetched = createSelector(selectRoot, (root) => root.get('inboxEntriesFetched'));

export const selectInboxPageNumber = createSelector(selectRoot, (root) => root.get('pageNumber'));

export const selectInboxFilter = createSelector(selectRoot, (root) => root.get('inboxFilter'));

export const selectInboxUnreadCounter = createSelector(selectRoot, (root) => root.get('inboxUnreadCounter'));

export const selectInboxTotal = createSelector(selectRoot, (root) => root.get('total'));

const selectInboxArchive = createSelector(selectRoot, (root) => root.get('inboxArchive'));

export const selectInboxArchiveEntries = createSelector(selectInboxArchive, (inboxArchive) =>
    inboxArchive.get('entries')
);

export const selectInboxArchiveEntriesFetched = createSelector(selectInboxArchive, (inboxArchive) =>
    inboxArchive.get('fetched')
);

export const selectInboxArchivePageNumber = createSelector(selectInboxArchive, (inboxArchive) =>
    inboxArchive.get('pageNumber')
);

export const selectInboxArchiveTotal = createSelector(selectInboxArchive, (inboxArchive) => inboxArchive.get('total'));
