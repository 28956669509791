import { Flex } from '@components/flex.component';
import { NewBadge } from '@components/new-badge.component';
import Link from 'next/link';
import { useState } from 'react';
import styled from 'styled-components';
import { FeatureFlagsType } from '../../../../../api/feature-flag.api';
import { withFeatureFlags } from '../../../../../contexts/FeatureFlagContext';
import {
    DropdownIcon,
    HelpIcon,
    InboxIcon,
    LogoutIcon,
    ProfileCalendarIcon,
    ProfileDashboardIcon,
    ProfileFavoritesIcon,
    ProfileFeedbackIcon,
    ProfileIcon,
    ServicesIcon,
} from '../../../../../styles/icons';
import PsNavBarButton from '../../templates/navbar-button';
import PsNavBarLink from '../../templates/navbar-link';
import PsDivider from '../divider';
import ProfileSitterActions from './profile-sitter-actions';

const ProfileButtonWrapper = styled.div`
    display: inline-flex;
    gap: 1rem;
    align-items: center;
`;

const ProfileActions = ({
    application,
    isDropdown = false,
    includeSubmenu = false,
    isASitter,
    messageUnreadCounter,
    hasACompletedSitterApplication,
    t,
    closeDrawer,
    logout,
}: {
    application: any;
    isDropdown?: boolean;
    includeSubmenu?: boolean;
    isASitter: boolean;
    messageUnreadCounter?: number;
    hasACompletedSitterApplication: boolean;
    t: (_key: string) => string;
    closeDrawer: () => void;
    logout: () => void;
    featureFlags: FeatureFlagsType;
}) => {
    const itemClassName = isDropdown ? 'menuItem' : 'drawerItem';
    const [sitterSubMenuVisible, setSitterSubMenuVisible] = useState(false);

    const onLogoutButtonClick = () => {
        logout();
        closeDrawer();
    };

    const onToggleSitterSubmenuClick = () => setSitterSubMenuVisible((previousValue) => !previousValue);

    return (
        <>
            <Link href='/dashboard' passHref={true}>
                <PsNavBarLink className={itemClassName} onClick={closeDrawer}>
                    <div className='icon_wrapper'>
                        <ProfileDashboardIcon />
                    </div>
                    {t('user.menu.dashboard')}
                </PsNavBarLink>
            </Link>
            <Link href={`/inbox?localeString=${application.locale.toString()}`} as={'/inbox'} passHref={true}>
                <PsNavBarLink className={itemClassName} onClick={closeDrawer} badge={messageUnreadCounter}>
                    <div className='icon_wrapper'>
                        <InboxIcon />
                    </div>
                    {t('user.menu.messages')}
                </PsNavBarLink>
            </Link>
            {isASitter && (
                <Link
                    href={`/dashboard/sitter/availability?localeString=${application.locale.toString()}`}
                    as={'/profile/availability'}
                    passHref={true}
                >
                    <PsNavBarLink className={itemClassName} onClick={closeDrawer}>
                        <div className='icon_wrapper'>
                            <ProfileCalendarIcon />
                        </div>
                        {t('user.menu.calendar')}
                    </PsNavBarLink>
                </Link>
            )}
            {isASitter && includeSubmenu ? (
                <>
                    <PsNavBarButton className='dropdown' onClick={onToggleSitterSubmenuClick}>
                        <ProfileButtonWrapper>
                            <div className='icon_wrapper'>
                                <ProfileIcon />
                            </div>
                            {t('user.menu.profile')}
                        </ProfileButtonWrapper>
                        <div className='icon_wrapper'>
                            <DropdownIcon />
                        </div>
                    </PsNavBarButton>
                    <ProfileSitterActions
                        t={t}
                        locale={application.locale.toString()}
                        isVisible={sitterSubMenuVisible}
                        hasACompletedSitterApplication={hasACompletedSitterApplication}
                        closeDrawer={closeDrawer}
                    />
                </>
            ) : (
                <Link
                    href={`/profile/aboutMe?localeString=${application.locale.toString()}`}
                    as={'/profile/about-me'}
                    passHref={true}
                >
                    <PsNavBarLink className={itemClassName} onClick={closeDrawer}>
                        <div className='icon_wrapper'>
                            <ProfileIcon />
                        </div>
                        {t('user.menu.profile')}
                    </PsNavBarLink>
                </Link>
            )}

            {isASitter && (
                <Link href='/profile/sitter-listing/services' passHref={true}>
                    <PsNavBarLink className={itemClassName} onClick={closeDrawer}>
                        <div className='icon_wrapper'>
                            <ServicesIcon />
                        </div>
                        <Flex style={{ justifyContent: 'space-between', width: '100%' }}>
                            {t('user.menu.services')}

                            <NewBadge />
                        </Flex>
                    </PsNavBarLink>
                </Link>
            )}

            <PsNavBarLink href={`/dashboard/favorites`} className={itemClassName} onClick={closeDrawer}>
                <div className='icon_wrapper'>
                    <ProfileFavoritesIcon />
                </div>
                {t('user.menu.favorites')}
            </PsNavBarLink>
            <Link
                href={`/dashboard/reviewableBookingsPage?localeString=${application.locale.toString()}`}
                as={'/dashboard/feedback'}
                passHref={true}
            >
                <PsNavBarLink className={itemClassName} onClick={closeDrawer}>
                    <div className='icon_wrapper'>
                        <ProfileFeedbackIcon />
                    </div>
                    {t('user.menu.feedback')}
                </PsNavBarLink>
            </Link>
            <PsDivider className={isDropdown ? 'divider--gray' : ''} />
            <Link href={`https://pawshake.zendesk.com/hc/${application.zendeskLanguage}`} passHref={true}>
                <PsNavBarLink className={itemClassName} onClick={closeDrawer}>
                    <div className='icon_wrapper'>
                        <HelpIcon />
                    </div>
                    {t('user.menu.help')}
                </PsNavBarLink>
            </Link>
            <PsNavBarButton
                className={itemClassName + ' danger'}
                onClick={onLogoutButtonClick}
                style={{ width: '100%' }}
            >
                <div className='icon_wrapper'>
                    <LogoutIcon />
                </div>
                {t('user.menu.logout')}
            </PsNavBarButton>
        </>
    );
};

export default withFeatureFlags(ProfileActions);
