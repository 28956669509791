import { fromJS } from 'immutable';
import {
    PET_DETAIL_FAILURE,
    PET_DETAIL_SUCCESS,
    ENRICH_PETS_WITH_DETAILS_SUCCESS,
    ENRICH_PETS_WITH_DETAILS_CLEANUP,
} from './constants';

export const initialState = fromJS({
    detail: {},
    status: 200,
    enriched: {
        succeeded: false,
        failed: false,
        data: {},
    },
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case ENRICH_PETS_WITH_DETAILS_CLEANUP:
            return state
                .setIn(['enriched', 'data'], fromJS({}))
                .setIn(['enriched', 'succeeded'], false)
                .setIn(['enriched', 'failed'], false);
        case ENRICH_PETS_WITH_DETAILS_SUCCESS:
            return state.setIn(['enriched', 'succeeded'], true).setIn(['enriched', 'data'], fromJS(payload));
        case PET_DETAIL_SUCCESS:
            return state.set('detail', fromJS(payload.data));
        case PET_DETAIL_FAILURE:
            return state.set('status', fromJS(payload.status));
        default:
            return state;
    }
};
