import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Label = styled.label`
    display: block;
    position: relative;
    line-height: 30px;
    padding-left: calc(30px + 1rem);
    margin-bottom: 1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        height: 1px;
        cursor: pointer;
        touch-action: manipulation;
    }
`;

const Checkmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;

    border: 1px solid ${(props) => (props.checked ? '#00c38a' : '#cecece')};
    border-radius: 8px;

    background: ${(props) =>
        props.checked ? 'url("https://static1.pawshakecdn.com/icons/icon-checkmark.svg") no-repeat center' : 'none'};

    &:after {
        content: '';
        position: absolute;
        display: none;

        left: 11px;
        top: 6px;
        width: 5px;
        height: 10px;
        border: solid #00c38a;
        border-width: 0 4px 4px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &:hover {
        border: 1px solid #00c38a;
    }
`;

const Checkbox = (props) => {
    const { children, name, onChange, required, className, checked } = props;
    const [value, setValue] = useState(checked);
    useEffect(() => {
        setValue(checked);
    }, [checked]);

    const internalOnChange = () => {
        const newValue = !value;
        setValue(newValue);
        if (onChange) {
            onChange({ target: { name, value: newValue } });
        }
    };

    return (
        <div className={className}>
            <Label>
                {children}
                <input required={required} name={name} onChange={internalOnChange} type='checkbox' checked={value} />
                <Checkmark checked={value} />
            </Label>
        </div>
    );
};

export default Checkbox;
