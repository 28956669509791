import {
    EmailAuthProvider,
    FacebookAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    applyActionCode,
    checkActionCode,
    getRedirectResult,
    reauthenticateWithCredential,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
} from 'firebase/auth';
import jwtDecode from 'jwt-decode';
import { auth, onAuthStateChanged } from './firebase';

import * as Sentry from '@sentry/react';
import { AppleLoginButton, GoogleLoginButton, createButton, createSvgIcon } from 'react-social-login-buttons';
import BookingApi from '../Pawshake/Booking/BookingApi';
import UserApi from '../Pawshake/User/userApi';
import AuthenticationApi from '../api/AuthenticationApi';
import SitterApi from '../api/SitterApi';
import { firebaseAuthenticated, userJwtTokenLoading, userJwtTokenSuccess } from '../domain/User/Authentication/actions';
import { i18n } from '../i18n';
import { emailVerificationRequestedLocaleStorageKey } from '../pages/authentication/emailVerificationPage';
import { showFailedToast } from '../utils/toasts';
import { getWorldRegionOfCountry } from '../utils/utils';
import { getFacebookIcon } from './facebook.js';

const config = {
    icon: createSvgIcon(getFacebookIcon),
    iconColor: '#3b5998',
    iconFormat: (name) => `fa fa-${name}`,
    style: { background: '#fff', color: 'black' },
    activeStyle: { background: '#eff0ee' },
};
const CustomFacebookLoginButton = createButton(config);

export const socialLoginProviders = {
    'google.com': {
        provider: () => {
            const provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            return provider;
        },
        providerType: GoogleAuthProvider,
        component: GoogleLoginButton,
        translation: 'user.registration.continueWithGoogle',
        analyticsName: 'google',
    },
    'facebook.com': {
        provider: () => {
            const provider = new FacebookAuthProvider();
            provider.addScope('email');
            provider.addScope('public_profile');
            return provider;
        },
        providerType: FacebookAuthProvider,
        component: CustomFacebookLoginButton,
        translation: 'user.registration.continueWithFacebook',
        analyticsName: 'facebook',
    },
    'apple.com': {
        provider: () => {
            const provider = new OAuthProvider('apple.com');
            provider.addScope('name');
            provider.addScope('email');
            return provider;
        },
        providerType: OAuthProvider,
        component: AppleLoginButton,
        translation: 'user.registration.continueWithApple',
        analyticsName: 'apple',
    },
};

export class AuthService {
    store;
    router;
    trackingService;
    locale;
    language;
    country;
    worldRegion;

    constructor(store, router, trackingService, locale) {
        this.store = store;
        this.router = router;
        this.trackingService = trackingService;

        const languageAndCountry = locale.split('-');
        this.locale = locale;
        this.language = languageAndCountry[0] ?? 'en';
        this.country = languageAndCountry[1] ?? 'GB';
        this.worldRegion = getWorldRegionOfCountry(this.country);

        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.store.dispatch(firebaseAuthenticated(true));
                user.getIdToken(true)
                    .then((token) => {
                        const decodedToken = jwtDecode(token);
                        const query = this.router.query;
                        delete query.localeString;

                        if (!this.doesUserHaveEmailAddress(user)) {
                            // remove the user from the firebase store
                            this.removeUserWithoutEmailAddress();
                            this.store.dispatch(firebaseAuthenticated(false));
                            this.logout(true);
                            showFailedToast(i18n.t('common:finalise.noEmail', {}), () => {
                                this.router.push({
                                    pathname: '/user/sign-up',
                                    query: query,
                                });
                            });
                            return;
                        }

                        if (decodedToken.email_verified === false) {
                            if (
                                this.router.route === '/authentication/emailVerificationPage' ||
                                this.router.route === '/user/sign-up/verify'
                            ) {
                                return;
                            }

                            // bypass verifyEmailFailure so people still so that verification failed and they have to try again.
                            // we can rework this once we look into moving `/user/management` to the `seo` repository
                            if (
                                this.router.route === '/authentication/userManagementPage' &&
                                query.mode === 'verifyEmailFailure'
                            ) {
                                return;
                            }

                            this.router.push({
                                pathname: '/user/sign-up/verify',
                                query: query,
                            });
                            return;
                        }

                        if (typeof decodedToken.wr === 'undefined') {
                            if (
                                this.router.route === '/authentication/finalisePawshakeAccountPage' ||
                                this.router.route === '/user/sign-up/provider'
                            ) {
                                return;
                            }
                            this.router.push({
                                pathname: '/user/sign-up/provider',
                                query: query,
                            });
                            return;
                        }

                        trackingService.identifyUserFromToken(token);
                        this.store.dispatch(userJwtTokenSuccess(token));
                        this.store.dispatch(userJwtTokenLoading(false));
                    })
                    .catch((error) => {
                        switch (error.code) {
                            case 'auth/user-token-expired':
                                this.store.dispatch(userJwtTokenLoading(false));
                                this.store.dispatch(firebaseAuthenticated(false));
                                this.logout();
                                break;
                            default:
                                showFailedToast(i18n.t('user:user.registration.errors.generic', {}));
                                break;
                        }
                    });
            } else {
                this.store.dispatch(userJwtTokenLoading(false));
                this.store.dispatch(firebaseAuthenticated(false));
            }
        });

        // inject the token every minute
        setInterval(() => {
            if (!auth.currentUser) {
                return;
            }
            auth.currentUser.getIdToken().then((token) => {
                this.store.dispatch(userJwtTokenSuccess(token));
            });
        }, 1000 * 60); // every minute
    }

    trackEvent = (category, name, properties = {}) => {
        this.trackingService.trackEvent(category, name, properties);
    };

    trackEventV2 = (event) => {
        this.trackingService.trackEventV2(event);
    };

    redirectResult = () => {
        return getRedirectResult(auth);
    };

    doesUserHaveEmailAddress = (user) => {
        for (const provider of user.providerData) {
            if (provider.email && provider.email.length > 0) {
                return true;
            }
        }
        return false;
    };

    hasEmailLoginOption = () => {
        if (!auth.currentUser) {
            return false;
        }

        for (const provider of auth.currentUser.providerData) {
            if (provider.providerId === 'password') {
                return true;
            }
        }

        return false;
    };

    getEmailAddress = () => {
        if (!auth.currentUser || !auth.currentUser.email) {
            return;
        }

        return auth.currentUser.email;
    };

    getUserId = () => {
        if (!auth.currentUser || !auth.currentUser.email) {
            return;
        }

        return auth.currentUser.uid;
    };

    refreshToken = () => {
        return auth.currentUser.getIdToken(true).then((token) => {
            this.trackingService.identifyUserFromToken(token);
            this.store.dispatch(userJwtTokenSuccess(token));
        });
    };

    getClaims = () => {
        return auth.currentUser.getIdToken(true).then((token) => jwtDecode(token));
    };

    getCountryOfUser = () => {
        return this.getClaims().then((claims) => claims.cc);
    };

    checkActionCode = (oobCode) => {
        return checkActionCode(auth, oobCode);
    };

    applyActionCode = (oobCode) => {
        return applyActionCode(auth, oobCode);
    };

    createUserWithEmailAndPassword = (emailAddress, password) => {
        return AuthenticationApi.createUserWithEmailAndPassword(
            this.worldRegion,
            emailAddress,
            password,
            this.language
        );
    };

    signInWithEmailAndPassword = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
            .then(async (response) => {
                const token = await auth.currentUser.getIdToken();
                this.trackingService.identifyUserFromToken(token);
                return response;
            })
            .catch((error) => {
                switch (error.code) {
                    case 'auth/wrong-password':
                        return this.requestMigrationCheck(email).then((response) => {
                            if (response.status === 200) {
                                return Promise.reject({
                                    code: 'auth/passwordMigrationInitiated',
                                    data: response.data,
                                });
                            } else {
                                return Promise.reject(error);
                            }
                        });
                    default:
                        return Promise.reject(error);
                }
            });
    };

    notifyAboutPasswordMigration = () => {
        return auth.currentUser.getIdToken(true).then((token) => {
            const decodedToken = jwtDecode(token);
            return AuthenticationApi.notifyAboutPasswordMigration(decodedToken.wr ?? 'EU', token);
        });
    };

    requestMigrationCheck = (emailAddress) => {
        return AuthenticationApi.requestMigrationCheck(
            this.worldRegion,
            emailAddress,
            this.language,
            this.getDestinationUrl()
        );
    };

    getDestinationUrl() {
        const currentDomain = window.location.origin;

        if (typeof this.router.query.destination !== 'undefined') {
            const destination = this.router.query.destination;
            if (destination && destination.startsWith(currentDomain) === false) {
                if (destination.startsWith('/') === false) {
                    return currentDomain + '/' + destination;
                }
                return currentDomain + destination;
            }
            return destination;
        }
        return currentDomain;
    }

    logout = (ignoreRedirect) => {
        this.trackingService.shutdown();
        localStorage.removeItem(emailVerificationRequestedLocaleStorageKey);

        if (!ignoreRedirect) {
            this.router.replace('/user/logout');
        }

        return signOut(auth).then(() => {
            if (!ignoreRedirect && this.router.route !== '/homepage') {
                this.router.push('/');
            }
        });
    };

    authenticateWithProvider = (provider, signup, analyticsName) => {
        return signInWithPopup(auth, provider)
            .then(async () => {
                if (signup) {
                    this.trackingService.trackEventV2({
                        eventName: 'register',
                        properties: {
                            loginMethod: analyticsName,
                        },
                    });
                } else {
                    const token = await auth.currentUser.getIdToken();
                    this.trackingService.identifyUserFromToken(token);
                    const event = {
                        eventName: 'login',
                        properties: {
                            loginMethod: analyticsName,
                        },
                    };
                    this.trackingService.trackEventV2(event);
                }
            })
            .catch((error) => {
                Sentry.captureMessage('auth.service - signInWithPopup: ', error);
                showFailedToast(i18n.t('user:user.registration.errors.generic', {}));
            });
    };

    reAuthenticateWithEmailAndPassword = (emailAddress, password) => {
        const credential = EmailAuthProvider.credential(emailAddress, password);
        return reauthenticateWithCredential(auth.currentUser, credential);
    };

    initiateForgotPasswordFlow = (emailAddress) => {
        return AuthenticationApi.initiateForgotPasswordFlow(
            this.worldRegion,
            emailAddress,
            this.locale,
            this.getDestinationUrl()
        );
    };

    verifyForgotPasswordFlow = (forgotPasswordFlowId) => {
        return AuthenticationApi.verifyForgotPasswordFlow(this.worldRegion, forgotPasswordFlowId);
    };

    completeForgotPasswordFlow = (forgotPasswordFlowId, newPassword, verifyPassword) => {
        return AuthenticationApi.completeForgotPasswordFlow(
            this.worldRegion,
            forgotPasswordFlowId,
            newPassword,
            verifyPassword
        );
    };

    updatePassword = (oldPassword, newPassword, verifyNewPassword) => {
        return auth.currentUser.getIdToken().then((token) => {
            const decodedToken = jwtDecode(token);
            return AuthenticationApi.updatePassword(
                decodedToken.wr,
                token,
                oldPassword,
                newPassword,
                verifyNewPassword
            );
        });
    };

    updateEmailAddress = (password, emailAddress, verifyEmailAddress) => {
        return auth.currentUser.getIdToken().then((token) => {
            const decodedToken = jwtDecode(token);
            return AuthenticationApi.updateEmailAddress(
                decodedToken.wr,
                token,
                password,
                emailAddress,
                verifyEmailAddress
            );
        });
    };

    sendEmailVerification = (emailAddress) => {
        return AuthenticationApi.requestEmailVerification(
            this.worldRegion,
            this.locale,
            emailAddress,
            this.getDestinationUrl()
        );
    };

    requestPasswordResetThroughTextMessage = (worldRegion, emailAddress) => {
        return AuthenticationApi.requestPasswordResetThroughTextMessage(worldRegion, emailAddress, this.language);
    };

    verifyPasswordResetThroughTextMessage = (
        worldRegion,
        emailAddress,
        newPassword,
        verifyPassword,
        passwordResetCode
    ) => {
        return AuthenticationApi.verifyPasswordResetThroughTextMessage(
            worldRegion,
            emailAddress,
            newPassword,
            verifyPassword,
            passwordResetCode
        );
    };

    getFinalisePawshakeAccount = () => {
        return auth.currentUser.getIdToken().then((token) => {
            return AuthenticationApi.getFinalisePawshakeAccount(this.worldRegion, token, this.language, this.country);
        });
    };

    removeUserWithoutEmailAddress = () => {
        return auth.currentUser.getIdToken().then((token) => {
            return AuthenticationApi.removeUserWithoutEmailAddress(this.worldRegion, token);
        });
    };

    finalisePawshakeAccount = (
        firstName,
        lastName,
        countryCode,
        language,
        profileImage,
        hasApprovedGdpr,
        userOlderThanAgeLimit
    ) => {
        return auth.currentUser.getIdToken().then((token) => {
            return AuthenticationApi.finalisePawshakeAccount(
                getWorldRegionOfCountry(countryCode),
                token,
                firstName,
                lastName,
                countryCode,
                language,
                profileImage,
                hasApprovedGdpr,
                userOlderThanAgeLimit
            );
        });
    };

    getEmailHandlerCopy = (mode, lang) => {
        return AuthenticationApi.getEmailHandlerCopy(mode, lang);
    };

    getRebookInformation = (petOwnerUserId, bookingId) => {
        return auth.currentUser.getIdToken().then((token) => {
            return SitterApi.getPetOwnerRebookInformation(token, petOwnerUserId, bookingId);
        });
    };

    requestBookingAsSitter = (bookingRequest) => {
        return auth.currentUser.getIdToken().then((token) => {
            return SitterApi.requestBookingAsSitter(token, bookingRequest);
        });
    };

    declineBookingAsPetOwner = (bookingId) => {
        return auth.currentUser.getIdToken().then((token) => {
            return BookingApi.declineBookingAsPetOwner(token, bookingId);
        });
    };

    getToken = () => {
        return auth.currentUser.getIdToken();
    };

    getMinimumProfileRequirementsBanner = () => {
        return this.getClaims().then((claims) => {
            if (claims.mmpr) {
                return Promise.reject({
                    status: 204,
                });
            } else {
                return auth.currentUser.getIdToken().then((token) => {
                    return UserApi.getMinimumProfileRequirementsBanner(token);
                });
            }
        });
    };
}

export default AuthService;
