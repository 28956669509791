import { fromJS } from 'immutable';
import {
    CONVERSATION_ALLOWED_INTERACTIONS_FAILURE,
    CONVERSATION_ALLOWED_INTERACTIONS_SUCCESS,
    CONVERSATION_MESSAGES_REQUEST,
    CONVERSATION_MESSAGES_SUCCESS,
    CONVERSATION_PARTICIPANTS_FAILURE,
    CONVERSATION_PARTICIPANTS_SUCCESS,
    RESET_CONVERSATION_AND_BOOKING_STORES,
    SEND_IMAGE_FAILURE,
    SEND_IMAGE_REQUEST,
    SEND_IMAGE_SUCCESS,
    SEND_TEXT_MESSAGE_FAILURE,
    SEND_TEXT_MESSAGE_REQUEST,
    SEND_TEXT_MESSAGE_SUCCESS,
} from './constants';

export const initialState = fromJS({
    participantsRequestStatus: undefined,
    participants: [],
    allowedInteractionsRequestStatus: undefined,
    allowedInteractions: {
        sendTextMessage: false,
        sendImage: false,
    },
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    firstPageMessages: [],
    messages: [],
});
const ConversationReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case RESET_CONVERSATION_AND_BOOKING_STORES:
            return initialState;
        case SEND_IMAGE_REQUEST:
            return state.set('total', state.get('total') + 1).set('messages', [
                {
                    messageId: payload.uuid,
                    messageContent: payload.image,
                    messageType: 'image',
                    author: payload.author,
                },
                ...state.get('messages'),
            ]);

        case SEND_IMAGE_SUCCESS: {
            const { message, uuid } = payload;

            const messages = [...state.get('messages')];

            // find local message and replace it with the one from the server
            const updatedMessages = messages.map((originalMessage) => {
                if (originalMessage.messageId === uuid) {
                    return { ...originalMessage, ...message };
                }
                return originalMessage;
            });

            return state.set('messages', updatedMessages).set('total', state.get('total') + 1);
        }
        case SEND_IMAGE_FAILURE: {
            const { uuid } = payload;

            const messages = [...state.get('messages')];

            // find local message and replace it with the one from the server
            const updatedMessages = messages.map((originalMessage) => {
                if (originalMessage.messageId === uuid) {
                    return { ...originalMessage, hasFailed: true };
                }
                return originalMessage;
            });

            return state.set('messages', updatedMessages);
        }
        case SEND_TEXT_MESSAGE_REQUEST:
            return state.set('total', state.get('total') + 1).set('messages', [
                {
                    messageId: payload.uuid,
                    messageContent: payload.message,
                    messageType: 'text',
                    author: payload.author,
                },
                ...state.get('messages'),
            ]);
        case SEND_TEXT_MESSAGE_SUCCESS: {
            const { message, uuid } = payload;

            const messages = [...state.get('messages')];

            // find local message and replace it with the one from the server
            const updatedMessages = messages.map((originalMessage) => {
                if (originalMessage.messageId === uuid) {
                    return { ...originalMessage, ...message };
                }
                return originalMessage;
            });

            return state.set('messages', updatedMessages).set('total', state.get('total') + 1);
        }

        case SEND_TEXT_MESSAGE_FAILURE: {
            const { uuid } = payload;

            const messages = [...state.get('messages')];

            // find local message and replace it with the one from the server
            const updatedMessages = messages.map((originalMessage) => {
                if (originalMessage.messageId === uuid) {
                    return { ...originalMessage, hasFailed: true };
                }
                return originalMessage;
            });

            return state.set('messages', updatedMessages);
        }
        case CONVERSATION_ALLOWED_INTERACTIONS_FAILURE:
            return state.set('allowedInteractionsRequestStatus', payload);
        case CONVERSATION_ALLOWED_INTERACTIONS_SUCCESS:
            return state.set('allowedInteractions', fromJS(payload)).set('allowedInteractionsRequestStatus', 200);
        case CONVERSATION_PARTICIPANTS_FAILURE:
            return state.set('participantsRequestStatus', payload);
        case CONVERSATION_PARTICIPANTS_SUCCESS:
            return state.set('participants', fromJS(payload)).set('participantsRequestStatus', 200);
        case CONVERSATION_MESSAGES_REQUEST:
            return state.set('pageNumber', fromJS(payload.pageNumber));
        case CONVERSATION_MESSAGES_SUCCESS: {
            const newPageState = state.get('pageNumber');
            const fetchedMessages = payload.messages;
            const newMessages = [...state.get('messages'), ...fetchedMessages];
            // filter out duplicate messages because of how badly the page and size was implemented
            // when sending a message, the new message is also redownloaded because of how the page and size is fetched
            const filteredMessages = new Map(newMessages.map((msg) => [msg.messageId, msg]));
            const uniqueMessages = [...filteredMessages.values()];
            const sortedMessages = uniqueMessages.sort((a, b) => new Date(b.sentOn) - new Date(a.sentOn));

            return state
                .set('total', payload.total)
                .set('messages', sortedMessages)
                .set('pageNumber', fetchedMessages.length > 0 ? newPageState + 1 : newPageState);
        }
        default:
            return state;
    }
};

export default ConversationReducer;
