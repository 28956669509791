import React, { useState } from 'react';
import styled from 'styled-components';
import ServiceDropdown from '../Service/ServiceDropdown';
import { searchableServicesWithActiveService } from '../../Pawshake/Core/Service/Groups';
import Label from '../Shared/Label';
import PetsDropdown from '../Pets/PetsDropdown';
import { GreenButton } from '../Shared/Button/Buttons';
import { trackSearchPopupEvent } from '../../Application/Search/Analytics';
import FlexibleDatesPickerDropdown from '../Dates/FlexibleDatesPickerDropdown';
import ResponsiveModal from '../Modal/ResponsiveModal';
import { withTranslation } from '../../i18n';
import breakpoint from 'styled-components-breakpoint';
import ErrorMessage from '../Shared/Message/ErrorMessage';
import { useLocalStorage } from 'usehooks-ts';
import { StorageKeys } from '../../utils/constants/storageKeys.constants';
import AddressFinder from '../../components/Common/AddressFinder';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import { getDefaultIndexableSearchableService, getDomainBasedUponLocale } from '../../Pawshake/Core/Domain';
import withTrackingService from '../../contexts/TrackingServiceContext';
import { AnalyticKeys } from '../../utils/constants/analytics.constants';

const Content = styled.div`
    ${breakpoint('md')`
        width: 40em;
  `};
`;

const Row = styled.div`
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;

    ${breakpoint('md')`
        flex-direction: row;
  `};
`;

const RowItem = styled.div`
    flex: 1;

    ${breakpoint('md')`
        margin-right: 2rem;
        &:last-child {
            margin-right: 0;
        }
    `};
`;

const StyledGreenButton = styled(GreenButton)`
    float: right;
    ${breakpoint('md')`
        width: 50%;
  `};
`;
const petsInitialValue = {
    smallDog: 0,
    mediumDog: 0,
    largeDog: 0,
    giantDog: 0,
    cat: 0,
    smallAnimal: 0,
};

const SearchPopupV3 = (props) => {
    const { t, onSave, onClose, isOpen, application, trackingService } = props;
    const { locale } = application;

    const domain = getDomainBasedUponLocale(locale);
    const defaultSearchableService = getDefaultIndexableSearchableService(domain);

    const [selectedService, setSelectedService] = useLocalStorage(StorageKeys.SEARCH_SERVICE, defaultSearchableService);
    const [error, setError] = useState();
    const [placeName, setPlaceName] = useLocalStorage(StorageKeys.SEARCH_PLACENAME, '');
    const [location, setLocation] = useLocalStorage(StorageKeys.SEARCH_LOCATION);

    const [dateRange, setDateRange] = useState({
        firstDay: false,
        lastDay: false,
    });

    const [typeOfDateSelection, setTypeOfDateSelection] = useState('consecutive');

    const [days, setDays] = useState();
    const [pets, setPets] = useLocalStorage(StorageKeys.SEARCH_PETS, petsInitialValue);

    const closeTracking = () => {
        onClose();
        trackSearchPopupEvent('Close', 'onRequestClose');
    };

    return (
        <ResponsiveModal
            title={t('search.searchPopup.title')}
            isOpen={isOpen}
            closeTracking={closeTracking}
            shouldCloseOnOverlayClick={false}
        >
            <Content>
                <Row>
                    {error && (
                        <RowItem>
                            <ErrorMessage error={error} />
                        </RowItem>
                    )}
                </Row>
                <Row>
                    <RowItem>
                        <Label>{t('search.searchPopup.labels.service')}</Label>
                        <ServiceDropdown
                            service={selectedService ?? defaultSearchableService}
                            services={searchableServicesWithActiveService(selectedService ?? defaultSearchableService)}
                            onUpdate={(service) => {
                                setSelectedService(service);
                                trackSearchPopupEvent('Service', `${selectedService} to ${service}`);
                                trackingService.trackEvent(
                                    AnalyticKeys.SERVICE_DROPDOWN_SEARCH_MODAL_SELECTED,
                                    selectedService
                                );
                            }}
                            trackOpen={() => {
                                trackingService.trackEvent(AnalyticKeys.SERVICE_DROPDOWN_SEARCH_MODAL);
                            }}
                        />
                    </RowItem>
                    <RowItem>
                        <Label>{t('search.searchPopup.labels.location')}</Label>
                        <AddressFinder
                            defaultValue={placeName}
                            country={locale.getCountry()}
                            placeholder={t('search:search.filters.location.placeholder')}
                            callback={({ lat, lng }, description) => {
                                trackSearchPopupEvent('Address', `Changed`);
                                setLocation({
                                    latitude: lat,
                                    longitude: lng,
                                });
                                setPlaceName(description);
                            }}
                        />
                    </RowItem>
                </Row>
                <Row>
                    <RowItem>
                        <Label>{t('search.searchPopup.labels.dates')}</Label>
                        <FlexibleDatesPickerDropdown
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            selectedDays={days}
                            setSelectedDays={setDays}
                            typeOfDateSelection={typeOfDateSelection}
                            setTypeOfDateSelection={setTypeOfDateSelection}
                            provideActions={true}
                            unavailableDates={[]}
                            trackOpen={() => {
                                trackSearchPopupEvent('Dates', 'Open');
                                trackingService.trackEvent(AnalyticKeys.DATE_PICKER_SEARCH_HOMEPAGE);
                            }}
                            trackApply={() => {
                                trackSearchPopupEvent('Dates', 'Apply');
                            }}
                            trackClear={() => {
                                trackSearchPopupEvent('Dates', 'Clear');
                            }}
                            onClear={() => {
                                setDays([]);
                                setDateRange({
                                    firstDay: false,
                                    lastDay: false,
                                });
                            }}
                        />
                    </RowItem>
                    <RowItem>
                        <Label>{t('search.searchPopup.labels.pets')}</Label>
                        <PetsDropdown
                            provideActions={true}
                            onChange={(name, count) => {
                                setPets({
                                    ...pets,
                                    [name]: count,
                                });
                                trackSearchPopupEvent('Pets', `${name} to ${count}`);
                            }}
                            handleApply={setPets}
                            smallDog={pets.smallDog}
                            mediumDog={pets.mediumDog}
                            largeDog={pets.largeDog}
                            giantDog={pets.giantDog}
                            cat={pets.cat}
                            smallAnimal={pets.smallAnimal}
                            trackOpen={() => {
                                trackingService.trackEvent(AnalyticKeys.PET_DROPDOWN_SEARCH_MODAL);
                            }}
                        />
                    </RowItem>
                </Row>
                <Row>
                    <RowItem />
                    <RowItem>
                        <StyledGreenButton
                            onClick={() => {
                                if (!placeName || !location || !location.longitude || !location.latitude) {
                                    setError(t('search.searchPopup.error.noLocation'));
                                    return;
                                }
                                onSave({
                                    selectedService,
                                    placeName,
                                    location,
                                    days,
                                    pets,
                                    typeOfDateSelection,
                                });
                                trackSearchPopupEvent('Save');
                                trackingService.trackEvent(AnalyticKeys.SEARCH_NOW_SEARCH_HOMEPAGE);
                            }}
                        >
                            {t('search.searchPopup.save')}
                        </StyledGreenButton>
                    </RowItem>
                </Row>
            </Content>
        </ResponsiveModal>
    );
};

export default withApplicationAwareness(withTrackingService(withTranslation('search')(SearchPopupV3)));
