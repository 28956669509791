import { useRouter } from 'next/router';
import { useState } from 'react';
import Script from 'react-load-script';
import SVGInline from 'react-svg-inline';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import { withTranslation } from '../../i18n';
import Spinner from '../Shared/Spinner';

const Container = styled.div`
    border: none;
    background: transparent;
    z-index: 999;
    transform: translateZ(0px);
    position: fixed;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: opacity, top, bottom;
    opacity: 1;
    margin: 10px 20px;
    right: 0;
    bottom: 0;

    button {
        box-shadow: none;
        background-color: #1f74ad;
        color: #ffffff;
        fill: #ffffff;
        padding: 0.5rem 1rem;
        letter-spacing: 0.05rem;
        border-radius: 999rem;
        bottom: 0;
        font-size: 1.07143rem;
        min-width: 100%;
        cursor: pointer;
        border: none;

        display: flex;
        align-items: center;
    }
`;

const Label = styled.span`
    display: none;

    ${breakpoint('md')`
    display: inline-block;
  `};
`;

const Icon = styled(SVGInline)`
    height: 22px;
    ${breakpoint('md')`
    margin-right: 0.5rem;
  `};
`;

const ZendeskWidget = ({ t, application }) => {
    const [clicked, setClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPlaceholder, setShowPlaceholder] = useState(true);
    const { zendeskLanguage } = application;

    const router = useRouter();

    if (router.pathname.includes('/conversation')) {
        return null;
    }

    const onLoad = () => {
        setShowPlaceholder(false);
        window.zE('webWidget', 'setLocale', `${zendeskLanguage}`);
        window.zE('webWidget', 'open');
        window.zESettings = {
            webWidget: {
                color: {
                    theme: '#5CACE2',
                    launcher: '#1f74ad',
                    launcherText: '#FFFFFF',
                    button: '#1f74ad',
                    resultLists: '#394147',
                    header: '#5CACE2',
                    articleLinks: '#5cace2',
                },
            },
        };
    };
    const dummy = () => {};

    const onPlaceholderClick = () => {
        setClicked(true);
        setLoading(true);
    };

    return (
        <>
            {clicked && (
                <Script
                    url='https://static.zdassets.com/ekr/snippet.js?key=0ec0c38d-e352-4a4d-b528-fb437bf71c76'
                    onCreate={dummy}
                    onError={dummy}
                    onLoad={onLoad}
                    attributes={{ id: 'ze-snippet' }}
                />
            )}
            {showPlaceholder && (
                <Container onClick={onPlaceholderClick}>
                    <button>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <>
                                <Icon
                                    svg={
                                        '<svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true" fill="currentColor"><title/><g id="Layer_4"><path d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z"></path><circle cx="10" cy="15" r="1"></circle><path d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z"></path></g></svg>'
                                    }
                                />
                                <Label>{t('user.menu.help')}</Label>
                            </>
                        )}
                    </button>
                </Container>
            )}
        </>
    );
};

export default withApplicationAwareness(withTranslation('user')(ZendeskWidget));
