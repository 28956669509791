import { fromJS } from 'immutable';
import {
    AVAILABILITY_CALENDAR_SUMMARY_SUCCESS,
    CALENDAR_AVAILABILITY_DATE_DETAIL_SUCCESS,
    BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS,
    BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_SUCCESS,
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST,
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS,
    SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_FAILURE,
    CALENDAR_AVAILABILITY_DATE_DETAIL_FAILURE,
    CALENDAR_AVAILABILITY_DATE_DETAIL_REQUEST,
    AVAILABILITY_CALENDAR_SUMMARY_REQUEST,
    MOST_RECENT_AVAILABILITY_UPDATE_SUCCESS,
    INACTIVE_MODE_SETTING_SUCCESS,
    ONE_BOOKING_AT_A_TIME_SETTING_SUCCESS,
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_SUCCESS,
    AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_REQUEST,
    OFFERED_BOOKABLE_SERVICES_SUCCESS,
} from './constants';

const initialState = fromJS({
    offeredBookableServices: [],
    availabilityCalendarSummary: {},
    showingMonthAndYear: null,
    showingBookableService: undefined,
    mostRecentAvailabilityUpdate: null,
    inactiveModeSetting: false,
    oneBookingAtATimeSetting: false,
    calendarAvailabilityDateDetail: {
        requested: false,
        succeeded: false,
        failed: false,
        data: {},
    },
    bookableServiceWeekdayAvailableSlots: false,
    bookableServiceRepeatClientsOnly: false,
    specifyDateRangeAvailableSlotsForBookableServices: {
        requested: false,
        succeeded: false,
        failed: false,
    },
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case OFFERED_BOOKABLE_SERVICES_SUCCESS:
            return state.set('offeredBookableServices', payload);
        case BOOKABLE_SERVICE_REPEAT_CLIENTS_ONLY_SUCCESS:
            return state.set('bookableServiceRepeatClientsOnly', payload);
        case ONE_BOOKING_AT_A_TIME_SETTING_SUCCESS:
            return state.set('oneBookingAtATimeSetting', payload);
        case INACTIVE_MODE_SETTING_SUCCESS:
            return state.set('inactiveModeSetting', payload);
        case MOST_RECENT_AVAILABILITY_UPDATE_SUCCESS:
            return state.set('mostRecentAvailabilityUpdate', payload);
        case CALENDAR_AVAILABILITY_DATE_DETAIL_SUCCESS:
            return state.set('calendarAvailabilityDateDetail', {
                requested: true,
                succeeded: true,
                data: payload,
            });
        case CALENDAR_AVAILABILITY_DATE_DETAIL_REQUEST:
            return state.set('calendarAvailabilityDateDetail', {
                requested: true,
                succeeded: false,
                failed: false,
                data: {},
            });
        case CALENDAR_AVAILABILITY_DATE_DETAIL_FAILURE:
            return state.set('calendarAvailabilityDateDetail', {
                requested: true,
                succeeded: false,
                failed: true,
                data: {},
            });
        case SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_REQUEST:
            return state
                .setIn(['specifyDateRangeAvailableSlotsForBookableServices', 'succeeded'], false)
                .setIn(['specifyDateRangeAvailableSlotsForBookableServices', 'failed'], false)
                .setIn(['specifyDateRangeAvailableSlotsForBookableServices', 'requested'], true);
        case SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_SUCCESS:
            return state
                .setIn(['specifyDateRangeAvailableSlotsForBookableServices', 'requested'], false)
                .setIn(['specifyDateRangeAvailableSlotsForBookableServices', 'succeeded'], true);
        case SPECIFY_DATE_RANGE_AVAILABLE_SLOTS_FOR_BOOKABLE_SERVICES_FAILURE:
            return state
                .setIn(['specifyDateRangeAvailableSlotsForBookableServices', 'requested'], false)
                .setIn(['specifyDateRangeAvailableSlotsForBookableServices', 'failed'], true);
        case AVAILABILITY_CALENDAR_SUMMARY_REQUEST:
            return state.set('showingBookableService', undefined).set('showingMonthAndYear', payload);
        case AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_REQUEST:
            return state
                .set('showingBookableService', payload.bookableService)
                .set('showingMonthAndYear', payload.date);
        case AVAILABILITY_CALENDAR_SUMMARY_FOR_BOOKABLE_SERVICE_SUCCESS:
        case AVAILABILITY_CALENDAR_SUMMARY_SUCCESS:
            return state.set('availabilityCalendarSummary', payload);
        case BOOKABLE_SERVICE_WEEKDAY_AVAILABLE_SLOTS_SUCCESS:
            return state.set('bookableServiceWeekdayAvailableSlots', payload);
        default:
            return state;
    }
};
