import addDays from 'date-fns/addDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import subDays from 'date-fns/subDays';
import first from 'lodash/first';
import last from 'lodash/last';
import { useEffect, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import styled from 'styled-components';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import { withTranslation } from '../../i18n';
import { getFirstDayOfTheWeekOfLocale } from '../../Pawshake/Core/Locale/FirstDayOfTheWeek';
import {
    CONSECUTIVE_DATE_SELECTION,
    determineDisabledDaysForConsecutive,
    NON_CONSECUTIVE_DATE_SELECTION,
} from '../../utils/dates';
import useDidMountEffect from '../../utils/hooks';
import { convertToSelectedDays } from '../Service/Utils/DateRangeConversion';
import Checkbox from '../Shared/Checkbox';
import DefaultDatePickerNavigationHeader from './DefaultDatePickerNavigationHeader';
import { DateRangePickerGlobalStyle } from './Styles/DatepickerStyling';

const Switch = styled.div`
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 1rem;
`;

const MULTIPLE_DATE_PICKER_MAX_RANGE = 30;
const MULTIPLE_DATE_PICKER_MAX_ALLOWED_DATES = 15;

const SelectedDaysAmount = styled.div`
    color: ${(props) =>
        props.amountLeft === 0
            ? '#dc5858'
            : props.amountLeft < MULTIPLE_DATE_PICKER_MAX_ALLOWED_DATES / 2
              ? '#f1c40f'
              : '#00c38a'};
`;

const loadInLanguage = (locale) => {
    let intl;
    try {
        intl = require('./languages/' + locale.getLanguage() + '.js');
    } catch (ex) {
        intl = require('./languages/en.js');
    }

    return intl;
};

const determineImpossibleModifier = () => {
    return { before: new Date(), after: addDays(new Date(), 180) };
};

const determineModifiersForConsecutive = (firstDay, lastDay, unavailableDates) => {
    return {
        start: firstDay,
        end: lastDay,
        unavailable: unavailableDates,
        impossible: determineImpossibleModifier(),
    };
};

const determineModifiersForNonConsecutive = (unavailableDates) => {
    return {
        unavailable: unavailableDates,
        impossible: determineImpossibleModifier(),
    };
};

const determineDisabledDaysForNonConsecutive = (selectedDays) => {
    let allowedAddedDays = MULTIPLE_DATE_PICKER_MAX_RANGE;
    const firstDay = first(selectedDays);
    const lastDay = last(selectedDays);

    const daysBetweenLastAndFirstDay =
        firstDay && lastDay ? eachDayOfInterval({ start: firstDay, end: lastDay }).length : 0;
    allowedAddedDays = allowedAddedDays - daysBetweenLastAndFirstDay;

    return [{ before: subDays(firstDay, allowedAddedDays) }, { after: addDays(lastDay, allowedAddedDays) }];
};

const isSelectingFirstDay = (firstDay, lastDay, selectedDay) => {
    const isBeforeFirstDay = firstDay && DateUtils.isDayBefore(selectedDay, firstDay);
    const isRangeSelected = firstDay && lastDay;
    return !firstDay || isBeforeFirstDay || isRangeSelected;
};

const FlexibleDatesPicker = ({
    t,
    initialFirstDay,
    initialLastDay,
    setDateRange,
    typeOfDateSelection,
    setTypeOfDateSelection,
    selectedDays,
    setSelectedDays,
    application,
    unavailableDates,
    numberOfMonths,
}) => {
    const { locale } = application;
    const [internalSelectedDays, setInternalSelectedDays] = useState(selectedDays);
    const [firstDay, setFirstDay] = useState(initialFirstDay);
    const [lastDay, setLastDay] = useState(initialLastDay);
    const [selectedMonth] = useState(initialFirstDay);

    useDidMountEffect(() => {
        setDateRange({
            firstDay: false,
            lastDay: false,
        });
        setSelectedDays([]);
    }, [typeOfDateSelection]);

    useDidMountEffect(() => {
        setSelectedDays([...internalSelectedDays]);
    }, [internalSelectedDays]);

    useDidMountEffect(() => {
        setDateRange({
            firstDay,
            lastDay,
        });

        setSelectedDays(
            convertToSelectedDays(firstDay ? firstDay.toString() : undefined, lastDay ? lastDay.toString() : undefined)
        );
    }, [firstDay, lastDay]);

    useEffect(() => {
        if (selectedDays.length === 0 && internalSelectedDays.length !== 0) {
            setInternalSelectedDays(selectedDays);
        }
    }, [selectedDays]);

    useEffect(() => {
        if (firstDay !== initialFirstDay) {
            setFirstDay(initialFirstDay);
        }
        if (lastDay !== initialLastDay) {
            setLastDay(initialLastDay);
        }
    }, [initialFirstDay, initialLastDay]);

    const consecutiveOnDayClick = (day, modifiers) => {
        if (modifiers.hasOwnProperty('impossible') && modifiers.impossible) {
            return;
        }

        if (modifiers.hasOwnProperty('unavailable') && modifiers.unavailable) {
            return;
        }

        if (modifiers.hasOwnProperty('disabled') && modifiers.disabled) {
            return;
        }

        if (isSelectingFirstDay(firstDay, lastDay, day)) {
            setFirstDay(day);
            setLastDay(null);
        } else {
            setLastDay(day);
        }
    };

    const nonConsecutiveOnDayClick = (day, modifiers) => {
        const { selected } = modifiers;

        if (selected) {
            const selectedIndex = internalSelectedDays.findIndex((selectedDay) =>
                DateUtils.isSameDay(selectedDay, day)
            );
            internalSelectedDays.splice(selectedIndex, 1);
        } else {
            if (modifiers.hasOwnProperty('impossible') && modifiers.impossible) {
                return;
            }

            if (modifiers.hasOwnProperty('unavailable') && modifiers.unavailable) {
                return;
            }

            if (modifiers.hasOwnProperty('disabled') && modifiers.disabled) {
                return;
            }

            if (MULTIPLE_DATE_PICKER_MAX_ALLOWED_DATES - internalSelectedDays.length === 0) {
                return;
            }
            internalSelectedDays.push(day);
        }

        internalSelectedDays.sort((a, b) => a - b);
        setInternalSelectedDays([...internalSelectedDays]);
    };

    const intl = loadInLanguage(locale);
    let modifiers;
    let disabledDays;
    if (typeOfDateSelection === CONSECUTIVE_DATE_SELECTION) {
        modifiers = determineModifiersForConsecutive(firstDay, lastDay, unavailableDates);
        disabledDays = determineDisabledDaysForConsecutive(firstDay, lastDay, unavailableDates);
    } else {
        modifiers = determineModifiersForNonConsecutive(unavailableDates);
        disabledDays = determineDisabledDaysForNonConsecutive(internalSelectedDays);
    }

    return (
        <>
            <DateRangePickerGlobalStyle />
            <DayPicker
                className={typeOfDateSelection === CONSECUTIVE_DATE_SELECTION ? 'Range' : undefined}
                month={selectedMonth}
                numberOfMonths={numberOfMonths}
                selectedDays={
                    typeOfDateSelection === CONSECUTIVE_DATE_SELECTION
                        ? [firstDay, { from: firstDay, to: lastDay }]
                        : internalSelectedDays
                }
                disabledDays={disabledDays}
                modifiers={modifiers}
                navbarElement={<DefaultDatePickerNavigationHeader />}
                onDayClick={
                    typeOfDateSelection === CONSECUTIVE_DATE_SELECTION
                        ? consecutiveOnDayClick
                        : nonConsecutiveOnDayClick
                }
                months={intl.MONTHS}
                weekdaysShort={intl.WEEKDAYS_SHORT}
                weekdaysLong={intl.WEEKDAYS_LONG}
                firstDayOfWeek={getFirstDayOfTheWeekOfLocale(locale)}
            />
            <Switch>
                <Checkbox
                    onChange={({ target }) => {
                        setTypeOfDateSelection(
                            target.value ? NON_CONSECUTIVE_DATE_SELECTION : CONSECUTIVE_DATE_SELECTION
                        );
                        setInternalSelectedDays([]);
                        setFirstDay(undefined);
                        setLastDay(undefined);
                    }}
                    checked={typeOfDateSelection === NON_CONSECUTIVE_DATE_SELECTION}
                >
                    {t('datePicker.nonConsecutiveDaysSwitchLabel')}
                </Checkbox>
                {typeOfDateSelection === NON_CONSECUTIVE_DATE_SELECTION && (
                    <SelectedDaysAmount
                        amountLeft={MULTIPLE_DATE_PICKER_MAX_ALLOWED_DATES - internalSelectedDays.length}
                    >
                        {t('datePicker.nonConsecutiveDaysSelected', {
                            maxAmount: MULTIPLE_DATE_PICKER_MAX_ALLOWED_DATES,
                            amount: MULTIPLE_DATE_PICKER_MAX_ALLOWED_DATES - internalSelectedDays.length,
                        })}
                    </SelectedDaysAmount>
                )}
            </Switch>
        </>
    );
};

export default withApplicationAwareness(withTranslation('common')(FlexibleDatesPicker));
