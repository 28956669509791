import Immutable from 'immutable';
import { createSelector } from 'reselect';

const selectRoot = (state) => state.getIn(['domain', 'search'], new Immutable.Map());

const selectSearchData = createSelector(selectRoot, (root) => root.get('data'));

export const selectSearchFromAddressSitters = createSelector(selectSearchData, (data) => data.get('sitters'));
export const selectSitterSearchId = createSelector(selectSearchData, (data) => data.get('sitterSearchId'));
export const selectSearchFromAddressAggregateRating = createSelector(selectSearchData, (data) =>
    data.get('aggregateRating')
);
export const selectSearchFromAddressTotal = createSelector(selectSearchData, (data) => data.get('total'));
export const selectSearchInProgress = createSelector(selectRoot, (root) => root.get('searchInProgress'));
export const selectHighlightedSitter = createSelector(selectRoot, (root) => root.get('highlightedSitter'));
