import { BaseApi } from '../Core/BaseApi';

class DashboardApi extends BaseApi {
    getDashboardSitterStatistics = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/sitter-statistics');
    };

    getDashboardFavoriteSitters = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/favorite-sitters');
    };

    getDashboardRecentSitters = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/recent-sitters');
    };

    getReviewableBookings = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/reviewable-bookings');
    };

    getSitterDashboardSitterStatus = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/sitter/status');
    };

    getDashboardConversationPenaltySummary = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/conversation-penalty-summary');
    };
}

export default new DashboardApi();
