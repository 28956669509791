import { NotificationsIcon } from '../../../../../styles/icons';
import PsNavBarIconLink from '../../templates/navbar-icon-link';

const PsNavBarNotificationsLink = ({ locale }: { locale: string }) => (
    <PsNavBarIconLink href='/dashboard'>
        <NotificationsIcon />
    </PsNavBarIconLink>
);

export default PsNavBarNotificationsLink;
