import Modal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const ModalStyle = createGlobalStyle`
  .ReactModal__Body--open {
      overflow: hidden;
  }
  
  .ReactModal__Overlay--after-open {
    ${breakpoint('md')`
      overflow-y: auto !important;
    `};
  }
  
  .ReactModal__Content--after-open {
    overflow: auto !important;

    ${breakpoint('md')`
        border-radius: .75rem !important;
        left: 50% !important;
        top: 4rem !important;
        right: auto !important;
        bottom: auto !important;
        transform: translate(-50%, 0);
        overflow: visible !important;
    `};
  }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #cecece;
`;

const CloseIcon = styled.img`
    line-height: 0;
    margin-left: 1rem;
    cursor: pointer;
    padding: 1rem;
    border-radius: 50%;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

Modal.setAppElement('#__next');

const ResponsiveModal = ({
    title,
    titleDescription,
    isOpen,
    setIsOpen,
    children,
    closeTracking,
    shouldCloseOnOverlayClick,
}) => {
    const closeModal = () => {
        if (closeTracking) {
            closeTracking();
        }
        if (setIsOpen) {
            setIsOpen(false);
        }
    };

    return (
        <>
            <ModalStyle />
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick ?? true}
                style={{
                    overlay: {
                        top: '0px',
                        bottom: '0px',
                        left: '0px',
                        right: '0px',
                        position: 'fixed',
                        zIndex: 1000,
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                    content: {
                        overflow: 'visible',
                        width: 'auto',
                        height: 'auto',
                        borderRadius: 0,
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    },
                }}
            >
                <Header>
                    <div>
                        <h2>{title}</h2>
                        {titleDescription && <div>{titleDescription}</div>}
                    </div>

                    <CloseIcon
                        onClick={closeModal}
                        src={'https://static1.pawshakecdn.com/icons/icon-cross.svg'}
                        alt={'close modal'}
                    />
                </Header>
                {children}
            </Modal>
        </>
    );
};

export default ResponsiveModal;
