import createRequestActionStrings from '../../../utils/createRequestActionStrings';

export const [
    SITTER_LISTING_POLICE_CHECK_REQUEST,
    SITTER_LISTING_POLICE_CHECK_SUCCESS,
    SITTER_LISTING_POLICE_CHECK_FAILURE,
] = createRequestActionStrings('SITTER_LISTING_POLICE_CHECK');

export const [REQUEST_POLICE_CHECK_REQUEST, REQUEST_POLICE_CHECK_SUCCESS, REQUEST_POLICE_CHECK_FAILURE] =
    createRequestActionStrings('REQUEST_POLICE_CHECK');

export const [RENEW_POLICE_CHECK_REQUEST, RENEW_POLICE_CHECK_SUCCESS, RENEW_POLICE_CHECK_FAILURE] =
    createRequestActionStrings('RENEW_POLICE_CHECK');
