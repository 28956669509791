import { fromJS } from 'immutable';
import { BLOG_FEATURED_FAILURE, BLOG_FEATURED_SUCCESS } from './constants';

export const initialState = fromJS({
    blogs: [{}, {}, {}],
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case BLOG_FEATURED_SUCCESS:
            return state.set('blogs', payload);
        case BLOG_FEATURED_FAILURE:
            return state.set('blogs', []);
        default:
            return state;
    }
};
