import { fromJS } from 'immutable';
import { SITTER_LISTING_POLICE_CHECK_SUCCESS } from './constants';

const initialState = fromJS({
    policeCheck: {
        requested: false,
        processCompleted: true,
        checkCompleted: false,
        sitterCanRequestInviteLink: false,
        sitterCanResendInviteLink: false,
        sitterCanRenewPoliceCheck: false,
        sitterIsAwaitingResults: false,
        isSoonToExpire: false,
        isInvalid: false,
        validUntil: null,
        policeCheckFailed: false,
        zendeskArticleLink: null,
        price: 37.5,
        currency: 'AUD',
        numberOfMonths: 12,
        fetched: false,
    },
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case SITTER_LISTING_POLICE_CHECK_SUCCESS:
            return state.set('policeCheck', fromJS(Object.assign({ fetched: true }, payload)));
        default:
            return state;
    }
};
