import { call, put, takeLatest, fork, select } from 'redux-saga/effects';
import { jwtTokenSelect } from '../User/Authentication/selectors';
import { DISMISS_NOTIFICATION_REQUEST } from './constants';
import { dismissNotificationSuccess, dismissNotificationFailure } from './actions';
import NotificationApi from '../../Pawshake/User/NotificationApi';

function* dismissNotificationFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(
        NotificationApi.dismissDashboardNotification,
        payload.dashboardNotificationId,
        jwtToken
    );

    if (response.status === 204) {
        yield put(dismissNotificationSuccess(payload));
    } else {
        yield put(dismissNotificationFailure());
    }
}

function* dismissNotificationFlowWatcher() {
    yield takeLatest(DISMISS_NOTIFICATION_REQUEST, dismissNotificationFlow);
}

export default [fork(dismissNotificationFlowWatcher)];
