import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import { fromJS } from 'immutable';
import flowRight from 'lodash/flowRight';
import throttle from 'lodash/throttle';
import withReduxSaga from 'next-redux-saga';
import withRedux from 'next-redux-wrapper';
import routerEvents from 'next-router-events';
import App from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import NProgress from 'nprogress';
import { Provider } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { fetchFeatureFlags } from '../api/feature-flag.api';
import { screenSizeChanged } from '../Application/Device/actions';
import Footer from '../Application/Layout/Footer/Footer';
import Header from '../Application/Layout/Header/Header';
import { ApplicationContext, getApplicationContextForCloudFrontRegion } from '../contexts/ApplicationContext';
import { AuthServiceContext } from '../contexts/AuthServiceContext';
import { EducationServiceContext } from '../contexts/EducationServiceContext';
import { FeatureFlagContext } from '../contexts/FeatureFlagContext';
import { TrackingServiceContext } from '../contexts/TrackingServiceContext';
import { campaignUtmParametersDetected } from '../domain/Campaign/actions';
import { appWithTranslation, i18n } from '../i18n';
import ZendeskWidget from '../Infrastructure/ZendeskWidget/ZendeskWidget';
import initialiseFirebase from '../initAuth';
import { AuthService } from '../services/auth.service';
import { EducationService } from '../services/education.service';
import { TrackingService } from '../services/tracking.service';
import initStore from '../store/next';
import Colors from '../styles/colors';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    letter-spacing: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${Colors.text.default};
    -webkit-font-smoothing: antialiased;

    
    &.modalOpen {
      overflow: hidden;
      height: 100%;
    }
  }
  
  input,
  textarea,
  select,
  button {
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    font-size: 1rem;
    letter-spacing: -0.1px;
    line-height: 1.5rem;
    color: ${Colors.text.default};
    box-sizing: border-box;
    outline: none;
  }
  
  input {
    border: 1px solid #cecece;
    border-radius: 8px;
    padding: 10px;
    height: 40px;

    :hover {
        outline: 1px solid #747474;
        border-color: #747474;
    }
    &:focus {
        outline: 1px solid #00c38a;
        border-color: #00c38a;
    }
    &:disabled {
        pointer-events:none;
    }
  }
 
  /* hide arrows in numeric input field */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    /* hide arrows in numeric input field - firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  a {
    color: ${Colors.brand.default};
    text-decoration: none;
  }
  
  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    @media (min-width: 768px) {
      line-height: 40px;
    } 
  }

  h1,
  h2 {
    color: ${Colors.text.default};
  }
  
  h1 {
    font-size: 1.5rem;
    
    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
  
  h2 {
    font-size: 1.2rem;
    
    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  small {
    font-size: .875rem;
    line-height: 1.25rem;
    color: ${Colors.text.light}
  }
  
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }
  
  #nprogress .bar {
    background: #1b7ab1;
  
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
  
    width: 100%;
    height: 3px;
  }
  
  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #1b7ab1, 0 0 5px #1b7ab1;
    opacity: 1;
  
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }
  
  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }
  
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @keyframes rotate-up {
    100% {
      transform: rotate(180deg);
    }
  }
  @keyframes rotate-down {
    100% {
      transform: rotate(0);
    }
  }
`;
const config = getConfig();
const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
Sentry.init({
    dsn: __CONFIG__.sentryPublicDsn,
    enabled: __CONFIG__.enableSentry,
    integrations: [
        new RewriteFrames({
            iteratee: (frame) => {
                frame.filename = frame.filename.replace(distDir, 'app:///_next');
                return frame;
            },
        }),
    ],
    tracesSampleRate: 0.5,
    // This beforeSend is used to filter out the "Non-Error promise rejection captured with value".
    // We are on an older version so we can't ignore certain errors so we have to manually filter
    // them out. More info about this: https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-828834651
    beforeSend: function (event) {
        // filter out UnhandledRejection errors that have no information
        if (
            event !== undefined &&
            event.exception !== undefined &&
            event.exception.values !== undefined &&
            event.exception.values.length == 1
        ) {
            var e = event.exception.values[0];
            if (e.type === 'UnhandledRejection' && e.value === 'Non-Error promise rejection captured with value: ') {
                return null;
            }

            if (e.type === 'Error' && e.value === 'Error looking up record in object store by key range ') {
                return null;
            }
        }

        return event;
    },
});

routerEvents.on('routeChangeStart', () => NProgress.start());
routerEvents.on('routeChangeComplete', () => NProgress.done());
routerEvents.on('routeChangeError', () => NProgress.done());

initialiseFirebase();
const trackingService = new TrackingService();
const educationService = new EducationService();
let authenticationService;

class MyApp extends App {
    static async getInitialProps({ Component, ctx }) {
        const { req } = ctx;
        const currentLanguage = typeof req === 'undefined' ? i18n.language : req.language;

        const featureFlags = await fetchFeatureFlags(currentLanguage);

        let pageProps = {};

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }

        const defaultNamespaces = ['common', 'footer', 'services', 'search', 'user', 'components', 'geo'];

        if (typeof pageProps.namespacesRequired === 'undefined') {
            pageProps.namespacesRequired = defaultNamespaces;
        } else {
            defaultNamespaces.forEach((namespace) => {
                if (!pageProps.namespacesRequired.includes(namespace)) {
                    pageProps.namespacesRequired.push(namespace);
                }
            });
        }

        return { pageProps, currentLanguage, featureFlags };
    }

    componentDidMount() {
        this.trackUtm();
        this.layout();

        window.addEventListener('resize', throttle(this.layout, 16));
    }

    trackUtm = () => {
        const { store, router } = this.props;
        const parameters = [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_term',
            'utm_content',
            'gclid',
            'keyword_id',
        ];

        if (!('utm_source' in router.query)) {
            return;
        }

        let foundParameters = {};
        parameters.forEach((parameter) => {
            const queryParam = router.query[parameter];
            if (queryParam !== null && typeof queryParam !== 'undefined') {
                foundParameters[parameter] = queryParam;
            }
        });

        if (Object.keys(foundParameters).length > 0) {
            store.dispatch(campaignUtmParametersDetected(foundParameters));
        }
    };

    layout = () => {
        const { store } = this.props;
        store.dispatch(screenSizeChanged({ width: window.innerWidth }));
    };

    render() {
        const { Component, pageProps, store, currentLanguage, router, featureFlags } = this.props;
        const applicationContext = getApplicationContextForCloudFrontRegion(currentLanguage);

        if (typeof currentLanguage === 'undefined') {
            // @todo: check with newest version of next if they handle static assets correctly
            if (router.asPath.startsWith('/static/')) {
                return null;
            }
            if (router.asPath.startsWith('/_next/')) {
                return null;
            }
        }

        if (!authenticationService) {
            authenticationService = new AuthService(store, router, trackingService, currentLanguage);
        }

        return (
            <>
                <GlobalStyle />
                <Provider store={store}>
                    <AuthServiceContext.Provider value={authenticationService}>
                        <FeatureFlagContext.Provider value={featureFlags}>
                            <TrackingServiceContext.Provider value={trackingService}>
                                <EducationServiceContext.Provider value={educationService}>
                                    <ApplicationContext.Provider value={applicationContext}>
                                        <ThemeProvider
                                            theme={{
                                                breakpoints: {
                                                    xs: 0,
                                                    sm: 480,
                                                    md: 768,
                                                    lg: 992,
                                                    xl: 1200,
                                                },
                                            }}
                                        >
                                            <>
                                                <Head>
                                                    <title>Pawshake</title>
                                                    <meta
                                                        name='apple-itunes-app'
                                                        content={`app-id=${__CONFIG__.appleAppId}`}
                                                    />
                                                    <link rel='manifest' href='/manifest.json' />
                                                    <link
                                                        rel='preconnect'
                                                        href='https://maps.googleapis.com'
                                                        crossOrigin='true'
                                                    />
                                                    <link
                                                        rel='preconnect'
                                                        href='https://www.google-analytics.com'
                                                        crossOrigin='true'
                                                    />
                                                    <link
                                                        rel='preconnect'
                                                        href='https://connect.facebook.net'
                                                        crossOrigin='true'
                                                    />
                                                    <link
                                                        rel='preconnect'
                                                        href='https://static.zdassets.com'
                                                        crossOrigin='true'
                                                    />
                                                    <link
                                                        rel='preconnect'
                                                        href='https://static1.pawshakecdn.com'
                                                        crossOrigin='true'
                                                    />
                                                    <link
                                                        rel='preconnect'
                                                        href='https://static2.pawshakecdn.com'
                                                        crossOrigin='true'
                                                    />
                                                    <link
                                                        rel='preconnect'
                                                        href='https://static3.pawshakecdn.com'
                                                        crossOrigin='true'
                                                    />
                                                    <link
                                                        rel='preconnect'
                                                        href='https://static4.pawshakecdn.com'
                                                        crossOrigin='true'
                                                    />
                                                    <meta name='theme-color' content='#5CACE2' />
                                                    <meta name='msapplication-TileColor' content='#5CACE2' />
                                                    <meta
                                                        name='msapplication-TileImage'
                                                        content={
                                                            'https://static1.pawshakecdn.com/favicons/ms-icon-144x144.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='apple-touch-icon'
                                                        sizes='57x57'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/apple-icon-57x57.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='apple-touch-icon'
                                                        sizes='60x60'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/apple-icon-60x60.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='apple-touch-icon'
                                                        sizes='72x72'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/apple-icon-72x72.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='apple-touch-icon'
                                                        sizes='76x76'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/apple-icon-76x76.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='apple-touch-icon'
                                                        sizes='114x114'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/apple-icon-114x114.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='apple-touch-icon'
                                                        sizes='120x120'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/apple-icon-120x120.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='apple-touch-icon'
                                                        sizes='144x144'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/apple-icon-144x144.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='apple-touch-icon'
                                                        sizes='152x152'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/apple-icon-152x152.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='apple-touch-icon'
                                                        sizes='180x180'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/apple-icon-180x180.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='icon'
                                                        type='image/png'
                                                        sizes='192x192'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/android-icon-192x192.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='icon'
                                                        type='image/png'
                                                        sizes='32x32'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/favicon-32x32.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='icon'
                                                        type='image/png'
                                                        sizes='96x96'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/favicon-96x96.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='icon'
                                                        type='image/png'
                                                        sizes='16x16'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/favicon-16x16.png'
                                                        }
                                                    />
                                                    <link
                                                        rel='shortcut icon'
                                                        href={
                                                            'https://static1.pawshakecdn.com/favicons/favicon-white.ico'
                                                        }
                                                        type='image/vnd.microsoft.icon'
                                                    />
                                                    <meta
                                                        name='viewport'
                                                        content='width=device-width, initial-scale=1.0, maximum-scale=5, user-scalable=1'
                                                    />
                                                    <meta name='format-detection' content='telephone=no' />
                                                </Head>
                                                <Header authService={authenticationService} />
                                                <Component {...pageProps} />
                                                <Footer />
                                                <ZendeskWidget />
                                            </>
                                        </ThemeProvider>
                                    </ApplicationContext.Provider>
                                </EducationServiceContext.Provider>
                            </TrackingServiceContext.Provider>
                        </FeatureFlagContext.Provider>
                    </AuthServiceContext.Provider>
                </Provider>
            </>
        );
    }
}

export default flowRight(
    withRedux(initStore, {
        debug: false,
        serializeState: (state) => state.toJS(),
        deserializeState: (state) => fromJS(state),
    })
)(appWithTranslation(withReduxSaga(MyApp)));
