import { call, select, takeLatest, fork } from 'redux-saga/effects';
import { UTM_PARAMETERS_DETECTED } from './constants';
import CampaignApi from '../../Pawshake/Campaign/CampaignApi';
import { USER_JWT_TOKEN_SUCCESS } from '../User/Authentication/constants';
import { jwtTokenSelect } from '../User/Authentication/selectors';

// eslint-disable-next-line require-yield
function* utmParametersDetectedFlow({ payload }) {
    let retrieved_object = JSON.parse(localStorage.getItem('campaigns')) || [];
    const unix_timestamp = Math.round(new Date().getTime() / 1000);
    retrieved_object.push({ ...payload, timestamp: unix_timestamp });
    localStorage.setItem('campaigns', JSON.stringify(retrieved_object));
}

function* utmParametersDetectedFlowWatcher() {
    yield takeLatest(UTM_PARAMETERS_DETECTED, utmParametersDetectedFlow);
}

function* persistCampaignTrackingFlow() {
    const campaigns = JSON.parse(localStorage.getItem('campaigns')) || [];
    if (campaigns.length > 0) {
        const jwtToken = yield select(jwtTokenSelect);
        const result = yield call(CampaignApi.persistTrackedCampaigns, jwtToken, campaigns);

        if (result) {
            localStorage.removeItem('campaigns');
        }
    }
}

function* persistCampaignTrackingFlowWatcher() {
    yield takeLatest(USER_JWT_TOKEN_SUCCESS, persistCampaignTrackingFlow);
}

export default [fork(utmParametersDetectedFlowWatcher), fork(persistCampaignTrackingFlowWatcher)];
