import { useRouter } from 'next/router';
import styled from 'styled-components';
import Breakpoints from '../../../../../styles/breakpoints';
import PsDrawerAvatar from '../drawer/drawer-avatar';
import PsDrawerLogo from '../drawer/drawer-logo';
import AnonymousActions from './anonymous-actions';
import FirebaseMenu from './firebase-menu';
import UserActions from './user-actions';

const NavBarActions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media ${Breakpoints.md} {
        && {
            flex-direction: row;
        }
    }
`;

const PsNavBarActions = ({
    application,
    isAuthenticated,
    isAuthenticatedButWithoutPawshakeAccount,
    isAuthenticatedLoading,
    logout,
    openSearch,
    toggleMenu,
    isASitter = false,
    hasACompletedSitterApplication = false,
    userImage = '',
    userName = '',
    messageUnreadCounter,
    trackEvent,
    t,
}: {
    application: any;
    isAuthenticated: boolean;
    isAuthenticatedButWithoutPawshakeAccount: boolean;
    isAuthenticatedLoading: boolean;
    isASitter?: boolean;
    hasACompletedSitterApplication?: boolean;
    userImage?: string;
    userName?: string;
    messageUnreadCounter?: number;
    logout: () => void;
    openSearch: () => void;
    toggleMenu: () => void;
    trackEvent: (fsCategory: any, gaCategory: any, action: any, eventLabel: any) => void;
    t: (key: string) => string;
}) => {
    const router = useRouter();

    // show logout button on the verify-email and finalise-account pages
    if (router.asPath.startsWith('/user/sign-up/provider') || router.asPath.startsWith('/user/sign-up/verify')) {
        return (
            <NavBarActions>
                <PsDrawerLogo onClick={toggleMenu} />
                <FirebaseMenu
                    t={t}
                    zendeskLanguage={application.zendeskLanguage}
                    logout={logout}
                    closeDrawer={toggleMenu}
                />
            </NavBarActions>
        );
    }

    if (isAuthenticatedLoading) {
        return null;
    }

    if (isAuthenticatedButWithoutPawshakeAccount) {
        return (
            <NavBarActions>
                <PsDrawerLogo onClick={toggleMenu} />
                <FirebaseMenu
                    t={t}
                    zendeskLanguage={application.zendeskLanguage}
                    logout={logout}
                    closeDrawer={toggleMenu}
                />
            </NavBarActions>
        );
    }

    if (!isAuthenticated) {
        return (
            <NavBarActions>
                <PsDrawerLogo onClick={toggleMenu} />
                <AnonymousActions
                    t={t}
                    locale={application.locale}
                    zendeskLanguage={application.zendeskLanguage}
                    trackEvent={trackEvent}
                    openSearch={openSearch}
                    closeDrawer={toggleMenu}
                />
            </NavBarActions>
        );
    }

    return (
        <NavBarActions>
            <PsDrawerAvatar userImage={userImage} userName={userName}></PsDrawerAvatar>
            <UserActions
                t={t}
                application={application}
                trackEvent={trackEvent}
                isASitter={isASitter}
                hasACompletedSitterApplication={hasACompletedSitterApplication}
                messageUnreadCounter={messageUnreadCounter}
                userImage={userImage}
                userName={userName}
                openSearch={openSearch}
                closeDrawer={toggleMenu}
                logout={logout}
            />
        </NavBarActions>
    );
};

export default PsNavBarActions;
