import { fromJS } from 'immutable';
import {
    SITTER_DETAIL_SUCCESS,
    SITTER_DETAIL_FAILURE,
    SITTER_DETAIL_REVIEWS_SUCCESS,
    SITTER_DETAIL_REDIRECT_TO_PAGE,
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_SUCCESS,
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_REQUEST,
    SITTER_CAN_BE_CONTACTED_SUCCESS,
} from './constants';

export const initialState = fromJS({
    detail: {
        data: '',
        status: 0,
        redirectToPage: '',
    },
    sitterBookableServiceUnavailableDates: [],
    paginatedReviews: '',
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_REQUEST:
            return state.set('sitterBookableServiceUnavailableDates', []);
        case SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_SUCCESS:
            return state.set('sitterBookableServiceUnavailableDates', payload);
        case SITTER_DETAIL_FAILURE:
            return state.setIn(['detail', 'status'], payload.status);
        case SITTER_DETAIL_SUCCESS:
            return state.setIn(['detail', 'status'], payload.status).setIn(['detail', 'data'], fromJS(payload.data));
        case SITTER_CAN_BE_CONTACTED_SUCCESS:
            return state.setIn(['detail', 'data', 'canBeContactedByPetOwner', 'canBeContacted'], payload);
        case SITTER_DETAIL_REVIEWS_SUCCESS:
            return state.set('paginatedReviews', fromJS(payload));
        case SITTER_DETAIL_REDIRECT_TO_PAGE:
            return state.setIn(['detail', 'status'], payload.status).setIn(['detail', 'redirectToPage'], payload.data);
        default:
            return state;
    }
};
