import createRequestActionStrings from '../../utils/createRequestActionStrings';

export const [SITTER_DETAIL_REQUEST, SITTER_DETAIL_SUCCESS, SITTER_DETAIL_FAILURE] =
    createRequestActionStrings('SITTER_DETAIL');

export const [SITTER_DETAIL_REVIEWS_REQUEST, SITTER_DETAIL_REVIEWS_SUCCESS, SITTER_DETAIL_REVIEWS_FAILURE] =
    createRequestActionStrings('SITTER_DETAIL_REVIEWS');

export const [
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_REQUEST,
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_SUCCESS,
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_FAILURE,
] = createRequestActionStrings('SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES');

export const [SITTER_CAN_BE_CONTACTED_REQUEST, SITTER_CAN_BE_CONTACTED_SUCCESS, SITTER_CAN_BE_CONTACTED_FAILURE] =
    createRequestActionStrings('SITTER_CAN_BE_CONTACTED');

export const REGISTER_PROFILE_VIEW = 'REGISTER_PROFILE_VIEW';

export const SITTER_DETAIL_REDIRECT_TO_PAGE = 'SITTER_DETAIL_REDIRECT_TO_PAGE';
