import { createAction } from 'redux-actions';
import {
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_REQUEST,
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_SUCCESS,
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_FAILURE,
    SITTER_DETAIL_REQUEST,
    SITTER_DETAIL_SUCCESS,
    SITTER_DETAIL_FAILURE,
    SITTER_DETAIL_REVIEWS_REQUEST,
    SITTER_DETAIL_REVIEWS_SUCCESS,
    SITTER_DETAIL_REVIEWS_FAILURE,
    REGISTER_PROFILE_VIEW,
    SITTER_DETAIL_REDIRECT_TO_PAGE,
    SITTER_CAN_BE_CONTACTED_REQUEST,
    SITTER_CAN_BE_CONTACTED_FAILURE,
    SITTER_CAN_BE_CONTACTED_SUCCESS,
} from './constants';

export const sitterDetailRequest = createAction(SITTER_DETAIL_REQUEST);
export const sitterDetailSuccess = createAction(SITTER_DETAIL_SUCCESS);
export const sitterDetailFailure = createAction(SITTER_DETAIL_FAILURE);

export const sitterDetailReviewsRequest = createAction(SITTER_DETAIL_REVIEWS_REQUEST);
export const sitterDetailReviewsSuccess = createAction(SITTER_DETAIL_REVIEWS_SUCCESS);
export const sitterDetailReviewsFailure = createAction(SITTER_DETAIL_REVIEWS_FAILURE);

export const registerProfileView = createAction(REGISTER_PROFILE_VIEW);

export const sitterDetailRedirectToPage = createAction(SITTER_DETAIL_REDIRECT_TO_PAGE);

export const sitterProfileBookableServiceUnavailableDatesRequest = createAction(
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_REQUEST
);

export const sitterProfileBookableServiceUnavailableDatesSuccess = createAction(
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_SUCCESS
);

export const sitterProfileBookableServiceUnavailableDatesFailure = createAction(
    SITTER_PROFILE_BOOKABLE_SERVICE_UNAVAILABLE_DATES_FAILURE
);

export const sitterCanBeContactedRequest = createAction(SITTER_CAN_BE_CONTACTED_REQUEST);

export const sitterCanBeContactedSuccess = createAction(SITTER_CAN_BE_CONTACTED_SUCCESS);

export const sitterCanBeContactedFailure = createAction(SITTER_CAN_BE_CONTACTED_FAILURE);
