import { BaseApi } from '../../Core/BaseApi';

class ProfileSitterListingApi extends BaseApi {
    getPoliceCheck = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, 'profile/sitter-listing/police-check');
    };

    requestPoliceCheck = (jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.User.PoliceCheck.WriteModel.RequestPoliceCheck', {});
    };

    renewPoliceCheck = (jwtToken) => {
        return this.sendCommand(jwtToken, 'Pawshake.User.PoliceCheck.WriteModel.RenewPoliceCheck', {});
    };

    prepareSitterForPayoutInformation = (jwtToken) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Sitter.PayoutInformation.WriteModel.TriggerPrepareForSitterCheckout',
            {}
        );
    };
}

export default new ProfileSitterListingApi();
