import { BaseApi } from '../Pawshake/Core/BaseApi';

class AuthenticationApi extends BaseApi {
    getContactCustomerSupportLink = (worldRegion, jwtToken) => {
        return this.performAuthenticatedGetRequestToSpecificWorldRegion(
            worldRegion,
            jwtToken,
            `contact-customer-support-link`
        );
    };

    getEmailHandlerCopy = (mode, lang) => {
        return this.performGetRequest('EU', `/firebase/email-handler-copy/${mode}/${lang}`);
    };

    getFinalisePawshakeAccount = (worldRegion, jwtToken, language, country) => {
        return this.performAuthenticatedGetRequestToSpecificWorldRegion(
            worldRegion,
            jwtToken,
            `finalise-pawshake-account?language=${language}&country=${country}`
        );
    };

    updateEmailAddress = (worldRegion, jwtToken, password, emailAddress, verifyEmailAddress) => {
        return this.sendCommandToSpecificWorldRegion(
            worldRegion,
            jwtToken,
            'Pawshake.Firebase.EmailChange.WriteModel.UpdateEmailAddress',
            {
                password,
                emailAddress,
                verifyEmailAddress,
            }
        );
    };

    updatePassword = (worldRegion, jwtToken, oldPassword, newPassword, verifyNewPassword) => {
        return this.sendCommandToSpecificWorldRegion(
            worldRegion,
            jwtToken,
            'Pawshake.Firebase.Account.WriteModel.UpdatePassword',
            {
                oldPassword,
                newPassword,
                verifyNewPassword,
            }
        );
    };

    removeUserWithoutEmailAddress = (worldRegion, jwtToken) => {
        return this.sendCommandToSpecificWorldRegion(
            worldRegion,
            jwtToken,
            'Pawshake.Firebase.Account.WriteModel.RemoveFirebaseAccountWithoutEmail',
            {}
        );
    };

    requestMigrationCheck = (worldRegion, emailAddress, language, destinationUrl) => {
        return this.sendAnonCommand(worldRegion, 'Pawshake.Firebase.Migration.WriteModel.RequestMigrationCheck', {
            emailAddress,
            language,
            destinationUrl,
        });
    };

    createUserWithEmailAndPassword = (worldRegion, emailAddress, password, language) => {
        return this.performPostRequest(worldRegion, '/user/sign-up', {
            emailAddress,
            password,
            language,
        });
    };

    finalisePawshakeAccount = (
        worldRegion,
        jwtToken,
        firstName,
        lastName,
        countryCode,
        language,
        profileImage,
        hasApprovedGdpr,
        userOlderThanAgeLimit
    ) => {
        return this.sendCommandToSpecificWorldRegion(
            worldRegion,
            jwtToken,
            'Pawshake.Firebase.Account.WriteModel.FinalisePawshakeAccount',
            {
                firstName,
                lastName,
                countryCode,
                language,
                profileImage,
                hasApprovedGdpr,
                userOlderThanAgeLimit,
            }
        );
    };

    notifyAboutPasswordMigration = (worldRegion, jwtToken) => {
        return this.sendCommandToSpecificWorldRegion(
            worldRegion,
            jwtToken,
            'Pawshake.Firebase.Migration.WriteModel.NotifyAboutPasswordMigration',
            {}
        );
    };

    requestEmailVerification = (worldRegion, locale, emailAddress, destinationUrl) => {
        return this.sendAnonCommand(worldRegion, 'Pawshake.Firebase.Email.WriteModel.UserRequestsEmailVerification', {
            emailAddress,
            locale,
            destinationUrl,
        });
    };

    initiateForgotPasswordFlow = (worldRegion, emailAddress, locale, destinationUrl) => {
        return this.sendAnonCommand(
            worldRegion,
            'Pawshake.Firebase.ForgotPassword.WriteModel.InitiateForgotPasswordFlow',
            {
                emailAddress,
                locale,
                destinationUrl,
            }
        );
    };

    verifyForgotPasswordFlow = (worldRegion, forgotPasswordFlowId) => {
        return this.performGetRequest(worldRegion, `/forgot-password-flow/verify/${forgotPasswordFlowId}`);
    };

    completeForgotPasswordFlow = (worldRegion, forgotPasswordFlowId, newPassword, verifyPassword) => {
        return this.sendAnonCommand(
            worldRegion,
            'Pawshake.Firebase.ForgotPassword.WriteModel.CompleteForgotPasswordFlow',
            {
                forgotPasswordFlowId,
                newPassword,
                verifyPassword,
            }
        );
    };

    requestPasswordResetThroughTextMessage = (worldRegion, emailAddress, language) => {
        return this.sendAnonCommand(
            worldRegion,
            'Pawshake.Firebase.PasswordReset.WriteModel.RequestPasswordResetThroughTextMessage',
            {
                emailAddress,
                language,
            }
        );
    };

    verifyPasswordResetThroughTextMessage = (
        worldRegion,
        emailAddress,
        newPassword,
        verifyPassword,
        passwordResetCode
    ) => {
        return this.sendAnonCommand(
            worldRegion,
            'Pawshake.Firebase.PasswordReset.WriteModel.VerifyPasswordResetThroughTextMessage',
            {
                emailAddress,
                newPassword,
                verifyPassword,
                passwordResetCode,
            }
        );
    };

    notifyUserSignedIn = (jwt) => {
        return this.sendCommand(jwt, 'Pawshake.Authentication.UserSignedIn.WriteModel.NotifyUserHasSignedIn', {
            deviceName: this.getOSName() + ' - ' + this.detectBrowser(),
            apiClient: 'Web',
        });
    };

    detectBrowser = () => {
        let userAgent = navigator.userAgent;
        let browserName;

        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = 'Chrome';
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = 'Firefox';
        } else if (userAgent.match(/safari/i)) {
            browserName = 'Safari';
        } else if (userAgent.match(/opr\//i)) {
            browserName = 'Opera';
        } else if (userAgent.match(/edg/i)) {
            browserName = 'edge';
        } else {
            browserName = 'Other';
        }

        return browserName;
    };

    getOSName = () => {
        let osName = 'Unknown';
        const userAgent = navigator.userAgent;

        if (userAgent.indexOf('Windows') !== -1) {
            osName = 'Windows';
        }
        if (userAgent.indexOf('Mac') !== -1) {
            osName = 'Mac/iOS';
        }
        if (userAgent.indexOf('X11') !== -1) {
            osName = 'UNIX';
        }
        if (userAgent.indexOf('Linux') !== -1) {
            osName = 'Linux';
        }

        return osName;
    };
}

export default new AuthenticationApi();
