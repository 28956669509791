import { call, put, select, takeLatest, fork } from 'redux-saga/effects';
import petApi from '../../Pawshake/User/petApi';
import { PET_DETAIL_REQUEST, ENRICH_PETS_WITH_DETAILS_REQUEST } from './constants';
import {
    enrichPetsWithDetailsFailure,
    enrichPetsWithDetailsSuccess,
    petDetailFailure,
    petDetailSuccess,
} from './actions';
import { jwtTokenSelect } from '../User/Authentication/selectors';

function* fetchPetDetailFlow({ payload }) {
    const { petId } = payload;
    const response = yield call(petApi.getProfile, petId);

    if (response.status === 200) {
        yield put(petDetailSuccess(response));
    } else {
        yield put(petDetailFailure(response));
    }
}

function* fetchPetDetailFlowWatcher() {
    yield takeLatest(PET_DETAIL_REQUEST, fetchPetDetailFlow);
}

function* enrichPetsWithDetailsFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(petApi.enrichPets, jwtToken, payload);

    if (response.status === 200) {
        yield put(enrichPetsWithDetailsSuccess(response.data));
    } else {
        yield put(enrichPetsWithDetailsFailure());
    }
}

function* enrichPetsWithDetailsFlowWatcher() {
    yield takeLatest(ENRICH_PETS_WITH_DETAILS_REQUEST, enrichPetsWithDetailsFlow);
}

export default [fork(fetchPetDetailFlowWatcher), fork(enrichPetsWithDetailsFlowWatcher)];
