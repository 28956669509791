import { stringify } from 'qs';
import { GB } from '../Core/Locale/Country';
import { precisionRound } from '../../utils/math';
import first from 'lodash/first';
import last from 'lodash/last';
import parseISO from 'date-fns/parseISO';
import { formatSearchFilterDates, NON_CONSECUTIVE_DATE_SELECTION } from '../../utils/dates';
import { LOCATION_BASED } from '../Core/Search/SearchTypes';

export const determineSitterHighlightedVibrancy = (vibrancies) => {
    if (vibrancies.length > 0) {
        return vibrancies[0];
    }
    return false;
};

export const determineFiveStarReview = (reviews, service, maxContentLength, language) => {
    if (typeof reviews === 'undefined') {
        return false;
    }

    if (reviews.hasOwnProperty(service.id) === false) {
        return false;
    }

    if (reviews[service.id].hasOwnProperty(language) === false) {
        return false;
    }

    const review = reviews[service.id][language];

    return {
        comment: review.comment.substring(0, maxContentLength) + ' ... ',
        seoScore: review.seoScore,
        reviewer: {
            profileImageUrl: review.profileImageUrl,
            userName: review.userName,
        },
    };
};

export const determineProfileDescription = (description, maxContentLength) => {
    return description.substring(0, maxContentLength) + ' ... ';
};

export const showFiveStarReviewInsteadOfProfileDescription = (fiveStarReview, profileDescriptionSeoScore) => {
    return fiveStarReview && fiveStarReview.seoScore > profileDescriptionSeoScore;
};

export const displayDistance = (miles, km, country) => {
    if (country === GB) {
        return precisionRound(miles, 1) + ' miles';
    }

    return precisionRound(km, 1) + ' km';
};

export const summariseAdvancedFilters = (t, sitterHasNoDogs, sitterHasNoChildren, sitterHasFullyFencedBackyard) => {
    const activeAdvancedFilters = [sitterHasNoDogs, sitterHasNoChildren, sitterHasFullyFencedBackyard].filter(
        (x) => x
    ).length;

    return activeAdvancedFilters
        ? t('pages.search.filters:pages.search.filters.overview.values.advancedFilters', {
              count: activeAdvancedFilters,
          })
        : null;
};

export const summarisePetFilters = (t, smallDog, mediumDog, largeDog, giantDog, cat, smallAnimal) => {
    const totalPets = smallDog + mediumDog + largeDog + giantDog + cat + smallAnimal;

    return totalPets
        ? t('pages.search.filters:pages.search.filters.overview.values.pets', {
              count: totalPets,
          })
        : null;
};

export const summariseDateSelection = (t, selectedDays, typeOfDateSelection, service, application) => {
    if (selectedDays.length === 0) {
        return null;
    }
    const { locale } = application;

    const firstDate =
        typeof first(selectedDays) === 'string'
            ? parseISO(first(selectedDays)).toLocaleDateString(locale)
            : first(selectedDays).toLocaleDateString(locale);
    const lastDate =
        typeof last(selectedDays) === 'string'
            ? parseISO(last(selectedDays)).toLocaleDateString(locale)
            : last(selectedDays).toLocaleDateString();

    if (typeOfDateSelection === NON_CONSECUTIVE_DATE_SELECTION) {
        return t('common:datePicker.multipleDates.value', {
            totalDays: selectedDays.length,
            firstDay: firstDate,
            lastDay: lastDate,
        });
    }

    return `${firstDate} - ${lastDate}`;
};

export const buildUserInitiatedSearchQuery = (
    locale,
    typeOfDateSelection,
    advancedFilters,
    pets,
    pageNumber,
    service,
    days,
    placeName,
    location,
    boundingBox,
    searchType
) => {
    let query = {
        localeString: typeof locale === 'Locale' ? locale.toString() : locale.language + '-' + locale.country,
    };

    if (service) {
        query.service = service.id;
    }

    if (placeName) {
        query.placeName = placeName;
    }

    if (searchType === LOCATION_BASED) {
        query.latitude = location.latitude;
        query.longitude = location.longitude;
    } else {
        query.boundingBox = stringify(boundingBox);
    }

    if (typeOfDateSelection) {
        query.typeOfDateSelection = typeOfDateSelection;
    }

    if (days && days.length > 0) {
        query.days = formatSearchFilterDates(days).join(',');
    }

    if (advancedFilters) {
        query.sitterHasNoDogs = advancedFilters.sitterHasNoDogs ?? false;
        query.sitterHasNoChildren = advancedFilters.sitterHasNoChildren ?? false;
        query.sitterHasFullyFencedBackyard = advancedFilters.sitterHasFullyFencedBackyard ?? false;
    }

    if (pets) {
        query.smallDog = pets.smallDog ?? 0;
        query.mediumDog = pets.mediumDog ?? 0;
        query.largeDog = pets.largeDog ?? 0;
        query.giantDog = pets.giantDog ?? 0;
        query.cat = pets.cat ?? 0;
        query.smallAnimal = pets.smallAnimal ?? 0;
    }

    if (pageNumber) {
        query.page = pageNumber;
    }

    query.searchType = searchType;

    return query;
};
