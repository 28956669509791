import Link from 'next/link';
import PsNavBarButton from '../../templates/navbar-button';
import PsNavBarLink from '../../templates/navbar-link';

const FirebaseMenu = ({
    t,
    zendeskLanguage,
    logout,
    closeDrawer,
}: {
    zendeskLanguage: string;
    logout: () => void;
    closeDrawer: () => void;
    t: (_key: string) => string;
}) => {
    const onLogoutButtonClick = () => {
        logout();
        closeDrawer();
    };
    return (
        <>
            <PsNavBarButton onClick={onLogoutButtonClick} title={t('user.menu.logout')}>
                {t('user.menu.logout')}
            </PsNavBarButton>
            <Link href={`https://pawshake.zendesk.com/hc/${zendeskLanguage}`} passHref={true}>
                <PsNavBarLink onClick={closeDrawer} title={t('user.menu.help')}>
                    {t('user.menu.help')}
                </PsNavBarLink>
            </Link>
        </>
    );
};

export default FirebaseMenu;
