import styled from 'styled-components';
import Breakpoints from '../../../../../styles/breakpoints';
import PsNavBarLogo from '../navbar/logo';

const DrawerLogo = styled.div`
    margin-bottom: 2rem;
    margin-top: 0.75rem;

    @media ${Breakpoints.md} {
        && {
            display: none;
        }
    }
`;

const PsDrawerLogo = ({ onClick }: { onClick: () => void }) => (
    <DrawerLogo onClick={onClick}>
        <PsNavBarLogo />
    </DrawerLogo>
);

export default PsDrawerLogo;
