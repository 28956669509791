import React from 'react';
import Counter from '../Shared/Counter/Counter';
import { withTranslation } from '../../i18n';

const PetsSelect = (props) => {
    const { t, onChange, smallDog, mediumDog, largeDog, giantDog, cat, smallAnimal } = props;

    return (
        <>
            <Counter
                limit={10}
                name={'smallDog'}
                label={t('pages.search.filters.pets.smallDog.label')}
                description={t('pages.search.filters.pets.smallDog.description')}
                onChange={onChange}
                count={smallDog}
            />
            <Counter
                limit={10}
                name={'mediumDog'}
                label={t('pages.search.filters.pets.mediumDog.label')}
                description={t('pages.search.filters.pets.mediumDog.description')}
                onChange={onChange}
                count={mediumDog}
            />
            <Counter
                limit={10}
                name={'largeDog'}
                label={t('pages.search.filters.pets.largeDog.label')}
                description={t('pages.search.filters.pets.largeDog.description')}
                onChange={onChange}
                count={largeDog}
            />
            <Counter
                limit={10}
                name={'giantDog'}
                label={t('pages.search.filters.pets.giantDog.label')}
                description={t('pages.search.filters.pets.giantDog.description')}
                onChange={onChange}
                count={giantDog}
            />
            <Counter
                limit={10}
                name={'cat'}
                label={t('pages.search.filters.pets.cat.label')}
                description={t('pages.search.filters.pets.cat.description')}
                onChange={onChange}
                count={cat}
            />
            <Counter
                limit={10}
                name={'smallAnimal'}
                label={t('pages.search.filters.pets.smallAnimal.label')}
                description={t('pages.search.filters.pets.smallAnimal.description')}
                onChange={onChange}
                count={smallAnimal}
            />
        </>
    );
};

export default withTranslation('pages.search.filters')(PetsSelect);
