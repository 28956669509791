import React from 'react';
import styled from 'styled-components';
import { withTranslation } from '../../../i18n';

const Wrapper = styled.div`
    position: relative;
    margin-bottom: 1rem;
`;

const Divider = styled.div`
    text-align: center;

    &:before,
    &:after {
        border-bottom: 1px solid #cecece;
        content: '';
        position: absolute;
        top: 60%;
    }

    &:before {
        right: 55%;
        left: 0;
    }

    &:after {
        left: 55%;
        right: 0;
    }
`;

const OrDivider = ({ t, className }) => {
    return (
        <Wrapper className={className}>
            <Divider>
                <span>{t('or')}</span>
            </Divider>
        </Wrapper>
    );
};

export default withTranslation('common')(OrDivider);
