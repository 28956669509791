import { createAction } from 'redux-actions';
import {
    ENRICH_USERS_WITH_DETAILS_CLEANUP,
    ENRICH_USERS_WITH_DETAILS_FAILURE,
    ENRICH_USERS_WITH_DETAILS_REQUEST,
    ENRICH_USERS_WITH_DETAILS_SUCCESS,
    FIREBASE_AUTHENTICATED,
    USER_JWT_TOKEN_LOADING,
    USER_JWT_TOKEN_SUCCESS,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
} from './constants';

export const enrichUsersWithDetailsRequest = createAction(ENRICH_USERS_WITH_DETAILS_REQUEST);

export const enrichUsersWithDetailsSuccess = createAction(ENRICH_USERS_WITH_DETAILS_SUCCESS);

export const enrichUsersWithDetailsFailure = createAction(ENRICH_USERS_WITH_DETAILS_FAILURE);

export const enrichUsersWithDetailsCleanup = createAction(ENRICH_USERS_WITH_DETAILS_CLEANUP);

export const userJwtTokenLoading = createAction(USER_JWT_TOKEN_LOADING);
export const firebaseAuthenticated = createAction(FIREBASE_AUTHENTICATED);

export const userJwtTokenSuccess = createAction(USER_JWT_TOKEN_SUCCESS);

export const userLogOutRequest = createAction(USER_LOGOUT_REQUEST);

export const userLogOutSuccess = createAction(USER_LOGOUT_SUCCESS);
