import styled from 'styled-components';

const StyledMessage = styled.div`
    padding: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
`;

const Message = ({ message, className }) => {
    return <StyledMessage className={className}>{message}</StyledMessage>;
};

export default Message;
