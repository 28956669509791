import { useEffect, useState } from 'react';

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const convertNewLineTranslationToParagraphs = (translation) => {
    if (!translation) {
        return '';
    }

    return translation.split('\n').map((line, index) => <p key={index}>{line}</p>);
};

export const withoutWorldRegionPrefix = (prefixedId) => {
    return prefixedId.replace('APAC_', '').replace('EU_', '');
};

export const getWorldRegionOfCountry = (country) => {
    if (['AU', 'NZ', 'HK', 'SG', 'JP'].includes(country)) {
        return 'APAC';
    }

    return 'EU';
};

export const convertToDictionary = (array, key) => {
    return Object.assign({}, ...array.map((x) => ({ [x[key]]: x })));
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
};

// Hook
export const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
};

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
const dec2hex = (dec) => {
    return dec.toString(16).padStart(2, '0');
};

// generateId :: Integer -> String
export const generateId = (len) => {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
};
