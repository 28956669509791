import { BaseApi } from '../Core/BaseApi';

class UserApi extends BaseApi {
    enrichUsers = (jwtToken, petOwnerUserIds) => {
        return this.performAuthenticatedGetRequest(jwtToken, `user/details/${petOwnerUserIds.join(',')}`);
    };

    getMinimumProfileRequirementsBanner = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/minimum-profile-requirements');
    };
}

export default new UserApi();
