import { call, put, takeLatest, fork, select } from 'redux-saga/effects';
import { INBOX_ARCHIVE_REQUEST, INBOX_REQUEST, INBOX_UNREAD_COUNTER_REQUEST } from './constants';
import { jwtTokenSelect } from '../User/Authentication/selectors';
import NewInboxApi from '../../Pawshake/Inbox/NewInboxApi';
import {
    inboxArchiveFailure,
    inboxArchiveSuccess,
    inboxFailure,
    inboxSuccess,
    inboxUnreadCounterFailure,
    inboxUnreadCounterSuccess,
} from './actions';
import { trackInboxArchiveEvent, trackInboxEvent } from '../../Application/Inbox/Analytics';
import { USER_JWT_TOKEN_SUCCESS } from '../User/Authentication/constants';
import { ARCHIVE_CONVERSATION_SUCCESS, UNARCHIVE_CONVERSATION_SUCCESS } from '../Conversation/constants';
import { NO_FILTER } from '../../Pawshake/Inbox/InboxFilter';

function* inboxRequestFlow({ type, payload }) {
    if (type === ARCHIVE_CONVERSATION_SUCCESS || UNARCHIVE_CONVERSATION_SUCCESS === type) {
        if (payload.origin !== 'inbox') {
            return;
        }

        if (typeof payload.originParameters !== 'undefined') {
            payload.pageNumber = payload.originParameters.pageNumber ?? 1;
            payload.pageSize = payload.originParameters.pageSize ?? 10;
            payload.inboxFilter = payload.originParameters.inboxFilter ?? NO_FILTER;
        }
    }

    trackInboxEvent(`Entries: ${payload.inboxFilter ?? NO_FILTER}`, 'Request attempt');
    const jwtToken = yield select(jwtTokenSelect);

    const response = yield call(
        NewInboxApi.getInbox,
        jwtToken,
        payload.pageNumber ?? 1,
        payload.pageSize ?? 10,
        payload.inboxFilter ?? NO_FILTER
    );

    if (response.status === 200) {
        trackInboxEvent(`Entries: ${payload.inboxFilter}`, 'Request successful');
        yield put(inboxSuccess(response.data));
    } else {
        trackInboxEvent(`Entries: ${payload.inboxFilter}`, 'Request failed');
        yield put(inboxFailure(response.data));
    }
}

function* inboxRequestFlowWatcher() {
    yield takeLatest([INBOX_REQUEST, ARCHIVE_CONVERSATION_SUCCESS, UNARCHIVE_CONVERSATION_SUCCESS], inboxRequestFlow);
}

function* inboxUnreadCounterRequestFlow() {
    trackInboxEvent('Unread counter', 'Request attempt');
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(NewInboxApi.getUnreadCounter, jwtToken);

    if (response.status === 200) {
        trackInboxEvent('Unread counter', 'Request successful');
        yield put(inboxUnreadCounterSuccess(response.data));
    } else {
        trackInboxEvent('Unread counter', 'Request failed');
        yield put(inboxUnreadCounterFailure(response.data));
    }
}

function* inboxUnreadCounterRequestFlowWatcher() {
    yield takeLatest([INBOX_UNREAD_COUNTER_REQUEST, USER_JWT_TOKEN_SUCCESS], inboxUnreadCounterRequestFlow);
}

function* inboxArchiveRequestFlow({ type, payload }) {
    if (type === ARCHIVE_CONVERSATION_SUCCESS || UNARCHIVE_CONVERSATION_SUCCESS === type) {
        if (payload.origin !== 'inboxArchive') {
            return;
        }

        if (typeof payload.originParameters !== 'undefined') {
            payload.pageNumber = payload.originParameters.pageNumber ?? 1;
            payload.pageSize = payload.originParameters.pageSize ?? 10;
        }
    }

    trackInboxArchiveEvent('Entries', 'Request attempt');
    const jwtToken = yield select(jwtTokenSelect);

    const response = yield call(NewInboxApi.getInboxArchive, jwtToken, payload.pageNumber ?? 1, payload.pageSize ?? 10);

    if (response.status === 200) {
        trackInboxEvent('Entries', 'Request successful');
        yield put(inboxArchiveSuccess(response.data));
    } else {
        trackInboxEvent('Entries', 'Request failed');
        yield put(inboxArchiveFailure(response.data));
    }
}

function* inboxArchiveRequestFlowWatcher() {
    yield takeLatest(
        [INBOX_ARCHIVE_REQUEST, ARCHIVE_CONVERSATION_SUCCESS, UNARCHIVE_CONVERSATION_SUCCESS],
        inboxArchiveRequestFlow
    );
}

export default [
    fork(inboxRequestFlowWatcher),
    fork(inboxArchiveRequestFlowWatcher),
    fork(inboxUnreadCounterRequestFlowWatcher),
];
