import { call, put, takeLatest, fork, select } from 'redux-saga/effects';
import petApi from '../../../Pawshake/User/petApi';
import { USER_PETS_REQUEST } from './constants';
import { userPetsFailure, userPetsSuccess } from './actions';
import { jwtTokenSelect } from '../Authentication/selectors';

function* userPetsRequest() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(petApi.getPets, jwtToken);

    if (response.status === 200) {
        yield put(userPetsSuccess(response.data));
    } else {
        yield put(userPetsFailure(response.status));
    }
}

function* userPetsRequestWatcher() {
    yield takeLatest(USER_PETS_REQUEST, userPetsRequest);
}

export default [fork(userPetsRequestWatcher)];
