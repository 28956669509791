import React, { createContext } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const AuthServiceContext = createContext({});

export default function withAuthService(WrappedComponent) {
    const AuthServiceComponent = function AuthServiceComponent(props) {
        return (
            <AuthServiceContext.Consumer>
                {(authService) => <WrappedComponent {...props} authService={authService} />}
            </AuthServiceContext.Consumer>
        );
    };
    hoistNonReactStatics(AuthServiceComponent, WrappedComponent);
    return AuthServiceComponent;
}
