import React, { useState } from 'react';
import { withTranslation } from '../../../i18n';
import { withApplicationAwareness } from '../../../contexts/ApplicationContext';
import SearchPopupV3Container from '../../../Infrastructure/Search/SearchPopupV3Container';
import { trackEvent } from '../../../lib/analytics';
import PawshakeNavBar from '../NavBar/NavBar';
import {
    isAuthenticatedButWithoutPawshakeAccount,
    isJwtAuthenticated,
    isJwtAuthenticatedLoading,
    selectHasACompletedSitterApplication,
    selectJwtTokenAvatar,
    selectJwtTokenBelongsToASitter,
    selectJwtTokenFirstName,
} from '../../../domain/User/Authentication/selectors';
import { userLogOutRequest } from '../../../domain/User/Authentication/actions';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import transformImmutableToJS from '../../../utils/transformImmutableToJS';
import { createStructuredSelector } from 'reselect';
import { selectInboxUnreadCounter } from '../../../domain/Inbox/selectors';
import withTrackingService from '../../../contexts/TrackingServiceContext';

const Header = ({
    t,
    isJwtAuthenticated,
    isAuthenticatedButWithoutPawshakeAccount,
    isJwtAuthenticatedLoading,
    userLogOutRequest,
    authService,
    trackingService,
    isASitter,
    hasACompletedSitterApplication,
    userImage,
    userName,
    messageUnreadCounter,
    application,
}) => {
    const [searchIsOpen, setSearchIsOpen] = useState(false);

    const openSearchModal = () => {
        setSearchIsOpen(true);
    };

    const logout = () => {
        userLogOutRequest();
        authService.logout(true);
    };

    const trackNavBarEvent = (fsCategory, gaCategory, action, eventLabel) => {
        trackEvent(gaCategory, action, eventLabel);
        trackingService.trackEvent(fsCategory, action, eventLabel);
    };

    const translate = (key) => t(key);

    return (
        <>
            <PawshakeNavBar
                application={application}
                isAuthenticated={isJwtAuthenticated}
                isAuthenticatedLoading={isJwtAuthenticatedLoading}
                isAuthenticatedButWithoutPawshakeAccount={isAuthenticatedButWithoutPawshakeAccount}
                userImage={userImage}
                userName={userName}
                messageUnreadCounter={messageUnreadCounter}
                isASitter={isASitter}
                hasACompletedSitterApplication={hasACompletedSitterApplication}
                t={translate}
                openSearch={openSearchModal}
                trackEvent={trackNavBarEvent}
                logout={logout}
            ></PawshakeNavBar>
            <SearchPopupV3Container searchIsOpen={searchIsOpen} setIsOpen={setSearchIsOpen} />
        </>
    );
};

const mapStateToProps = (state) => {
    const computedData = createStructuredSelector({
        isJwtAuthenticated,
        isAuthenticatedButWithoutPawshakeAccount,
        isJwtAuthenticatedLoading,
        isASitter: selectJwtTokenBelongsToASitter,
        hasACompletedSitterApplication: selectHasACompletedSitterApplication,
        userName: selectJwtTokenFirstName,
        userImage: selectJwtTokenAvatar,
        messageUnreadCounter: selectInboxUnreadCounter,
    })(state);

    return transformImmutableToJS(computedData);
};

export default withApplicationAwareness(
    withTrackingService(
        flowRight(withTranslation(['user', 'profile']), connect(mapStateToProps, { userLogOutRequest }))(Header)
    )
);
