import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { FEATURED_TOP_REVIEW_REQUEST, TOP_REVIEWS_REQUEST } from './constants';
import { topReviewFailure, topReviewSuccess, featuredTopReviewSuccess, featuredTopReviewFailure } from './actions';
import TopReviewsApi from '../../Pawshake/TopReviews/TopReviewsApi';

function* topReviewsOverviewFlow({ payload }) {
    try {
        const topReviews = yield call(TopReviewsApi.getForLocale, payload);
        yield put(topReviewSuccess(topReviews));
    } catch (error) {
        topReviewFailure(error.data);
    }
}

function* topReviewsOverviewFlowWatcher() {
    yield takeLatest(TOP_REVIEWS_REQUEST, topReviewsOverviewFlow);
}

function* featuredTopReviewFlow({ payload }) {
    try {
        const { locale } = payload;
        const featuredTopReview = yield call(TopReviewsApi.getFeaturedTopReviewForLocale, locale);
        yield put(featuredTopReviewSuccess(featuredTopReview));
    } catch (error) {
        featuredTopReviewFailure(error.data);
    }
}

function* featuredTopReviewFlowWatcher() {
    yield takeLatest(FEATURED_TOP_REVIEW_REQUEST, featuredTopReviewFlow);
}

export default [fork(topReviewsOverviewFlowWatcher), fork(featuredTopReviewFlowWatcher)];
