import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useAuthUser } from 'next-firebase-auth';
import { withTranslation } from '../../i18n';
import { combineTitleWithLocalBrandName } from '../../utils/page';
import PageContainer from '../../Application/Layout/PageContainer';
import Button from '../../Infrastructure/Shared/Button';
import styled from 'styled-components';
import { showFailedToast, showSuccessToast } from '../../utils/toasts';
import withFirebaseAuthenticatedUserPage from '../../contexts/WithFirebaseAuthenticatedUserPage';
import withAuthService from '../../contexts/AuthServiceContext';
import InlineSVG from 'react-inlinesvg';

const Action = styled(Button)`
    display: block;
    margin-bottom: 1rem;
`;

const Wrapper = styled.div`
    max-width: 640px;
    margin: 0 auto;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const Illustration = styled(InlineSVG)`
    max-width: 200px;
    margin-bottom: 2.5rem;
`;

export const emailVerificationRequestedLocaleStorageKey = 'emailVerificationRequested';

const EmailVerificationPage = ({ t, authService }) => {
    const currentUser = useAuthUser();

    const [showRetry, setShowRetry] = useState(false);

    const triggerEmailVerification = () => {
        const emailAddress = authService.getEmailAddress();
        if (emailAddress && currentUser.emailVerified === false) {
            authService.sendEmailVerification(emailAddress).then((response) => {
                if (response.status === 204) {
                    showSuccessToast(t('pages.emailVerificationPage.toasts.success'));
                    localStorage.setItem(emailVerificationRequestedLocaleStorageKey, 'true');
                } else {
                    showFailedToast(t('pages.emailVerificationPage.toasts.failed'));
                }
            });
        }
    };

    useEffect(() => {
        const item = localStorage.getItem(emailVerificationRequestedLocaleStorageKey);
        if (item === null) {
            triggerEmailVerification();
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowRetry(currentUser.emailVerified === false);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const retryOnClick = (e) => {
        e.preventDefault();
        triggerEmailVerification();
        setShowRetry(false);
    };

    return (
        <>
            <Head>
                <title>{combineTitleWithLocalBrandName(t('pages.emailVerificationPage.title'))}</title>
                <meta name={'robots'} content={'noindex'} />
            </Head>
            <PageContainer>
                {currentUser.emailVerified && (
                    <Wrapper>
                        <Illustration src={'/static/img-email-verified.svg'} />
                        <h1>{t('pages.emailVerificationPage.succeeded.title')}</h1>
                    </Wrapper>
                )}
                {currentUser.emailVerified === false && (
                    <Wrapper>
                        <Illustration src={'/static/img-verify-email.svg'} />
                        <h1>{t('pages.emailVerificationPage.title')}</h1>
                        <p>{t('pages.emailVerificationPage.body')}</p>
                        {showRetry && (
                            <Action isLinkButton={true} onClick={retryOnClick}>
                                {t('pages.emailVerificationPage.retry.label')}
                            </Action>
                        )}
                    </Wrapper>
                )}
            </PageContainer>
        </>
    );
};

EmailVerificationPage.getInitialProps = async () => {
    return {
        namespacesRequired: ['pages.account'],
    };
};

export default withFirebaseAuthenticatedUserPage(
    withAuthService(withTranslation('pages.account')(EmailVerificationPage))
);
