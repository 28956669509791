import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dropdown from '../Shared/Dropdown';
import ServiceFakeInput from './ServiceFakeInput';
import ServiceSelect from './ServiceSelect';
import { Backdrop, DropdownContent, DropdownContentWithAnchorRight, Wrapper } from '../Shared/Dropdown/Dropdown';
import breakpoint from 'styled-components-breakpoint';

const Menu = styled.div`
    max-width: 100%;
`;

const Services = styled(ServiceSelect)`
    ${breakpoint('md')`
    min-width: 25rem;
  `};
`;

class ServiceDropdown extends Dropdown {
    state = {
        isOpen: false,
        activeService: this.props.service,
    };

    handleOnClick = (service) => {
        const { onUpdate, trackApply } = this.props;

        this.setState({
            activeService: service,
            isOpen: !this.state.isOpen,
        });

        if (trackApply) {
            trackApply();
        }

        onUpdate(service);
    };

    renderInput() {
        const { activeService, isOpen } = this.state;
        return <ServiceFakeInput service={activeService} isOpen={isOpen} />;
    }

    renderMenu() {
        const { activeService } = this.state;
        const { services } = this.props;
        return (
            <Menu>
                <Services services={services} activeService={activeService} onClick={this.handleOnClick} />
            </Menu>
        );
    }

    render() {
        const { isOpen } = this.state;
        const { provideActions, anchorRight, showBackdrop, className, customWidth } = this.props;

        const Content = anchorRight ? DropdownContentWithAnchorRight : DropdownContent;

        return (
            <Wrapper className={className}>
                <div onMouseDown={this.handleMouseDown}>{this.renderInput()}</div>
                {isOpen && (
                    <>
                        <Content customWidth={customWidth}>
                            {this.renderMenu()}
                            {provideActions && this.renderMenuActions()}
                        </Content>
                        {showBackdrop && <Backdrop />}
                    </>
                )}
            </Wrapper>
        );
    }
}

ServiceDropdown.defaultProps = {
    showBackdrop: false,
};

ServiceDropdown.propTypes = {
    className: PropTypes.string,
    service: PropTypes.object.isRequired,
    services: PropTypes.array.isRequired,
    showBackdrop: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
};

export default ServiceDropdown;
