import { BaseApi } from '../Core/BaseApi';
import { NO_FILTER } from './InboxFilter';

class NewInboxApi extends BaseApi {
    getInbox = (jwtToken, pageNumber, pageSize, inboxFilter) => {
        let url = `/inbox/entries?page[number]=${pageNumber}&page[size]=${pageSize}`;
        if (inboxFilter !== NO_FILTER) {
            url += `&filter=${inboxFilter}`;
        }

        return this.performAuthenticatedGetRequest(jwtToken, url);
    };

    getUnreadCounter = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, 'inbox/unread-counter');
    };

    getInboxArchive = (jwtToken, pageNumber, pageSize) => {
        return this.performAuthenticatedGetRequest(
            jwtToken,
            `/inbox/entries/archive?page[number]=${pageNumber}&page[size]=${pageSize}`
        );
    };
}

export default new NewInboxApi();
