import { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Breakpoints from '../../../../styles/breakpoints';
import Colors from '../../../../styles/colors';

const animationDuration = 300;
const fadeInKeyframes = keyframes`
    from {opacity: 0}
    to {opacity: 1}
`;
const fadeOutKeyframes = keyframes`
    from {opacity: 0}
    to {opacity: 1}
`;
const slideInKeyframes = (toggle: boolean) => keyframes`
    from {left: -320px}
    to {left: ${toggle ? 0 : '-320px'}
`;
const slideOutKeyframes = keyframes`
    from {left: 0}
    to {left: -320px}
`;

const NavBarBackdrop = styled.div<{ isOpen: boolean; toggle: boolean }>`
    position: fixed;
    background-color: ${(props) => (!props.isOpen ? 'transparent' : 'rgba(0, 0, 0, 0.48)')};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    z-index: ${(props) => (!props.isOpen ? -1000 : 1000)};
    opacity: ${(props) => (props.toggle || !props.isOpen ? 0 : 1)};
    transition: opacity ${animationDuration}ms ease-out;
    animation: ${(props) => (props.toggle ? fadeOutKeyframes : fadeInKeyframes)} ${animationDuration}ms ease-out;
`;

const NavBarContent = styled.div<{ isOpen: boolean; toggle: boolean }>`
    position: fixed;
    top: 0;
    left: ${(props) => (props.toggle || !props.isOpen ? '-320px' : 0)};
    padding: 1.5rem 1rem;
    width: 320px;
    height: 100%;
    z-index: 2000;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    background: ${Colors.brand.default};
    animation: ${(props) => (props.toggle ? slideOutKeyframes : slideInKeyframes(props.isOpen))} ${animationDuration}ms
        ease-out;
    -webkit-user-select: none;
    user-select: none;
    overflow-y: scroll;

    @media ${Breakpoints.md} {
        && {
            animation: none;
            position: relative;
            display: flex;
            align-items: center;
            width: auto;
            height: 100%;
            top: 0;
            left: 0;
            padding: 0;
            margin-right: -0.5rem; //align icon with main content
            overflow-y: visible; //needed to display the profile-actions-dropdown
        }
    }
`;

const NavBarWrapper = ({ isOpen, onClose, children }: { isOpen: boolean; onClose(): void; children: JSX.Element }) => {
    const [toggle, setToggle] = useState(false);

    return (
        <>
            <NavBarContent isOpen={isOpen} toggle={toggle}>
                {children}
            </NavBarContent>
            <NavBarBackdrop
                isOpen={isOpen}
                toggle={toggle}
                onClick={() => {
                    setToggle(true);
                    //make sure animation is finished before 'hiding' component
                    setTimeout(() => {
                        setToggle(false);
                        onClose();
                    }, animationDuration);
                }}
            />
        </>
    );
};

export default NavBarWrapper;
