import { createAction } from 'redux-actions';
import {
    ACCEPT_BOOKING_FAILURE,
    ACCEPT_BOOKING_REQUEST,
    ACCEPT_BOOKING_SUCCESS,
    ALTER_BOOKING_FAILURE,
    ALTER_BOOKING_REQUEST,
    ALTER_BOOKING_SUCCESS,
    BOOKING_DETAILS_SUMMARY_FAILURE,
    BOOKING_DETAILS_SUMMARY_REQUEST,
    BOOKING_DETAILS_SUMMARY_SUCCESS,
    CANCEL_BOOKING_FAILURE,
    CANCEL_BOOKING_REQUEST,
    CANCEL_BOOKING_SUCCESS,
    DECLINE_BOOKING_FAILURE,
    DECLINE_BOOKING_REQUEST,
    DECLINE_BOOKING_SUCCESS,
    GIVE_DISCOUNT_FAILURE,
    GIVE_DISCOUNT_REQUEST,
    GIVE_DISCOUNT_SUCCESS,
    REMOVE_DISCOUNT_FAILURE,
    REMOVE_DISCOUNT_REQUEST,
    REMOVE_DISCOUNT_SUCCESS,
    REQUEST_BOOKING_FAILURE,
    REQUEST_BOOKING_REQUEST,
    REQUEST_BOOKING_SUCCESS,
    REVIEW_BOOKING_FAILURE,
    REVIEW_BOOKING_REQUEST,
    REVIEW_BOOKING_SUCCESS,
    RETRY_PAYOUT_REQUEST,
    RETRY_PAYOUT_SUCCESS,
    RETRY_PAYOUT_FAILURE,
    UNCONVERTED_BOOKING_FAILURE,
    UNCONVERTED_BOOKING_REQUEST,
    UNCONVERTED_BOOKING_SUCCESS,
    PREFER_NOT_TO_REVIEW_BOOKING_REQUEST,
    PREFER_NOT_TO_REVIEW_BOOKING_SUCCESS,
    PREFER_NOT_TO_REVIEW_BOOKING_FAILURE,
    RESET_BOOKING_REQUEST,
    BOOKABLE_RATES_REQUEST,
    BOOKABLE_RATES_SUCCESS,
    BOOKABLE_RATES_FAILURE,
} from './constants';

export const bookingDetailsSummaryRequest = createAction(BOOKING_DETAILS_SUMMARY_REQUEST);

export const bookingDetailsSummarySuccess = createAction(BOOKING_DETAILS_SUMMARY_SUCCESS);

export const bookingDetailsSummaryFailure = createAction(BOOKING_DETAILS_SUMMARY_FAILURE);

export const cancelBookingRequest = createAction(CANCEL_BOOKING_REQUEST);
export const cancelBookingSuccess = createAction(CANCEL_BOOKING_SUCCESS);
export const cancelBookingFailure = createAction(CANCEL_BOOKING_FAILURE);

export const resetBookingRequest = createAction(RESET_BOOKING_REQUEST);

export const requestBookingRequest = createAction(REQUEST_BOOKING_REQUEST);
export const requestBookingSuccess = createAction(REQUEST_BOOKING_SUCCESS);
export const requestBookingFailure = createAction(REQUEST_BOOKING_FAILURE);

export const acceptBookingRequest = createAction(ACCEPT_BOOKING_REQUEST);
export const acceptBookingSuccess = createAction(ACCEPT_BOOKING_SUCCESS);
export const acceptBookingFailure = createAction(ACCEPT_BOOKING_FAILURE);

export const declineBookingRequest = createAction(DECLINE_BOOKING_REQUEST);
export const declineBookingSuccess = createAction(DECLINE_BOOKING_SUCCESS);
export const declineBookingFailure = createAction(DECLINE_BOOKING_FAILURE);

export const reviewBookingRequest = createAction(REVIEW_BOOKING_REQUEST);
export const reviewBookingSuccess = createAction(REVIEW_BOOKING_SUCCESS);
export const reviewBookingFailure = createAction(REVIEW_BOOKING_FAILURE);

export const preferToNotReviewBookingRequest = createAction(PREFER_NOT_TO_REVIEW_BOOKING_REQUEST);
export const preferToNotReviewBookingSuccess = createAction(PREFER_NOT_TO_REVIEW_BOOKING_SUCCESS);
export const preferToNotReviewBookingFailure = createAction(PREFER_NOT_TO_REVIEW_BOOKING_FAILURE);

export const alterBookingRequest = createAction(ALTER_BOOKING_REQUEST);
export const alterBookingSuccess = createAction(ALTER_BOOKING_SUCCESS);
export const alterBookingFailure = createAction(ALTER_BOOKING_FAILURE);

export const giveDiscountRequest = createAction(GIVE_DISCOUNT_REQUEST);
export const giveDiscountSuccess = createAction(GIVE_DISCOUNT_SUCCESS);
export const giveDiscountFailure = createAction(GIVE_DISCOUNT_FAILURE);

export const removeDiscountRequest = createAction(REMOVE_DISCOUNT_REQUEST);
export const removeDiscountSuccess = createAction(REMOVE_DISCOUNT_SUCCESS);
export const removeDiscountFailure = createAction(REMOVE_DISCOUNT_FAILURE);

export const retryPayoutRequest = createAction(RETRY_PAYOUT_REQUEST);
export const retryPayoutSuccess = createAction(RETRY_PAYOUT_SUCCESS);
export const retryPayoutFailure = createAction(RETRY_PAYOUT_FAILURE);

export const unconvertedBookingRequest = createAction(UNCONVERTED_BOOKING_REQUEST);
export const unconvertedBookingSuccess = createAction(UNCONVERTED_BOOKING_SUCCESS);
export const unconvertedBookingFailure = createAction(UNCONVERTED_BOOKING_FAILURE);

export const bookableRatesRequest = createAction(BOOKABLE_RATES_REQUEST);

export const bookableRatesSuccess = createAction(BOOKABLE_RATES_SUCCESS);

export const bookableRatesFailure = createAction(BOOKABLE_RATES_FAILURE);
