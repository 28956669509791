import cogoToast from 'cogo-toast';
import jwtDecode from 'jwt-decode';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { trackBookingConfirmed, trackBookingEvent } from '../../Application/Booking/Analytics';
import i18n from '../../i18n';
import { trackException } from '../../lib/analytics';
import { REFUND_TYPE_FULL, REFUND_TYPE_PARTIAL } from '../../lib/refundType';
import BookingApi from '../../Pawshake/Booking/BookingApi';
import { conversationMessagesRequest } from '../Conversation/actions';
import { jwtTokenSelect } from '../User/Authentication/selectors';
import {
    acceptBookingFailure,
    acceptBookingSuccess,
    alterBookingFailure,
    alterBookingSuccess,
    bookableRatesFailure,
    bookableRatesSuccess,
    bookingDetailsSummaryFailure,
    bookingDetailsSummarySuccess,
    cancelBookingFailure,
    cancelBookingSuccess,
    declineBookingFailure,
    declineBookingSuccess,
    giveDiscountFailure,
    giveDiscountSuccess,
    preferToNotReviewBookingFailure,
    preferToNotReviewBookingSuccess,
    removeDiscountFailure,
    removeDiscountSuccess,
    requestBookingFailure,
    requestBookingSuccess,
    retryPayoutFailure,
    retryPayoutSuccess,
    reviewBookingFailure,
    reviewBookingSuccess,
    unconvertedBookingFailure,
    unconvertedBookingSuccess,
} from './actions';
import {
    ACCEPT_BOOKING_REQUEST,
    ACCEPT_BOOKING_SUCCESS,
    ALTER_BOOKING_REQUEST,
    ALTER_BOOKING_SUCCESS,
    BOOKABLE_RATES_REQUEST,
    BOOKING_DETAILS_SUMMARY_REQUEST,
    CANCEL_BOOKING_REQUEST,
    CANCEL_BOOKING_SUCCESS,
    DECLINE_BOOKING_REQUEST,
    DECLINE_BOOKING_SUCCESS,
    GIVE_DISCOUNT_REQUEST,
    GIVE_DISCOUNT_SUCCESS,
    PREFER_NOT_TO_REVIEW_BOOKING_REQUEST,
    REMOVE_DISCOUNT_REQUEST,
    REMOVE_DISCOUNT_SUCCESS,
    REQUEST_BOOKING_REQUEST,
    RETRY_PAYOUT_REQUEST,
    RETRY_PAYOUT_SUCCESS,
    REVIEW_BOOKING_REQUEST,
    REVIEW_BOOKING_SUCCESS,
    UNCONVERTED_BOOKING_REQUEST,
} from './constants';

const showProcessingToast = (text) => {
    const { hide } = cogoToast.loading(text, {
        position: 'top-right',
        hideAfter: 0,
    });

    return hide;
};

const showSuccessToast = (text) => {
    cogoToast.success(text, { position: 'top-right' });
};

const showFailedToast = (text) => {
    cogoToast.error(text, { position: 'top-right' });
};

function* unconvertedBookingRequestFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(BookingApi.getUnconvertedBooking, jwtToken, payload);

    if (response.status === 200) {
        yield put(
            unconvertedBookingSuccess({
                hasOne: true,
                data: response.data,
            })
        );
    } else if (response.status === 204) {
        yield put(
            unconvertedBookingSuccess({
                hasOne: false,
            })
        );
    } else {
        unconvertedBookingFailure(response.status);
    }
}

function* unconvertedBookingRequestFlowWatcher() {
    yield takeLatest(UNCONVERTED_BOOKING_REQUEST, unconvertedBookingRequestFlow);
}

function* bookableRatesRequestFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(BookingApi.getBookableRates, jwtToken, payload);

    if (response.status === 200) {
        yield put(bookableRatesSuccess(response.data));
    } else {
        bookableRatesFailure(response.status);
    }
}

function* bookableRatesRequestFlowWatcher() {
    yield takeLatest(BOOKABLE_RATES_REQUEST, bookableRatesRequestFlow);
}

function* bookingDetailsSummaryRequestFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(BookingApi.getBookingDetailsSummary, jwtToken, payload);
    if (response.status === 200) {
        yield put(bookingDetailsSummarySuccess(response.data));
    } else {
        yield put(bookingDetailsSummaryFailure(response.status));
    }
}

function* bookingDetailsSummaryRequestFlowWatcher() {
    yield takeLatest(
        [
            BOOKING_DETAILS_SUMMARY_REQUEST,
            ACCEPT_BOOKING_SUCCESS,
            DECLINE_BOOKING_SUCCESS,
            ALTER_BOOKING_SUCCESS,
            GIVE_DISCOUNT_SUCCESS,
            REMOVE_DISCOUNT_SUCCESS,
            CANCEL_BOOKING_SUCCESS,
            REVIEW_BOOKING_SUCCESS,
            RETRY_PAYOUT_SUCCESS,
        ],
        bookingDetailsSummaryRequestFlow
    );
}

function* cancelBookingFlow({ payload }) {
    trackBookingEvent('Cancel booking', 'Request attempting');
    const hide = showProcessingToast(i18n.i18n.t('booking:booking.bookingCanBeCancelledByPetOwner.attempting'));

    const jwtToken = yield select(jwtTokenSelect);

    let response = null;

    if (payload.refundType === REFUND_TYPE_FULL) {
        response = yield call(
            BookingApi.cancelAndRefundBookingByPetOwner,
            jwtToken,
            payload.bookingId,
            payload.reason,
            payload.clarificationForSitter,
            payload.otherReason
        );
    }

    if (payload.refundType === REFUND_TYPE_PARTIAL) {
        response = yield call(
            BookingApi.cancelAndPartialRefundBookingByPetOwner,
            jwtToken,
            payload.bookingId,
            payload.reason,
            payload.clarificationForSitter,
            payload.otherReason
        );
    }

    hide();

    if (response === null) {
        return;
    }

    if (response.status === 204) {
        trackBookingEvent('Cancel booking', 'Request successful');
        showSuccessToast(i18n.i18n.t('booking:booking.bookingCanBeCancelledByPetOwner.successful'));
        yield put(cancelBookingSuccess(payload.bookingId));
    } else {
        trackBookingEvent('Cancel booking', 'Request failed');
        showFailedToast(i18n.i18n.t('booking:booking.bookingCanBeCancelledByPetOwner.failed'));
        yield put(cancelBookingFailure());
    }
}

function* cancelBookingFlowWatcher() {
    yield takeLatest(CANCEL_BOOKING_REQUEST, cancelBookingFlow);
}

function* requestBookingFlow({ payload }) {
    trackBookingEvent('Request booking', 'Request attempting');

    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(
        BookingApi.requestBooking,
        jwtToken,
        payload.sitterId,
        payload.message,
        payload.bookableService,
        payload.petIds,
        payload.dates,
        payload.suburb,
        payload.vasIds
    );

    if (response.status === 200) {
        trackBookingEvent('Request booking', 'Request successful');
        yield put(requestBookingSuccess(response.data));
    } else {
        trackBookingEvent('Request booking', 'Request failed');
        trackException(response.data);
        yield put(requestBookingFailure(response.data));
    }
}

function* requestBookingFlowWatcher() {
    yield takeLatest(REQUEST_BOOKING_REQUEST, requestBookingFlow);
}

function* acceptBookingFlow({ payload }) {
    trackBookingEvent('Accept booking', 'Request attempt');
    const hide = showProcessingToast(i18n.i18n.t('booking:booking.acceptOrDecline.acceptBooking.attempting'));

    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(BookingApi.acceptBooking, jwtToken, payload.bookingId);
    hide();

    if (response.status === 204) {
        trackBookingEvent('Accept booking', 'Request successful');
        const decodedToken = jwtDecode(jwtToken);
        const email = decodedToken.email;
        trackBookingConfirmed(payload.analyticsProperties, email);
        yield put(acceptBookingSuccess(payload.bookingId));
        showSuccessToast(i18n.i18n.t('booking:booking.acceptOrDecline.acceptBooking.successful'));
    } else {
        trackBookingEvent('Accept booking', 'Request failed');
        yield put(acceptBookingFailure());
        if (response.data) {
            showFailedToast(response.data);
        } else {
            showFailedToast(i18n.i18n.t('booking:booking.acceptOrDecline.acceptBooking.failed'));
        }
    }
}

function* acceptBookingFlowWatcher() {
    yield takeLatest(ACCEPT_BOOKING_REQUEST, acceptBookingFlow);
}

function* declineBookingFlow({ payload }) {
    trackBookingEvent('Decline booking', 'Request attempting');
    const hide = showProcessingToast(i18n.i18n.t('booking:booking.acceptOrDecline.declineBooking.attempting'));

    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(BookingApi.declineBooking, jwtToken, payload);

    hide();

    if (response.status === 204) {
        trackBookingEvent('Decline booking', 'Request success');
        yield put(declineBookingSuccess(payload));
        showSuccessToast(i18n.i18n.t('booking:booking.acceptOrDecline.declineBooking.successful'));
    } else {
        trackBookingEvent('Decline booking', 'Request failed');
        yield put(declineBookingFailure());
        showFailedToast(i18n.i18n.t('booking:booking.acceptOrDecline.declineBooking.failed'));
    }
}

function* declineBookingFlowWatcher() {
    yield takeLatest(DECLINE_BOOKING_REQUEST, declineBookingFlow);
}

function* retryPayoutFlow({ payload }) {
    trackBookingEvent('Retry payout', 'Request attempting');

    const hide = showProcessingToast(i18n.i18n.t('booking:booking.retryPayout.attempting'));

    const jwtToken = yield select(jwtTokenSelect);

    const response = yield call(BookingApi.retryPayout, jwtToken, payload);

    hide();

    if (response.status === 204) {
        trackBookingEvent('Retry payout', 'Request success');
        yield put(retryPayoutSuccess(payload));
        showSuccessToast(i18n.i18n.t('booking:booking.retryPayout.successful'));
    } else {
        trackBookingEvent('Retry payout', 'Request failed');
        yield put(retryPayoutFailure());
        showFailedToast(i18n.i18n.t('booking:booking.retryPayout.failed'));
    }
}

function* retryPayoutFlowWatcher() {
    yield takeLatest(RETRY_PAYOUT_REQUEST, retryPayoutFlow);
}

function* reviewBookingFlow({ payload }) {
    trackBookingEvent('Review booking', 'Request attempt');
    const hide = showProcessingToast(i18n.i18n.t('booking:booking.bookingCanBeReviewedByPetOwner.attempting'));

    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(
        BookingApi.reviewBooking,
        jwtToken,
        payload.bookingId,
        payload.comment,
        payload.valueStarRating,
        payload.profileAccuracyStarRating,
        payload.communicationStarRating,
        payload.conscientiousStarRating,
        payload.animalTreatmentStarRating
    );

    hide();

    if (response.status === 204) {
        trackBookingEvent('Review booking', 'Request successful');
        yield put(reviewBookingSuccess(payload.bookingId));
        showSuccessToast(i18n.i18n.t('booking:booking.bookingCanBeReviewedByPetOwner.successful'));
    } else {
        trackBookingEvent('Review booking', 'Request failed');
        yield put(reviewBookingFailure());
        showFailedToast(i18n.i18n.t('booking:booking.bookingCanBeReviewedByPetOwner.failed'));
    }
}

function* reviewBookingFlowWatcher() {
    yield takeLatest(REVIEW_BOOKING_REQUEST, reviewBookingFlow);
}

function* preferNotToReviewBookingFlow({ payload }) {
    trackBookingEvent('Prefer not to review booking', 'Request attempt');
    const hide = showProcessingToast(
        i18n.i18n.t('booking:booking.reviewableBooking.preferNotToLeaveAReview.attempting')
    );

    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(BookingApi.preferNotToReviewBooking, jwtToken, payload);

    hide();

    if (response.status === 204) {
        trackBookingEvent('Prefer not to review booking', 'Request successful');
        yield put(preferToNotReviewBookingSuccess(payload));
        showSuccessToast(i18n.i18n.t('booking:booking.reviewableBooking.preferNotToLeaveAReview.successful'));
    } else {
        trackBookingEvent('Prefer not to review booking', 'Request failed');
        yield put(preferToNotReviewBookingFailure());
        showFailedToast(i18n.i18n.t('booking:booking.reviewableBooking.preferNotToLeaveAReview.failed'));
    }
}

function* preferNotToReviewBookingFlowWatcher() {
    yield takeLatest(PREFER_NOT_TO_REVIEW_BOOKING_REQUEST, preferNotToReviewBookingFlow);
}

function* alterBookingFlow({ payload }) {
    trackBookingEvent('Alter booking', 'Request attempt');
    const hide = showProcessingToast(i18n.i18n.t('booking:booking.bookingCanBeAlteredByPetOwner.attempting'));

    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(BookingApi.alterBooking, jwtToken, payload.bookingId, payload.dateCollection);

    hide();

    if (response.status === 204) {
        yield put(alterBookingSuccess(payload.bookingId));
        trackBookingEvent('Alter booking', 'Request successful');
        showSuccessToast(i18n.i18n.t('booking:booking.bookingCanBeAlteredByPetOwner.successful'));
    } else {
        yield put(alterBookingFailure());
        trackBookingEvent('Alter booking', 'Request failed');
        showFailedToast(i18n.i18n.t('booking:booking.bookingCanBeAlteredByPetOwner.failed'));
    }
}

function* alterBookingFlowWatcher() {
    yield takeLatest(ALTER_BOOKING_REQUEST, alterBookingFlow);
}

function* giveDiscountFlow({ payload }) {
    trackBookingEvent('Discount booking', 'Request attempt');
    const hide = showProcessingToast(
        i18n.i18n.t('booking:booking.bookingCanBeGivenADiscountByPetSitter.give.attempting')
    );

    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(BookingApi.giveDiscount, jwtToken, payload.bookingId, payload.discountPercentage);

    hide();

    if (response.status === 204) {
        trackBookingEvent('Discount booking', 'Request successful');
        yield put(giveDiscountSuccess(payload.bookingId));
        showSuccessToast(i18n.i18n.t('booking:booking.bookingCanBeGivenADiscountByPetSitter.give.successful'));
    } else {
        trackBookingEvent('Discount booking', 'Request failed');
        yield put(giveDiscountFailure());
        showFailedToast(i18n.i18n.t('booking:booking.bookingCanBeGivenADiscountByPetSitter.give.failed'));
    }
}

function* giveDiscountFlowWatcher() {
    yield takeLatest(GIVE_DISCOUNT_REQUEST, giveDiscountFlow);
}

function* removeDiscountFlow({ payload }) {
    trackBookingEvent('Remove discount booking', 'Request attempt');
    const hide = showProcessingToast(
        i18n.i18n.t('booking:booking.bookingCanBeGivenADiscountByPetSitter.remove.attempting')
    );

    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(BookingApi.removeDiscount, jwtToken, payload.bookingId);

    hide();

    if (response.status === 204) {
        trackBookingEvent('Remove discount booking', 'Request successful');
        yield put(removeDiscountSuccess(payload.bookingId));
        showSuccessToast(i18n.i18n.t('booking:booking.bookingCanBeGivenADiscountByPetSitter.remove.successful'));
    } else {
        trackBookingEvent('Remove discount booking', 'Request failed');
        yield put(removeDiscountFailure());
        showFailedToast(i18n.i18n.t('booking:booking.bookingCanBeGivenADiscountByPetSitter.remove.failed'));
    }
}

function* removeDiscountFlowWatcher() {
    yield takeLatest(REMOVE_DISCOUNT_REQUEST, removeDiscountFlow);
}

function* reloadConversationMessagesFlow({ payload }) {
    yield put(
        conversationMessagesRequest({
            conversationId: payload,
            pageNumber: 1,
            pageSize: 10,
        })
    );
}

function* reloadConversationMessagesFlowWatcher() {
    yield takeLatest(
        [
            ACCEPT_BOOKING_SUCCESS,
            DECLINE_BOOKING_SUCCESS,
            ALTER_BOOKING_SUCCESS,
            GIVE_DISCOUNT_SUCCESS,
            REMOVE_DISCOUNT_SUCCESS,
            CANCEL_BOOKING_SUCCESS,
            RETRY_PAYOUT_SUCCESS,
        ],
        reloadConversationMessagesFlow
    );
}

export default [
    fork(bookingDetailsSummaryRequestFlowWatcher),
    fork(acceptBookingFlowWatcher),
    fork(declineBookingFlowWatcher),
    fork(retryPayoutFlowWatcher),
    fork(cancelBookingFlowWatcher),
    fork(requestBookingFlowWatcher),
    fork(reviewBookingFlowWatcher),
    fork(preferNotToReviewBookingFlowWatcher),
    fork(alterBookingFlowWatcher),
    fork(giveDiscountFlowWatcher),
    fork(removeDiscountFlowWatcher),
    fork(reloadConversationMessagesFlowWatcher),
    fork(unconvertedBookingRequestFlowWatcher),
    fork(bookableRatesRequestFlowWatcher),
];
