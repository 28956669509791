import styled from 'styled-components';
import Breakpoints from '../../../../../styles/breakpoints';
import PsAvatar from '../avatar';

const DrawerAvatar = styled.div`
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    @media ${Breakpoints.md} {
        && {
            display: none;
        }
    }
`;

const NameWrapper = styled.div`
    color: #fff;
    font-size: 1.125rem;
    line-height: 1.5rem;
`;

const PsDrawerAvatar = ({ userImage, userName }: { userImage: string; userName: string }) => (
    <DrawerAvatar>
        <PsAvatar src={userImage} label={userName} size={'6rem'} />
        <NameWrapper>{userName}</NameWrapper>
    </DrawerAvatar>
);

export default PsDrawerAvatar;
