import { formatForApiUsage } from '../../../Core/Date';
import { BaseApi } from '../../../Core/BaseApi';

class DashboardSitterAvailabilityApi extends BaseApi {
    getOfferedBookableServices = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/calendar/offered-bookable-services');
    };

    getOneBookingAtATimeSetting = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/calendar/one-booking-at-a-time');
    };

    getInactiveModeSetting = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/calendar/inactive-mode');
    };

    getMostRecentAvailabilityUpdate = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/calendar/most-recent-availability-update');
    };

    getCalendarAvailabilitySummaryForBookableService = (jwtToken, bookableService, year, month) => {
        return this.performAuthenticatedGetRequest(
            jwtToken,
            `/dashboard/calendar/availability-summary/${bookableService}/${year}/${month}`
        );
    };

    getCalendarAvailabilitySummary = (jwtToken, year, month) => {
        return this.performAuthenticatedGetRequest(
            jwtToken,
            `/dashboard/calendar/availability-summary/${year}/${month}`
        );
    };

    getCalendarAvailabilityDateDetail = (jwtToken, date) => {
        return this.performAuthenticatedGetRequest(
            jwtToken,
            `/dashboard/calendar/availability-detail/${formatForApiUsage(date)}`
        );
    };

    getBookableServiceWeekdayAvailableSlots = (jwtToken) => {
        return this.performAuthenticatedGetRequest(
            jwtToken,
            '/dashboard/calendar/bookable-service-weekday-availability'
        );
    };

    getBookableServiceRepeatClientsOnly = (jwtToken) => {
        return this.performAuthenticatedGetRequest(
            jwtToken,
            '/dashboard/calendar/bookable-service-repeat-clients-only'
        );
    };

    confirmAvailability = (jwtToken) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Sitter.Availability.AvailabilityConfirmation.WriteModel.ConfirmAvailability',
            {}
        );
    };

    markAsAvailable = (jwtToken, days) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Sitter.Availability.QuickCalendarUpdate.WriteModel.MarkAsAvailable',
            {
                days: days.map((date) => formatForApiUsage(date)),
            }
        );
    };

    markAsUnavailable = (jwtToken, days) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Sitter.Availability.QuickCalendarUpdate.WriteModel.MarkAsUnavailable',
            {
                days: days.map((date) => formatForApiUsage(date)),
            }
        );
    };

    specifyBookableServiceWeekdayAvailableSlots = (jwtToken, bookableService, weekday, availableSlots) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Sitter.Availability.BookableServiceWeekdayAvailability.WriteModel.SpecifyBookableServiceWeekdayAvailableSlots',
            {
                bookableService: bookableService,
                weekday: weekday,
                availableSlots: availableSlots,
            }
        );
    };

    specifyDateRangeAvailableSlotsForBookableServices = (jwtToken, from, till, availableSlotsByBookableService) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Sitter.Availability.BookableServiceDateAvailability.WriteModel.SpecifyDateRangeAvailableSlotsForBookableServices.SpecifyDateRangeAvailableSlotsForBookableServices',
            {
                from: formatForApiUsage(from),
                till: formatForApiUsage(till),
                availableSlotsByBookableService,
            }
        );
    };

    unspecifyDateRangeAvailableSlotsForBookableServices = (jwtToken, from, till, bookableServices) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Sitter.Availability.BookableServiceDateAvailability.WriteModel.UnspecifyDateRangeAvailableSlotsForBookableServices.UnspecifyDateRangeAvailableSlotsForBookableServices',
            {
                from: formatForApiUsage(from),
                till: formatForApiUsage(till),
                bookableServices,
            }
        );
    };

    toggleInactiveMode = (jwtToken, enabled) => {
        return this.sendCommand(jwtToken, 'Pawshake.Sitter.Availability.InactiveMode.WriteModel.ToggleInactiveMode', {
            enabled,
        });
    };

    toggleOneBookingAtATime = (jwtToken, enabled) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Sitter.Availability.OneBookingAtATime.WriteModel.ToggleOneBookingAtATime',
            {
                enabled,
            }
        );
    };

    toggleRepeatClientsForBookableService = (jwtToken, bookableService, enabled) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Sitter.Availability.RepeatClientsOnly.WriteModel.ToggleRepeatClientsOnlyForBookableService',
            {
                bookableService,
                enabled,
            }
        );
    };
}

export default new DashboardSitterAvailabilityApi();
