import { fromJS } from 'immutable';
import { USER_PETS_SUCCESS } from './constants';

export const initialState = fromJS([]);

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case USER_PETS_SUCCESS:
            return fromJS(payload);
        default:
            return state;
    }
};
