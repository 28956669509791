import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import styled from 'styled-components';
import Breakpoints from '../../../styles/breakpoints';
import Colors from '../../../styles/colors';
import { GreenButton } from '../../../styles/common';
import { isDevelopment } from '../../../utils/isClientSide';
import PsNavBarActions from './components/actions/navbar-actions';
import PsAvatar from './components/avatar';
import PsNavBarLogo from './components/navbar/logo';
import PsNavBarMenuButton from './components/navbar/menu-button';
import PsNavBarSearchButton from './components/navbar/search-button';
import NavBarWrapper from './templates/nav-bar-wrapper';

export const NavBarHeight = '64px';

const NavBar = styled(RemoveScroll)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${NavBarHeight};
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${Colors.brand.default};
    padding: 0 1rem;
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
`;

const NavBarLogo = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const FlexRow = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
`;

const RenderPhone = styled.div`
    @media ${Breakpoints.md} {
        && {
            display: none;
        }
    }
`;

const PawshakeNavBar = ({
    application,
    isAuthenticated,
    isAuthenticatedButWithoutPawshakeAccount,
    isAuthenticatedLoading,
    isASitter,
    hasACompletedSitterApplication,
    userImage,
    userName,
    messageUnreadCounter,
    t,
    openSearch,
    logout,
    trackEvent,
}: {
    application: any;
    isAuthenticated: boolean;
    isAuthenticatedButWithoutPawshakeAccount: boolean;
    isAuthenticatedLoading: boolean;
    isASitter?: boolean;
    hasACompletedSitterApplication?: boolean;
    userImage?: string;
    userName?: string;
    messageUnreadCounter?: number;
    openSearch: () => void;
    logout: () => void;
    t: (key: string) => string;
    trackEvent: (fsCategory: any, gaCategory: any, action: any, eventLabel: any) => void;
}) => {
    const router = useRouter();
    const [mobileMenuOpen, setMobileMenu] = useState(false);
    const toggleMenu = () => {
        setMobileMenu((previousValue) => !previousValue);
    };
    const closeDrawer = () => {
        setMobileMenu(false);
    };

    useEffect(() => {
        if (!mobileMenuOpen) {
            return;
        }

        window.addEventListener('resize', closeDrawer);

        return () => {
            window.removeEventListener('resize', closeDrawer);
        };
    }, [closeDrawer]);

    return (
        <>
            <NavBar enabled={mobileMenuOpen}>
                <NavBarLogo>
                    {(router.asPath.startsWith('/conversation') === false ||
                        router.asPath.endsWith('/checkout') === false) && <PsNavBarMenuButton onClick={toggleMenu} />}
                    <PsNavBarLogo />
                </NavBarLogo>
                {isDevelopment() && (
                    <div>
                        <GreenButton
                            onClick={() => {
                                logout();
                            }}
                        >
                            Logout
                        </GreenButton>
                    </div>
                )}
                <NavBarWrapper isOpen={mobileMenuOpen} onClose={toggleMenu}>
                    <PsNavBarActions
                        t={t}
                        application={application}
                        isAuthenticated={isAuthenticated}
                        isAuthenticatedButWithoutPawshakeAccount={isAuthenticatedButWithoutPawshakeAccount}
                        isAuthenticatedLoading={isAuthenticatedLoading}
                        isASitter={isASitter}
                        hasACompletedSitterApplication={hasACompletedSitterApplication}
                        userImage={userImage}
                        userName={userName}
                        messageUnreadCounter={messageUnreadCounter}
                        logout={logout}
                        trackEvent={trackEvent}
                        openSearch={openSearch}
                        toggleMenu={closeDrawer}
                    />
                </NavBarWrapper>
                <RenderPhone>
                    <FlexRow>
                        <PsNavBarSearchButton onClick={openSearch} />
                        {userImage && userName && (
                            <div onClick={toggleMenu}>
                                <PsAvatar src={userImage} label={userName} size={'2rem'} />
                            </div>
                        )}
                    </FlexRow>
                </RenderPhone>
            </NavBar>
        </>
    );
};

export default PawshakeNavBar;
