import Immutable from 'immutable';
import { createSelector } from 'reselect';

const selectRoot = (state) => state.getIn(['domain', 'dashboard', 'sitter', 'availability'], new Immutable.Map());

export const selectAvailabilityCalendarSummary = createSelector(selectRoot, (root) =>
    root.get('availabilityCalendarSummary')
);

export const selectCalendarAvailabilityDateDetail = createSelector(selectRoot, (root) =>
    root.get('calendarAvailabilityDateDetail')
);

export const selectBookableServiceWeekdayAvailableSlots = createSelector(selectRoot, (root) =>
    root.get('bookableServiceWeekdayAvailableSlots')
);

export const selectBookableServiceRepeatClientsOnly = createSelector(selectRoot, (root) =>
    root.get('bookableServiceRepeatClientsOnly')
);

export const selectShowingMonthAndYear = createSelector(selectRoot, (root) => root.get('showingMonthAndYear'));

export const selectShowingBookableService = createSelector(selectRoot, (root) => root.get('showingBookableService'));

export const selectOfferedBookableServices = createSelector(selectRoot, (root) => root.get('offeredBookableServices'));

export const selectMostRecentAvailabilityUpdate = createSelector(selectRoot, (root) =>
    root.get('mostRecentAvailabilityUpdate')
);

export const selectInactiveModeSetting = createSelector(selectRoot, (root) => root.get('inactiveModeSetting'));

export const selectOneBookingAtATimeSetting = createSelector(selectRoot, (root) =>
    root.get('oneBookingAtATimeSetting')
);
