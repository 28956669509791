import React from 'react';
import Router from 'next/router';
import { buildUserInitiatedSearchQuery } from '../../Pawshake/Search/Utils';
import { LOCATION_BASED } from '../../Pawshake/Core/Search/SearchTypes';
import SearchPopupV3 from './SearchPopupV3';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';

const SearchPopupV3Container = ({ application, searchIsOpen, setIsOpen }) => {
    const { locale } = application;

    const onSave = ({ selectedService, placeName, location, days, pets, typeOfDateSelection }) => {
        const query = buildUserInitiatedSearchQuery(
            locale,
            typeOfDateSelection,
            null,
            pets,
            null,
            selectedService,
            days,
            placeName,
            location,
            null,
            LOCATION_BASED
        );

        Router.push({
            pathname: `/search`,
            query: query,
        });

        setIsOpen(false);
    };

    const closeSearch = () => {
        setIsOpen(false);
    };

    return <>{searchIsOpen && <SearchPopupV3 isOpen={searchIsOpen} onClose={closeSearch} onSave={onSave} />}</>;
};

export default withApplicationAwareness(SearchPopupV3Container);
