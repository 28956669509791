import React from 'react';
import first from 'lodash/first';
import last from 'lodash/last';
import DateRangeFakeInput from './DateRangeFakeInput';
import MultipleDateFakeInput from './MultipleDate/MultipleDateFakeInput';
import { CONSECUTIVE_DATE_SELECTION } from '../../utils/dates';

const FlexibleDatePickerFakeInput = ({ className, selectedDays, typeOfDateSelection, isOpen }) => {
    if (typeOfDateSelection === CONSECUTIVE_DATE_SELECTION) {
        return (
            <DateRangeFakeInput
                className={className}
                from={first(selectedDays)}
                till={last(selectedDays)}
                isOpen={isOpen}
            />
        );
    }

    return <MultipleDateFakeInput className={className} selectedDays={selectedDays} isOpen={isOpen} isSelect={true} />;
};

export default FlexibleDatePickerFakeInput;
