export const convertToSelectedDays = (from, till) => {
    let selectedDays = [];

    if (from) {
        if (till) {
            let start = new Date(from);
            while (start <= new Date(till)) {
                selectedDays.push(new Date(start));
                start.setDate(start.getDate() + 1);
            }
        } else {
            selectedDays = [new Date(from)];
        }
    }

    return selectedDays;
};
