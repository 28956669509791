import { InboxIcon } from '../../../../../styles/icons';
import PsNavBarIconLink from '../../templates/navbar-icon-link';

const PsNavBarInboxLink = ({ badge, locale }: { badge: number; locale: string }) => (
    <PsNavBarIconLink badge={badge} href={`/inbox?localeString=${locale}`} as={'/inbox'}>
        <InboxIcon />
    </PsNavBarIconLink>
);

export default PsNavBarInboxLink;
