import styled from 'styled-components';

export const NormalMenu = styled.div`
    padding: 1rem;
    max-width: 100%;
`;

export const SearchPageMenu = styled.div`
    padding: 1rem;
    width: 25rem;
`;
