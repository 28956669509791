import axios from '../Core/Api';
import { BaseApi } from '../Core/BaseApi';

class PetApi extends BaseApi {
    getPets = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, 'user/pets');
    };

    getProfile = (petId) => {
        return axios
            .get(`pet/${petId}/profile`)
            .then((response) => {
                return {
                    status: 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: error.response.status,
                };
            });
    };

    enrichPets = (jwtToken, petIds) => {
        return this.performAuthenticatedGetRequest(jwtToken, `pet/details/${petIds.join(',')}`);
    };
}

export default new PetApi();
