import FakeInput from '../Shared/FakeInput';
import Service from './Service';

const ServiceFakeInput = ({ className, service, isOpen, label, onClick }) => {
    const value = (
        <Service service={service} alignItems='center'>
            <Service.Icon size='md' />
            <Service.DropdownLabel />
        </Service>
    );

    return (
        <FakeInput
            className={className}
            value={value}
            label={label}
            isSelect={true}
            isOpen={isOpen}
            onClick={onClick}
        />
    );
};

export default ServiceFakeInput;
