/* global google */
import React, { useEffect, useState } from 'react';
import usePlacesAutocomplete, { getDetails, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import googleMapLoader from '../../utils/google_map_loader';
import SearchIcon from './SearchIcon';
import ResponsiveModal from '../../Infrastructure/Modal/ResponsiveModal';
import { GreenButton, HollowBlueButton } from '../../styles/common';
import OrDivider from '../../Infrastructure/Shared/OrDivider/OrDivider';

let cachedVal = '';
const acceptedKeys = ['ArrowUp', 'ArrowDown', 'Escape', 'Enter'];

const Suggestions = styled.ul`
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    margin-top: 1rem;
    box-shadow: 0 14px 36px 2px rgba(0, 0, 0, 0.15);
    list-style-type: none;
    padding: 0;
`;

const Suggestion = styled.li`
    border-bottom: 1px solid #cecece;
    padding: 1rem;

    &:last-child {
        border-bottom: 0;
    }

    small {
        margin-left: 0.5rem;
    }

    &[aria-selected='true'] {
        background-color: #f1faf8;
        cursor: pointer;
    }

    ${breakpoint('md')`
    &:hover {
      background-color: #f1faf8;
      cursor: pointer;
    } 
  `};
`;

const Container = styled.div`
    position: relative;
    vertical-align: top;
    font-weight: 200;
    background-color: #ffffff;
    margin-bottom: 1rem;
`;

const Input = styled.input`
    height: 52px;
    width: 100%;
    padding-left: 3rem;
    border: 1px solid #cecece;
    border-radius: 4px;
`;

const StyledOrDivider = styled(OrDivider)`
    margin-top: 1rem;
`;

const AddressFinder = ({
    className,
    callback,
    country,
    placeholder,
    type,
    defaultValue = '',
    returnLatLng = true,
    requiresStreetNumber = false,
    streetNumberIsMissingTitle = '',
    streetNumberIsMissingComplete = '',
    streetNumberIsMissingNone = '',
}) => {
    const [currIndex, setCurrIndex] = useState(null);
    const [requestNewSessionToken, setRequestNewSessionToken] = useState(true);
    const [sessionToken, setSessionToken] = useState();
    const [placeId, setPlaceId] = useState();
    const [showStreetNumberIsMissing, setShowStreetNumberIsMissing] = useState(false);
    const getSessionToken = () => sessionToken;

    let types;
    if (typeof type === 'undefined') {
        types = ['geocode'];
    } else {
        types = [type];
    }

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            sessionToken: getSessionToken(),
            componentRestrictions: {
                country: country.toLocaleLowerCase(),
            },
            types: types,
        },
        callbackName: 'initAddressFinder',
        debounce: 300,
        defaultValue: defaultValue ?? '',
    });

    useEffect(() => {
        googleMapLoader();
    }, []);

    useEffect(() => {
        if (requestNewSessionToken && ready) {
            const autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
            setSessionToken(autocompleteSessionToken);
            setRequestNewSessionToken(false);
        }
    }, [requestNewSessionToken, ready]);

    const hasSuggestions = status === 'OK';

    const dismissSuggestions = () => {
        setCurrIndex(null);
        clearSuggestions();
    };

    const streetNumberIsMissing = (place_id) => {
        return getDetails({
            placeId: place_id,
            fields: ['address_components'],
            sessionToken: getSessionToken(),
        }).then((details) => {
            let result = false;
            details.address_components.forEach((component) => {
                if (component.types.includes('street_number')) {
                    result = true;
                }
            });
            return result;
        });
    };

    const getLatLngOfSuggestion = (place_id, description) => {
        getDetails({
            // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
            placeId: place_id,
            // Specify the return data that you want (optional)
            fields: ['geometry'],
            sessionToken: getSessionToken(),
        })
            .then((details) => {
                setRequestNewSessionToken(true);
                return getLatLng(details);
            })
            .then((latLng) => callback(latLng, description))
            .catch((error) => {
                console.log('Error: ', error);
            });
    };

    const ref = useOnclickOutside(dismissSuggestions);

    const handleInput = (e) => {
        setValue(e.target.value);
        cachedVal = e.target.value;
    };

    const handleSelection = (placeId, description) => {
        setPlaceId(placeId);
        if (returnLatLng) {
            getLatLngOfSuggestion(placeId, description);
        } else {
            if (requiresStreetNumber) {
                streetNumberIsMissing(placeId).then((result) => {
                    if (result) {
                        callback(placeId, sessionToken.Cm);
                    } else {
                        setShowStreetNumberIsMissing(true);
                    }
                });
            } else {
                callback(placeId, sessionToken.Cm);
            }
        }
    };

    const handleSelect = ({ place_id, description }) => {
        setValue(description, false);
        dismissSuggestions();
        handleSelection(place_id, description);
    };

    const handleEnter = (idx) => {
        setCurrIndex(idx);
    };

    const handleLeave = () => {
        setCurrIndex(null);
    };

    const handleKeyDown = (e) => {
        if (!hasSuggestions || !acceptedKeys.includes(e.key)) return;

        if (e.key === 'Enter') {
            dismissSuggestions();
            if (currIndex === null) {
                setCurrIndex(0);
                setValue(data[0] ? data[0].description : cachedVal, false);
            }
            handleSelection(data[currIndex ?? 0].place_id, data[currIndex ?? 0].description);
            return;
        }

        if (e.key === 'Escape') {
            dismissSuggestions();
            return;
        }

        let nextIndex;

        if (e.key === 'ArrowUp') {
            e.preventDefault();
            nextIndex = currIndex ?? data.length;
            nextIndex = nextIndex > 0 ? nextIndex - 1 : null;
        } else {
            nextIndex = currIndex ?? -1;
            nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
        }

        setCurrIndex(nextIndex);
        // @ts-expect-error
        setValue(data[nextIndex] ? data[nextIndex].description : cachedVal, false);
    };

    const renderSuggestions = () => {
        const suggestions = data.map((suggestion, idx) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            const onClick = () => handleSelect(suggestion);
            const onMouseEnter = () => handleEnter(idx);

            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <Suggestion
                    key={place_id}
                    id={`ex-list-item-${idx}`}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    role='option'
                    aria-selected={idx === currIndex}
                >
                    <strong>{main_text}</strong>
                    <small>{secondary_text}</small>
                </Suggestion>
            );
        });

        return <>{suggestions}</>;
    };

    return (
        <>
            <Container
                className={className}
                ref={ref}
                // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                role='combobox'
                aria-owns='ex-list-box'
                aria-haspopup='listbox'
                aria-expanded={hasSuggestions}
            >
                <SearchIcon />
                <Input
                    value={value}
                    onChange={handleInput}
                    onKeyDown={handleKeyDown}
                    disabled={!ready}
                    placeholder={placeholder}
                    type='text'
                    aria-autocomplete='list'
                    aria-controls='ex-list-box'
                    aria-activedescendant={currIndex !== null ? `ex-list-item-${currIndex}` : undefined}
                />
                {hasSuggestions && (
                    <Suggestions id='ex-list-box' onMouseLeave={handleLeave} role='listbox'>
                        {renderSuggestions()}
                    </Suggestions>
                )}
            </Container>
            <ResponsiveModal
                isOpen={showStreetNumberIsMissing}
                setIsOpen={setShowStreetNumberIsMissing}
                title={streetNumberIsMissingTitle}
            >
                <GreenButton onClick={() => setShowStreetNumberIsMissing(false)}>
                    {streetNumberIsMissingComplete}
                </GreenButton>
                <StyledOrDivider />
                <HollowBlueButton
                    onClick={() => {
                        callback(placeId);
                        setShowStreetNumberIsMissing(false);
                    }}
                >
                    {streetNumberIsMissingNone}
                </HollowBlueButton>
            </ResponsiveModal>
        </>
    );
};

export default AddressFinder;
