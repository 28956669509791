export const NO_FILTER = 'noFilter';
export const REQUESTED = 'requested';
export const AWAITING_CONFIRMATION = 'awaitingConfirmation';
export const DECLINED = 'declined';
export const CONFIRMED = 'confirmed';
export const IN_PROGRESS = 'inProgress';
export const ENDED = 'ended';
export const PAYOUT_IN_TRANSIT = 'payoutInTransit';
export const PAYOUT_FAILED = 'payoutFailed';
export const PAID_TO_SITTER = 'paidToSitter';
export const CANCELLED = 'cancelled';

export const getList = () => {
    return [
        NO_FILTER,
        REQUESTED,
        AWAITING_CONFIRMATION,
        CONFIRMED,
        IN_PROGRESS,
        ENDED,
        PAYOUT_IN_TRANSIT,
        PAID_TO_SITTER,
        PAYOUT_FAILED,
        CANCELLED,
        DECLINED,
    ];
};
