import styled from 'styled-components';

const PsDivider = styled.hr`
    opacity: 1;
    border-width: 0px 0px 1px;
    border-image: initial;
    border-style: solid;
    width: 100%;
    border-color: white;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    &.divider--gray {
        border-color: #cecece;
    }
`;

export default PsDivider;
