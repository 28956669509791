import styled from 'styled-components';
import Breakpoints from '../../../../../styles/breakpoints';
import { MenuIcon } from '../../../../../styles/icons';
import PsNavBarIconButton from '../../templates/navbar-icon-button';

const MenuButton = styled(PsNavBarIconButton)`
    && {
        padding: 0;
        width: 2.5rem;
        justify-content: center;
        margin-left: -0.5rem;

        @media ${Breakpoints.md} {
            && {
                display: none;
            }
        }
    }
`;

const PsNavBarMenuButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <MenuButton onClick={onClick}>
            <MenuIcon />
        </MenuButton>
    );
};

export default PsNavBarMenuButton;
