import { createAction } from 'redux-actions';
import {
    PET_DETAIL_REQUEST,
    PET_DETAIL_SUCCESS,
    PET_DETAIL_FAILURE,
    ENRICH_PETS_WITH_DETAILS_REQUEST,
    ENRICH_PETS_WITH_DETAILS_SUCCESS,
    ENRICH_PETS_WITH_DETAILS_FAILURE,
    ENRICH_PETS_WITH_DETAILS_CLEANUP,
} from './constants';

export const petDetailRequest = createAction(PET_DETAIL_REQUEST);
export const petDetailSuccess = createAction(PET_DETAIL_SUCCESS);
export const petDetailFailure = createAction(PET_DETAIL_FAILURE);

export const enrichPetsWithDetailsRequest = createAction(ENRICH_PETS_WITH_DETAILS_REQUEST);
export const enrichPetsWithDetailsSuccess = createAction(ENRICH_PETS_WITH_DETAILS_SUCCESS);
export const enrichPetsWithDetailsFailure = createAction(ENRICH_PETS_WITH_DETAILS_FAILURE);

export const enrichPetsWithDetailsCleanup = createAction(ENRICH_PETS_WITH_DETAILS_CLEANUP);
