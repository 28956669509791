import React from 'react';
import styled from 'styled-components';
import FakeInput from '../Shared/FakeInput';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import { withTranslation } from '../../i18n';

const Dates = styled(FakeInput)`
    min-width: 12rem;
    background: #ffffff url('https://static1.pawshakecdn.com/icons/icon-date-separator.svg') no-repeat center;
`;

const From = styled.div`
    float: left;
`;
const Till = styled.div`
    float: right;
`;

const DateRangeFakeInput = ({ t, className, from, till, onClick, isOpen, application }) => {
    const { locale } = application;
    const localeToString = locale.toString();
    const value = (
        <>
            <From>{from ? from.toLocaleDateString(localeToString) : t('datePicker.dateRange.placeholder.from')}</From>
            <Till>{till ? till.toLocaleDateString(localeToString) : t('datePicker.dateRange.placeholder.till')}</Till>
        </>
    );

    return <Dates className={className} value={value} onClick={onClick} isOpen={isOpen} />;
};

export default withApplicationAwareness(withTranslation('common')(DateRangeFakeInput));
