import { HTMLProps } from 'react';
import styled from 'styled-components';
import ActionItemStyle from '../constants/action-item-style';

const NavBarButton = styled.button`
    ${ActionItemStyle}
    border: none;
`;

export type PsNavBarButtonProps = {
    isMain?: boolean;
    onClick?: () => void;
} & HTMLProps<HTMLButtonElement>;

const PsNavBarButton = (props: PsNavBarButtonProps) => {
    const { isMain, onClick, style } = props;
    return (
        <NavBarButton className={props.className ?? '' + (isMain ? ' main' : '')} onClick={onClick} style={style}>
            {props.children}
        </NavBarButton>
    );
};

export default PsNavBarButton;
