import Link from 'next/link';
import { SitterApplicationIcon } from '../../../../../styles/icons';
import PsNavBarLink from '../../templates/navbar-link';

const PsNavBarBecomeSitterLink = ({ t, closeDrawer }: { t: (_key: string) => string; closeDrawer: () => void }) => {
    return (
        <Link href={'/become-a-sitter'} passHref>
            <PsNavBarLink isMain={true} onClick={closeDrawer}>
                <div className='icon_wrapper'>
                    <SitterApplicationIcon />
                </div>
                {t('user.menu.becomeASitter')}
            </PsNavBarLink>
        </Link>
    );
};

export default PsNavBarBecomeSitterLink;
