import { fromJS } from 'immutable';
import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';
import {
    USER_JWT_TOKEN_SUCCESS,
    USER_JWT_TOKEN_LOADING,
    ENRICH_USERS_WITH_DETAILS_SUCCESS,
    USER_LOGOUT_SUCCESS,
    ENRICH_USERS_WITH_DETAILS_CLEANUP,
    FIREBASE_AUTHENTICATED,
} from './constants';

export const initialState = fromJS({
    enriched: {
        succeeded: false,
        failed: false,
        data: {},
    },
    token: undefined,
    jwtToken: undefined,
    jwtTokenDecoded: {},
    jwtTokenLoading: true,
    isFirebaseAuthenticated: false,
});

export default (state = initialState, { payload, type }) => {
    if (type === USER_JWT_TOKEN_SUCCESS) {
        const decoded = jwtDecode(payload);
        Sentry.configureScope(function (scope) {
            scope.setUser({
                id: decoded.sub,
                username: decoded.fna,
            });
        });
    }

    switch (type) {
        case ENRICH_USERS_WITH_DETAILS_SUCCESS:
            return state.setIn(['enriched', 'succeeded'], true).setIn(['enriched', 'data'], fromJS(payload));
        case ENRICH_USERS_WITH_DETAILS_CLEANUP:
            return state
                .setIn(['enriched', 'data'], fromJS({}))
                .setIn(['enriched', 'succeeded'], false)
                .setIn(['enriched', 'failed'], false);
        case USER_JWT_TOKEN_SUCCESS:
            return state.set('jwtToken', payload).set('jwtTokenDecoded', fromJS(jwtDecode(payload)));
        case USER_LOGOUT_SUCCESS:
            return initialState;
        case USER_JWT_TOKEN_LOADING:
            return state.set('jwtTokenLoading', payload);
        case FIREBASE_AUTHENTICATED:
            return state.set('isFirebaseAuthenticated', payload);
        default:
            return state;
    }
};
