import FullPageLoader from '../components/Auth/FullPageLoader';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { AuthAction, withAuthUser } from 'next-firebase-auth';

export default function withFirebaseAuthenticatedUserPage(WrappedComponent) {
    const WithFirebaseAuthenticatedUserPageComponent = withAuthUser({
        whenAuthed: AuthAction.RENDER,
        whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
        whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
        LoaderComponent: FullPageLoader,
    })(WrappedComponent);
    hoistNonReactStatics(WithFirebaseAuthenticatedUserPageComponent, WrappedComponent);
    return WithFirebaseAuthenticatedUserPageComponent;
}
