import Dropdown from '../Shared/Dropdown';
import FlexibleDatePickerFakeInput from './FlexibleDatePickerFakeInput';
import FlexibleDatesPicker from './FlexibleDatesPicker';
import { NormalMenu, SearchPageMenu } from './Styles/Dropdown';

class FlexibleDatesPickerDropdown extends Dropdown {
    state = {
        isOpen: false,
    };

    handleClear() {
        super.handleClear(false);
        const { onClear } = this.props;
        onClear();
    }

    renderInput() {
        const { selectedDays, typeOfDateSelection } = this.props;
        const { isOpen } = this.state;

        return (
            <FlexibleDatePickerFakeInput
                isOpen={isOpen}
                selectedDays={selectedDays}
                typeOfDateSelection={typeOfDateSelection}
            />
        );
    }

    renderMenu() {
        const {
            customWidth,
            numberOfMonths,
            unavailableDates,
            selectedDays,
            setSelectedDays,
            dateRange,
            setDateRange,
            typeOfDateSelection,
            setTypeOfDateSelection,
        } = this.props;

        const MenuElement = customWidth ? SearchPageMenu : NormalMenu;

        return (
            <MenuElement>
                <FlexibleDatesPicker
                    typeOfDateSelection={typeOfDateSelection}
                    setTypeOfDateSelection={setTypeOfDateSelection}
                    initialFirstDay={dateRange.firstDay}
                    initialLastDay={dateRange.lastDay}
                    setDateRange={setDateRange}
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                    numberOfMonths={numberOfMonths}
                    unavailableDates={unavailableDates}
                />
            </MenuElement>
        );
    }
}

export default FlexibleDatesPickerDropdown;
