import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import {
    DASHBOARD_CONVERSATION_PENALTY_SUMMARY_REQUEST,
    DASHBOARD_FAVORITE_SITTERS_REQUEST,
    DASHBOARD_NOTIFICATIONS_REQUEST,
    DASHBOARD_RECENT_SITTERS_REQUEST,
    DASHBOARD_REVIEWABLE_BOOKINGS_REQUEST,
    DASHBOARD_SITTER_STATISTICS_REQUEST,
    DOWNLOAD_SITTER_MARKET_MATERIALS_REQUEST,
    DOWNLOAD_SITTER_TAX_REPORT_REQUEST,
} from './constants';
import {
    dashboardActionCreated,
    dashboardConversationPenaltySummaryFailure,
    dashboardConversationPenaltySummarySuccess,
    dashboardFavoriteSittersFailure,
    dashboardFavoriteSittersSuccess,
    dashboardNotificationsFailure,
    dashboardNotificationsSuccess,
    dashboardRecentSittersFailure,
    dashboardRecentSittersSuccess,
    dashboardReviewableBookingsFailure,
    dashboardReviewableBookingsSuccess,
    dashboardSitterStatisticsFailure,
    dashboardSitterStatisticsSuccess,
    dashboardSitterStatusFailure,
    dashboardSitterStatusSuccess,
    downloadSitterMarketMaterialsFailure,
    downloadSitterMarketMaterialsSucceeded,
    downloadSitterTaxReportFailure,
    downloadSitterTaxReportSucceeded,
} from './actions';
import {
    jwtTokenSelect,
    selectJwtTokenAvatar,
    selectJwtTokenBelongsToASitter,
    selectJwtTokenUserId,
} from '../User/Authentication/selectors';
import DashboardApi from '../../Pawshake/Dashboard/DashboardApi';
import NotificationApi from '../../Pawshake/User/NotificationApi';
import { CALENDAR_UPDATE_REQUIRED_THRESHOLD_DAYS } from '../../Pawshake/Dashboard/CalendarUpdateIsRequired';
import {
    DASHBOARD_ACTION_CALENDAR,
    DASHBOARD_ACTION_MISSING_PROFILE_IMAGE,
} from '../../Pawshake/Dashboard/DashboardActions';
import { USER_JWT_TOKEN_SUCCESS } from '../User/Authentication/constants';
import SitterMarketingMaterialsApi from '../../Pawshake/Sitter/Marketing/SitterMarketingMaterialsApi';
import SitterTaxReportApi from '../../Pawshake/Sitter/TaxReport/SitterTaxReportApi';
import { BUSINESS_CARD, FLYER } from '../../Pawshake/Sitter/Marketing/Types';
import { trackDashboardSitterAction } from '../../Application/Dashboard/Analytics';
import { MOST_RECENT_AVAILABILITY_UPDATE_SUCCESS } from './Sitter/Availability/constants';
import { PREFER_NOT_TO_REVIEW_BOOKING_SUCCESS, REVIEW_BOOKING_SUCCESS } from '../Booking/constants';

function* dashboardConversationPenaltySummaryFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardApi.getDashboardConversationPenaltySummary, jwtToken);

    if (response.status === 200) {
        yield put(dashboardConversationPenaltySummarySuccess(response.data));
    } else {
        yield put(dashboardConversationPenaltySummaryFailure());
    }
}

function* dashboardConversationPenaltySummaryFlowWatcher() {
    yield takeLatest(DASHBOARD_CONVERSATION_PENALTY_SUMMARY_REQUEST, dashboardConversationPenaltySummaryFlow);
}

function* sitterDashboardStatisticsFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardApi.getDashboardSitterStatistics, jwtToken);
    if (response.status === 200) {
        yield put(dashboardSitterStatisticsSuccess(response.data));
    } else {
        yield put(dashboardSitterStatisticsFailure());
    }
}

function* sitterDashboardStatisticsFlowWatcher() {
    yield takeLatest(DASHBOARD_SITTER_STATISTICS_REQUEST, sitterDashboardStatisticsFlow);
}

function* sitterDashboardSitterStatusFlow() {
    try {
        const isSitter = yield select(selectJwtTokenBelongsToASitter);
        if (isSitter) {
            const jwtToken = yield select(jwtTokenSelect);
            const response = yield call(DashboardApi.getSitterDashboardSitterStatus, jwtToken);
            if (response.status === 200) {
                yield put(dashboardSitterStatusSuccess(response.data));
            } else {
                yield put(dashboardSitterStatusFailure());
            }
        }
    } catch (e) {
        dashboardSitterStatusFailure(e.message);
    }
}

function* sitterDashboardSitterStatusFlowWatcher() {
    yield takeLatest(USER_JWT_TOKEN_SUCCESS, sitterDashboardSitterStatusFlow);
}

function* dashboardNotificationsFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(NotificationApi.getDashboardNotifications, jwtToken);

    if (response.status === 200) {
        yield put(dashboardNotificationsSuccess(response.data));
    } else {
        yield put(dashboardNotificationsFailure());
    }
}

function* dashboardNotificationsFlowWatcher() {
    yield takeLatest(DASHBOARD_NOTIFICATIONS_REQUEST, dashboardNotificationsFlow);
}

function* dashboardRecentSittersFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardApi.getDashboardRecentSitters, jwtToken);

    if (response.status === 200) {
        yield put(dashboardRecentSittersSuccess(response.data));
    } else {
        yield put(dashboardRecentSittersFailure());
    }
}

function* dashboardRecentSittersFlowWatcher() {
    yield takeLatest(DASHBOARD_RECENT_SITTERS_REQUEST, dashboardRecentSittersFlow);
}

function* dashboardFavoriteSittersFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardApi.getDashboardFavoriteSitters, jwtToken);
    if (response.status === 200) {
        yield put(dashboardFavoriteSittersSuccess(response.data));
    } else {
        yield put(dashboardFavoriteSittersFailure());
    }
}

function* dashboardFavoriteSittersFlowWatcher() {
    yield takeLatest(DASHBOARD_FAVORITE_SITTERS_REQUEST, dashboardFavoriteSittersFlow);
}

function* dashboardReviewableBookingsFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(DashboardApi.getReviewableBookings, jwtToken);

    if (response.status === 200) {
        yield put(dashboardReviewableBookingsSuccess(response.data));
    } else {
        yield put(dashboardReviewableBookingsFailure());
    }
}

function* dashboardReviewableBookingsFlowWatcher() {
    yield takeLatest(
        [DASHBOARD_REVIEWABLE_BOOKINGS_REQUEST, REVIEW_BOOKING_SUCCESS, PREFER_NOT_TO_REVIEW_BOOKING_SUCCESS],
        dashboardReviewableBookingsFlow
    );
}

function* dashboardCalendarActionFlow({ payload }) {
    const dayDifference = differenceInDays(new Date(), parseISO(payload));
    if (dayDifference >= CALENDAR_UPDATE_REQUIRED_THRESHOLD_DAYS) {
        yield put(
            dashboardActionCreated({
                identifier: DASHBOARD_ACTION_CALENDAR,
                value: dayDifference,
            })
        );
    }
}

function* dashboardCalendarActionFlowWatcher() {
    yield takeLatest(MOST_RECENT_AVAILABILITY_UPDATE_SUCCESS, dashboardCalendarActionFlow);
}

function* dashboardProfileImageMissingActionFlow() {
    const avatar = yield select(selectJwtTokenAvatar);
    if (avatar === 'https://static1.pawshakecdn.com/icons/icon-placeholder-user.png') {
        const userId = yield select(selectJwtTokenUserId);
        yield put(
            dashboardActionCreated({
                identifier: DASHBOARD_ACTION_MISSING_PROFILE_IMAGE,
                value: userId,
            })
        );
    }
}

function* dashboardProfileImageMissingActionFlowWatcher() {
    yield takeLatest(USER_JWT_TOKEN_SUCCESS, dashboardProfileImageMissingActionFlow);
}

function* dashboardSitterMarketMaterialsDownloadFlyerFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const { type, locale } = payload;

    let response;

    if (type === FLYER) {
        trackDashboardSitterAction('Marketing Materials - Flyer - Clicked');
        response = yield call(SitterMarketingMaterialsApi.getFlyer, jwtToken, locale.toString());
    }

    if (type === BUSINESS_CARD) {
        trackDashboardSitterAction('Marketing Materials - Business card - Clicked');
        response = yield call(SitterMarketingMaterialsApi.getBusinessCard, jwtToken, locale.toString());
    }

    if (response.status === 200) {
        yield put(downloadSitterMarketMaterialsSucceeded(type));
    } else {
        yield put(downloadSitterMarketMaterialsFailure(type));
    }
}

function* dashboardSitterMarketMaterialsDownloadFlyerWatcher() {
    yield takeLatest(DOWNLOAD_SITTER_MARKET_MATERIALS_REQUEST, dashboardSitterMarketMaterialsDownloadFlyerFlow);
}

function* dashboardSitterTaxReportDownloadFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    trackDashboardSitterAction('Tax report - Clicked');
    const { startDate, endDate } = payload;

    const response = yield call(SitterTaxReportApi.getTaxReport, jwtToken, startDate, endDate);

    if (response.status === 200) {
        yield put(downloadSitterTaxReportSucceeded());
    } else {
        yield put(downloadSitterTaxReportFailure());
    }
}

function* dashboardSitterTaxReportDownloadFlowWatcher() {
    yield takeLatest(DOWNLOAD_SITTER_TAX_REPORT_REQUEST, dashboardSitterTaxReportDownloadFlow);
}

export default [
    fork(sitterDashboardSitterStatusFlowWatcher),
    fork(sitterDashboardStatisticsFlowWatcher),
    fork(dashboardNotificationsFlowWatcher),
    fork(dashboardRecentSittersFlowWatcher),
    fork(dashboardFavoriteSittersFlowWatcher),
    fork(dashboardReviewableBookingsFlowWatcher),
    fork(dashboardCalendarActionFlowWatcher),
    fork(dashboardProfileImageMissingActionFlowWatcher),
    fork(dashboardSitterMarketMaterialsDownloadFlyerWatcher),
    fork(dashboardSitterTaxReportDownloadFlowWatcher),
    fork(dashboardConversationPenaltySummaryFlowWatcher),
];
