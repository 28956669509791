import { z } from 'zod';
import { CoreApi } from './CoreApi';

const CHANNEL = 'web';

export const fallbackFeatureFlags: FeatureFlagsType = {
    DEFAULT_FF: false,
    VAS_ENABLED: false,
};

export type FeatureFlagProps = {
    featureFlags: FeatureFlagsType;
};

const FEATURE_FLAGS = ['DEFAULT_FF', 'VAS_ENABLED'] as const;
export const FeatureFlagEnum = z.enum(FEATURE_FLAGS);
export type FeatureFlag = z.infer<typeof FeatureFlagEnum>;
export type FeatureFlagsType = Record<FeatureFlag, boolean>;

const serverFeatureFlagSchema = z.object({
    activeFeatureFlags: z.array(z.string()),
});
export type ServerFeatureFlags = z.infer<typeof serverFeatureFlagSchema>;

export const fetchFeatureFlags = async (locale: string | undefined = 'en-GB') => {
    try {
        const response = await featureFlagApi.fetchFeatureFlags(locale);
        const serverData = serverFeatureFlagSchema.parse(response.data);
        const serverFeatureFlags = serverFeatureFlagSchema.parse(serverData);
        return mapServerFeatureFlags(serverFeatureFlags);
    } catch (error) {
        console.error('fetchFeatureFlags error', error);
        return fallbackFeatureFlags;
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mapServerFeatureFlags = (serverFeatureFlags: ServerFeatureFlags): FeatureFlagsType => {
    const mappedFeatureFlags = { ...fallbackFeatureFlags };

    // loop local feature flags and map them to the server feature flags
    FeatureFlagEnum.options.forEach((featureFlag) => {
        mappedFeatureFlags[featureFlag] = serverFeatureFlags.activeFeatureFlags.includes(featureFlag);
    });

    return mappedFeatureFlags;
};

class FeatureFlagApi extends CoreApi {
    fetchFeatureFlags = async (locale: string | undefined = 'en-GB') => {
        return this.performGetRequestNewServer(`/v1/feature-flags?locales=${locale}&platforms=${CHANNEL}`);
    };
}

export const featureFlagApi = new FeatureFlagApi();
