import React, { createContext } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const EducationServiceContext = createContext({});

export default function withEducationService(WrappedComponent) {
    const EducationServiceComponent = function EducationServiceComponent(props) {
        return (
            <EducationServiceContext.Consumer>
                {(educationService) => <WrappedComponent {...props} educationService={educationService} />}
            </EducationServiceContext.Consumer>
        );
    };
    hoistNonReactStatics(EducationServiceComponent, WrappedComponent);
    return EducationServiceComponent;
}
