import { fromJS } from 'immutable';
import {
    DASHBOARD_SITTER_STATISTICS_SUCCESS,
    DASHBOARD_CONVERSATION_PENALTY_SUMMARY_SUCCESS,
    DASHBOARD_NOTIFICATIONS_SUCCESS,
    DASHBOARD_RECENT_SITTERS_SUCCESS,
    DASHBOARD_FAVORITE_SITTERS_SUCCESS,
    DASHBOARD_SITTER_STATUS_SUCCESS,
    DASHBOARD_ACTION_CREATED,
    DOWNLOAD_SITTER_MARKET_MATERIALS_REQUEST,
    DOWNLOAD_SITTER_MARKET_MATERIALS_FAILURE,
    DOWNLOAD_SITTER_MARKET_MATERIALS_SUCCESS,
    DOWNLOAD_SITTER_TAX_REPORT_REQUEST,
    DOWNLOAD_SITTER_TAX_REPORT_SUCCESS,
    DOWNLOAD_SITTER_TAX_REPORT_FAILURE,
    DASHBOARD_REVIEWABLE_BOOKINGS_SUCCESS,
} from './constants';
import { DISMISS_NOTIFICATION_SUCCESS } from '../Notifications/constants';

export const initialState = fromJS({
    sitterStatistics: {
        overallStatistics: {},
        serviceStatistics: {},
        profileViews: 0,
        zendeskArticleIds: [],
    },
    notifications: [],
    actions: [],
    recentSitters: [],
    favoriteSitters: [],
    reviewableBookings: [],
    sitterStatus: {
        isBlocked: false,
        isInactive: false,
        isOnHoliday: false,
        isPenalised: false,
        isPublished: true,
    },
    finishedLoadingConversationPenaltySummary: false,
    conversationPenaltySummary: {
        needsToContactCustomerSupport: false,
        penalties: [],
    },
    fetchedRecentSitters: false,
    fetchedNotifications: false,
    fetchedFavoriteSitters: false,
    fetchedReviewableBookings: false,
    downloadSitterMarketMaterialsPending: {
        flyer: false,
        businessCard: false,
    },
    downloadSitterTaxReportPending: false,
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case DASHBOARD_CONVERSATION_PENALTY_SUMMARY_SUCCESS:
            return state
                .set('finishedLoadingConversationPenaltySummary', true)
                .set('conversationPenaltySummary', fromJS(payload));
        case DOWNLOAD_SITTER_TAX_REPORT_REQUEST:
            return state.set('downloadSitterTaxReportPending', true);
        case DOWNLOAD_SITTER_TAX_REPORT_SUCCESS:
        case DOWNLOAD_SITTER_TAX_REPORT_FAILURE:
            return state.set('downloadSitterTaxReportPending', false);
        case DOWNLOAD_SITTER_MARKET_MATERIALS_REQUEST:
            return state.setIn(['downloadSitterMarketMaterialsPending', payload.type], true);
        case DOWNLOAD_SITTER_MARKET_MATERIALS_FAILURE:
        case DOWNLOAD_SITTER_MARKET_MATERIALS_SUCCESS:
            return state.setIn(['downloadSitterMarketMaterialsPending', payload], false);
        case DASHBOARD_ACTION_CREATED:
            return state.set('actions', fromJS([...state.get('actions'), payload]));
        case DASHBOARD_SITTER_STATUS_SUCCESS:
            return state.set('sitterStatus', fromJS(payload));
        case DASHBOARD_SITTER_STATISTICS_SUCCESS:
            return state.set('sitterStatistics', fromJS(payload));
        case DASHBOARD_NOTIFICATIONS_SUCCESS:
            return state.set('notifications', fromJS(payload)).set('fetchedNotifications', true);
        case DASHBOARD_RECENT_SITTERS_SUCCESS:
            return state.set('recentSitters', fromJS(payload)).set('fetchedRecentSitters', true);
        case DASHBOARD_FAVORITE_SITTERS_SUCCESS:
            return state.set('favoriteSitters', fromJS(payload)).set('fetchedFavoriteSitters', true);
        case DASHBOARD_REVIEWABLE_BOOKINGS_SUCCESS:
            return state.set('reviewableBookings', fromJS(payload)).set('fetchedReviewableBookings', true);
        case DISMISS_NOTIFICATION_SUCCESS:
            return state.set(
                'notifications',
                fromJS(
                    state
                        .get('notifications')
                        .toJS()
                        .filter(
                            (notification) => notification.dashboardNotificationId !== payload.dashboardNotificationId
                        )
                )
            );
        default:
            return state;
    }
};
