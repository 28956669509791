import createRequestActionStrings from '../../../utils/createRequestActionStrings';

export const USER_JWT_TOKEN_SUCCESS = 'USER_JWT_TOKEN_SUCCESS';
export const USER_JWT_TOKEN_LOADING = 'USER_JWT_TOKEN_LOADING';
export const FIREBASE_AUTHENTICATED = 'FIREBASE_AUTHENTICATED';

export const [USER_LOGOUT_REQUEST, USER_LOGOUT_SUCCESS, USER_LOGOUT_FAILURE] =
    createRequestActionStrings('USER_LOGOUT');

export const [ENRICH_USERS_WITH_DETAILS_REQUEST, ENRICH_USERS_WITH_DETAILS_SUCCESS, ENRICH_USERS_WITH_DETAILS_FAILURE] =
    createRequestActionStrings('ENRICH_USERS_WITH_DETAILS');

export const ENRICH_USERS_WITH_DETAILS_CLEANUP = 'ENRICH_USERS_WITH_DETAILS_CLEANUP';
