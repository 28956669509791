import { auth } from './firebase';
import jwtDecode from 'jwt-decode';
import SitterApi from '../api/SitterApi';

export class EducationService {
    isSitter = () => {
        return auth.currentUser.getIdToken().then((token) => {
            const decoded = jwtDecode(token);
            return decoded.is;
        });
    };
    getPayoutInformationRequirements = () => {
        return auth.currentUser.getIdToken().then((token) => {
            return SitterApi.getPayoutInformationRequirements(token);
        });
    };

    getSubmissionStatusCta = () => {
        return auth.currentUser.getIdToken().then((token) => {
            return SitterApi.getSubmissionStatusCta(token);
        });
    };
}
