import { createAction } from 'redux-actions';
import {
    SITTER_LISTING_POLICE_CHECK_FAILURE,
    SITTER_LISTING_POLICE_CHECK_REQUEST,
    SITTER_LISTING_POLICE_CHECK_SUCCESS,
    REQUEST_POLICE_CHECK_REQUEST,
    REQUEST_POLICE_CHECK_SUCCESS,
    REQUEST_POLICE_CHECK_FAILURE,
    RENEW_POLICE_CHECK_REQUEST,
    RENEW_POLICE_CHECK_SUCCESS,
    RENEW_POLICE_CHECK_FAILURE,
} from './constants';

export const sitterListingPoliceCheckRequest = createAction(SITTER_LISTING_POLICE_CHECK_REQUEST);

export const sitterListingPoliceCheckSuccess = createAction(SITTER_LISTING_POLICE_CHECK_SUCCESS);

export const sitterListingPoliceCheckFailure = createAction(SITTER_LISTING_POLICE_CHECK_FAILURE);

export const renewPoliceCheckRequest = createAction(RENEW_POLICE_CHECK_REQUEST);

export const renewPoliceCheckSuccess = createAction(RENEW_POLICE_CHECK_SUCCESS);

export const renewPoliceCheckFailure = createAction(RENEW_POLICE_CHECK_FAILURE);

export const requestPoliceCheckRequest = createAction(REQUEST_POLICE_CHECK_REQUEST);

export const requestPoliceCheckSuccess = createAction(REQUEST_POLICE_CHECK_SUCCESS);

export const requestPoliceCheckFailure = createAction(REQUEST_POLICE_CHECK_FAILURE);
