import { all } from 'redux-saga/effects';
import BookingSagas from './Booking/sagas';
import CampaignSagas from './Campaign/sagas';
import ConversationSagas from './Conversation/sagas';
import DashboardSitterAvailabilitySagas from './Dashboard/Sitter/Availability/sagas';
import DashboardSagas from './Dashboard/sagas';
import FavoritesSagas from './Favorites/sagas';
import InboxSagas from './Inbox/sagas';
import NotificationsStandaloneSagas from './Notifications/sagas';
import PetSagas from './Pet/sagas';
import ProfileSitterListingSagas from './Profile/SitterListing/sagas';
import TopReviewsSagas from './Reviews/sagas';
import SearchSagas from './Search/sagas';
import SitterSagas from './Sitter/sagas';
import UserAuthenticationSagas from './User/Authentication/sagas';
import UserPetsSagas from './User/Pets/sagas';

function* rootSaga() {
    yield all([
        ...CampaignSagas,
        ...PetSagas,
        ...SearchSagas,
        ...SitterSagas,
        ...UserAuthenticationSagas,
        ...ConversationSagas,
        ...UserPetsSagas,
        ...InboxSagas,
        ...DashboardSagas,
        ...DashboardSitterAvailabilitySagas,
        ...BookingSagas,
        ...TopReviewsSagas,
        ...NotificationsStandaloneSagas,
        ...FavoritesSagas,
        ...ProfileSitterListingSagas,
    ]);
}

export default rootSaga;
