import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AWAY, HOME } from '../../Pawshake/Core/Service/Groups';
import ServiceSelectOption from './ServiceSelectOption';
import GroupLabelText from './Labels/GroupLabelText';

const Groups = styled.div`
    width: 100%;
`;

const Group = styled.div`
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
        border-bottom: none;
    }
`;

const GroupHeader = styled.div`
    padding: 1rem;
    color: #939393;
    border-bottom: 1px solid #cecece;
`;

class ServiceSelect extends PureComponent {
    state = {
        activeService: this.props.activeService,
    };

    onClick = (service) => {
        const { onClick } = this.props;
        this.setState({ activeService: service });
        if (onClick) {
            onClick(service);
        }
    };

    render() {
        const { services, className } = this.props;
        const { activeService } = this.state;

        const groups = [AWAY, HOME];

        return (
            <Groups className={className}>
                {groups.map(
                    (group) =>
                        typeof services[group] !== 'undefined' && (
                            <Group key={group}>
                                <GroupHeader>
                                    <GroupLabelText group={group} />
                                </GroupHeader>
                                {services[group].map((service) => (
                                    <ServiceSelectOption
                                        key={service.id}
                                        service={service}
                                        onClick={this.onClick}
                                        checked={service.id === activeService.id}
                                    />
                                ))}
                            </Group>
                        )
                )}
            </Groups>
        );
    }
}

ServiceSelect.propTypes = {
    onClick: PropTypes.func,
    services: PropTypes.array.isRequired,
    activeService: PropTypes.object,
};

export default ServiceSelect;
