import { fromJS } from 'immutable';
import {
    INBOX_SUCCESS,
    INBOX_REQUEST,
    INBOX_UNREAD_COUNTER_SUCCESS,
    INBOX_FAILURE,
    INBOX_ARCHIVE_SUCCESS,
    INBOX_ARCHIVE_FAILURE,
    INBOX_ARCHIVE_REQUEST,
} from './constants';
import { NO_FILTER } from '../../Pawshake/Inbox/InboxFilter';

export const initialState = fromJS({
    inboxArchive: {
        fetched: false,
        entries: [],
        pageNumber: 1,
        total: 0,
    },
    inboxEntriesFetched: false,
    inboxEntries: [],
    inboxFilter: NO_FILTER,
    inboxUnreadCounter: 0,
    pageNumber: 1,
    total: 0,
});

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case INBOX_ARCHIVE_REQUEST:
            return state
                .setIn(['inboxArchive', 'fetched'], false)
                .setIn(['inboxArchive', 'entries'], [])
                .setIn(['inboxArchive', 'pageNumber'], payload.pageNumber)
                .setIn(['inboxArchive', 'total'], 0);
        case INBOX_ARCHIVE_SUCCESS:
            return state
                .setIn(['inboxArchive', 'fetched'], true)
                .setIn(['inboxArchive', 'entries'], fromJS(payload.entries))
                .setIn(['inboxArchive', 'total'], payload.total);
        case INBOX_ARCHIVE_FAILURE:
            return state.setIn(['inboxArchive', 'fetched'], true);
        case INBOX_REQUEST:
            return state
                .set('inboxFilter', payload.inboxFilter)
                .set('inboxEntries', [])
                .set('inboxEntriesFetched', false)
                .set('total', 0)
                .set('pageNumber', payload.pageNumber);
        case INBOX_SUCCESS:
            return state
                .set('total', payload.total)
                .set('inboxEntriesFetched', true)
                .set('inboxEntries', fromJS(payload.entries));
        case INBOX_FAILURE:
            return state.set('inboxEntriesFetched', true);
        case INBOX_UNREAD_COUNTER_SUCCESS:
            return state.set('inboxUnreadCounter', fromJS(payload));
        default:
            return state;
    }
};
