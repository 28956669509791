import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DropdownIcon } from '../../../../../styles/icons';
import PsNavBarButton from '../../templates/navbar-button';
import PsAvatar from '../avatar';
import ProfileActions from './profile-actions';

const DropdownWrapper = styled.div`
    position: relative;
`;

const Dropdown = styled.div`
    position: absolute;
    top: calc(100% + 0.25rem);
    left: 0;
    z-index: 500;
    display: none;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #cecece;
    box-shadow: 0 14px 36px 2px rgba(0, 0, 0, 0.15);
    padding: 0.25rem 0;
    min-width: 13rem;
    width: 100%;

    > * {
        width: 100%;
    }

    &.expanded {
        display: block;
    }
`;

const ProfileActionsDropdown = ({
    t,
    application,
    isASitter,
    hasACompletedSitterApplication,
    userImage,
    userName,
    logout,
}: {
    application: any;
    isASitter: boolean;
    hasACompletedSitterApplication: boolean;
    userImage: string;
    userName: string;
    logout: () => void;
    t: (_key: string) => string;
}) => {
    const [isOpen, setOpen] = useState(false);
    const toggleMenu = useCallback(() => {
        setOpen((previousValue) => !previousValue);
    }, []);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        window.addEventListener('click', toggleMenu);

        return () => {
            window.removeEventListener('click', toggleMenu);
        };
    }, [isOpen, toggleMenu]);

    return (
        <DropdownWrapper>
            <PsNavBarButton onClick={toggleMenu} style={{ gap: '.5rem' }}>
                <PsAvatar src={userImage} label={userName} size={'2rem'} />
                {userName}
                <DropdownIcon />
            </PsNavBarButton>
            <Dropdown className={isOpen ? 'expanded' : ''}>
                <ProfileActions
                    t={t}
                    application={application}
                    isDropdown={true}
                    isASitter={isASitter}
                    hasACompletedSitterApplication={hasACompletedSitterApplication}
                    logout={logout}
                    closeDrawer={toggleMenu}
                />
            </Dropdown>
        </DropdownWrapper>
    );
};

export default ProfileActionsDropdown;
