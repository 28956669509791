import axios from '../Core/Api';

class TopReviewsApi {
    getForLocale = (values) => {
        const { locale, pageNumber, pageSize } = values;
        return axios
            .get(
                `reviews/top-with-aggregated-reviews/${locale.toString()}?page[number]=${pageNumber}&page[size]=${pageSize}`
            )
            .then((response) => response.data);
    };

    getFeaturedTopReviewForLocale = (locale) => {
        return axios.get(`reviews/featured/${locale.toString()}`).then((response) => response.data);
    };
}

export default new TopReviewsApi();
