import createRequestActionStrings from '../../utils/createRequestActionStrings';

export const [
    DASHBOARD_SITTER_STATISTICS_REQUEST,
    DASHBOARD_SITTER_STATISTICS_SUCCESS,
    DASHBOARD_SITTER_STATISTICS_FAILURE,
] = createRequestActionStrings('DASHBOARD_SITTER_STATISTICS');

export const [
    DASHBOARD_CONVERSATION_PENALTY_SUMMARY_REQUEST,
    DASHBOARD_CONVERSATION_PENALTY_SUMMARY_SUCCESS,
    DASHBOARD_CONVERSATION_PENALTY_SUMMARY_FAILURE,
] = createRequestActionStrings('DASHBOARD_CONVERSATION_PENALTY_SUMMARY');

export const [DASHBOARD_SITTER_STATUS_REQUEST, DASHBOARD_SITTER_STATUS_SUCCESS, DASHBOARD_SITTER_STATUS_FAILURE] =
    createRequestActionStrings('DASHBOARD_SITTER_STATUS');

export const [DASHBOARD_NOTIFICATIONS_REQUEST, DASHBOARD_NOTIFICATIONS_SUCCESS, DASHBOARD_NOTIFICATIONS_FAILURE] =
    createRequestActionStrings('DASHBOARD_NOTIFICATIONS');

export const [DASHBOARD_RECENT_SITTERS_REQUEST, DASHBOARD_RECENT_SITTERS_SUCCESS, DASHBOARD_RECENT_SITTERS_FAILURE] =
    createRequestActionStrings('DASHBOARD_RECENT_SITTERS');

export const [
    DASHBOARD_FAVORITE_SITTERS_REQUEST,
    DASHBOARD_FAVORITE_SITTERS_SUCCESS,
    DASHBOARD_FAVORITE_SITTERS_FAILURE,
] = createRequestActionStrings('DASHBOARD_FAVORITE_SITTERS');

export const [
    DASHBOARD_REVIEWABLE_BOOKINGS_REQUEST,
    DASHBOARD_REVIEWABLE_BOOKINGS_SUCCESS,
    DASHBOARD_REVIEWABLE_BOOKINGS_FAILURE,
] = createRequestActionStrings('DASHBOARD_REVIEWABLE_BOOKINGS');

export const [
    DOWNLOAD_SITTER_MARKET_MATERIALS_REQUEST,
    DOWNLOAD_SITTER_MARKET_MATERIALS_SUCCESS,
    DOWNLOAD_SITTER_MARKET_MATERIALS_FAILURE,
] = createRequestActionStrings('DOWNLOAD_SITTER_MARKET_MATERIALS');

export const [
    DOWNLOAD_SITTER_TAX_REPORT_REQUEST,
    DOWNLOAD_SITTER_TAX_REPORT_SUCCESS,
    DOWNLOAD_SITTER_TAX_REPORT_FAILURE,
] = createRequestActionStrings('DOWNLOAD_SITTER_TAX_REPORT');

export const DASHBOARD_ACTION_CREATED = 'DASHBOARD_ACTION_CREATED';
