import { css } from 'styled-components';
import Breakpoints from '../../../../styles/breakpoints';
import Colors from '../../../../styles/colors';

const ActionItemStyle = css`
    margin-right: -0.25rem;
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    border-radius: 0.5rem;
    min-height: 2.5rem;
    cursor: pointer;
    text-decoration: none;
    background: ${Colors.brand.default};
    color: ${Colors.inversed.default};
    line-height: 1.5rem;
    text-align: left;

    :hover {
        background-color: ${Colors.brand.hover};
    }
    :active {
        background-color: ${Colors.brand.active};
    }

    .icon_wrapper {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }

    @media ${Breakpoints.md} {
        && {
            padding: 0 0.5rem;

            &.danger {
                color: ${Colors.danger.default};
            }

            .icon_wrapper,
            svg:not(.dropdown) {
                display: none;
            }
        }
    }

    &.icon {
        width: 2.5rem;
        justify-content: center;
        padding: 0;

        svg {
            display: block !important;
        }
    }

    &&.dropdown {
        justify-content: space-between;
    }

    &&.indented {
        padding-left: 1.5rem;
        transition: all 0.2s ease-in;
        li:last-child {
            border: none;
        }
    }

    &&.menuItem {
        height: 2.25rem;
        background-color: ${Colors.inversed.default};
        color: #4f4f4f;
        border-radius: 0;
        padding: 0 0.75rem;
        width: 100%;

        &.danger {
            color: ${Colors.danger.default};
        }

        :hover {
            background: #eeeeee;
        }
        :active {
            background: #cecece;
        }

        svg {
            display: none;
        }
    }

    &.main {
        margin: 0;
        padding: 0.25rem 1rem;
        background: ${Colors.inversed.default};
        color: ${Colors.brand.default};

        &:hover {
            background: ${Colors.inversed.hover};
        }
        &:active {
            background: ${Colors.inversed.active};
        }
    }
`;

export default ActionItemStyle;
