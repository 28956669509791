import { BaseApi } from '../Core/BaseApi';

class NotificationApi extends BaseApi {
    getDashboardNotifications = (jwtToken) => {
        return this.performAuthenticatedGetRequest(jwtToken, '/dashboard/notifications?latest=true');
    };

    dismissDashboardNotification = (dashboardNotificationId, jwtToken) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Notifications.DashboardNotifications.DismissDashboardNotification.ManualDismissDashboardNotification',
            {
                dashboardNotificationId,
            }
        );
    };
}

export default new NotificationApi();
