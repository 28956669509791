import React, { useState } from 'react';
import styled from 'styled-components';
import Label from '../Label/Label';

const Wrapper = styled.div`
    display: block;
    position: relative;
    padding: 1rem 9rem 1rem 1rem;
`;

const CounterLabel = styled(Label)`
    color: #5c5c5c;
`;

const Description = styled.div`
    color: #939393;
    font-size: 0.9rem;
    margin-top: 0.6rem;
`;

const Button = styled.div`
    background: #ffffff;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    position: relative;
    touch-action: manipulation;
    cursor: pointer;
    display: inline-block;
    line-height: 2rem;
    text-align: center;
    user-select: none;
`;

const InactiveButton = styled(Button)`
    border: 1px solid #cecece;
    color: #cecece;
`;

const ActiveButton = styled(Button)`
    border: 1px solid #00c38a;
    color: #00c38a;
`;

const Actions = styled.div`
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
`;

const CurrentCount = styled.span`
    display: inline-block;
    width: 2rem;
    height: 2rem;
    position: relative;
    vertical-align: bottom;
    font-size: 1.2rem;
`;

const CurrentCountValue = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    line-height: 0;
`;

const Counter = ({ name, count, className, label, description, limit, onChange }) => {
    const [internalCount, setInternalCount] = useState(count);

    let Increment = ActiveButton;
    const hasLimit = typeof limit !== 'undefined';
    if (hasLimit) {
        Increment = internalCount < limit ? ActiveButton : InactiveButton;
    }

    const Decrement = internalCount > 0 ? ActiveButton : InactiveButton;

    return (
        <Wrapper className={className}>
            <CounterLabel>{label}</CounterLabel>
            <Description>{description}</Description>
            <Actions>
                <Decrement
                    onClick={() => {
                        if (internalCount <= 0) {
                            return;
                        }
                        const newInternalCount = internalCount - 1;
                        if (onChange) {
                            onChange(name, newInternalCount);
                        }
                        setInternalCount(newInternalCount);
                    }}
                >
                    -
                </Decrement>
                <CurrentCount>
                    <CurrentCountValue>{internalCount}</CurrentCountValue>
                </CurrentCount>
                <Increment
                    onClick={() => {
                        if (hasLimit && internalCount === limit) {
                            return;
                        }
                        const newInternalCount = internalCount + 1;
                        if (onChange) {
                            onChange(name, newInternalCount);
                        }
                        setInternalCount(newInternalCount);
                    }}
                >
                    +
                </Increment>
            </Actions>
        </Wrapper>
    );
};

export default Counter;
