import { trackEvent } from '../../lib/analytics';

const trackSearchEvent = (action) => {
    trackEvent('Search-2', action);
};

const trackMobileSearchEvent = (action) => {
    trackSearchEvent('Mobile - ' + action);
};

const trackDesktopSearchEvent = (action) => {
    trackSearchEvent('Desktop - ' + action);
};

const trackSearchEventWithMediaType = (mobile, action) => {
    if (mobile) {
        trackMobileSearchEvent(action);
    } else {
        trackDesktopSearchEvent(action);
    }
};

export const trackListResultClickedEvent = (mobile) => {
    trackSearchEventWithMediaType(mobile, 'ResultClicked-List');
};

export const trackSearchSitterResultClicked = (showCalendarBadge) => {
    trackEvent('Search-2', 'Sitter result clicked', showCalendarBadge ? 'calendar yes' : 'calendar no');
};

export const trackMapResultClickedEvent = (mobile) => {
    trackSearchEventWithMediaType(mobile, 'ResultClicked-Map');
};

export const trackPageChangeEvent = (mobile) => {
    trackSearchEventWithMediaType(mobile, 'PageChange');
};

export const trackShowMapClick = () => {
    trackMobileSearchEvent('ShowMap');
};

export const trackShowFiltersClick = () => {
    trackMobileSearchEvent('ShowFilters');
};

const LOCATION_FILTER = 'Location';
const SERVICE_FILTER = 'Service';
const DATES_FILTER = 'Dates';
const PETS_FILTER = 'Pets';
const ADVANCED_FILTER = 'Advanced';

const generateOpenFilterAction = (filter) => {
    return 'OpenFilter-' + filter;
};

const generateApplyFilterAction = (filter) => {
    return 'UsedFilter-' + filter;
};

const generateClearFilterAction = (filter) => {
    return 'ClearFilter-' + filter;
};

const generateCloseFilterAction = (filter) => {
    return 'CloseFilter-' + filter;
};

export const trackOpenLocationFilter = (mobile) => {
    trackSearchEventWithMediaType(mobile, generateOpenFilterAction(LOCATION_FILTER));
};

export const trackApplyLocationFilter = (mobile) => {
    trackSearchEventWithMediaType(mobile, generateApplyFilterAction(LOCATION_FILTER));
};

export const trackOpenServiceFilter = (mobile) => {
    trackSearchEventWithMediaType(mobile, generateOpenFilterAction(SERVICE_FILTER));
};

export const trackApplyServiceFilter = (mobile) => {
    trackSearchEventWithMediaType(mobile, generateApplyFilterAction(SERVICE_FILTER));
};

export const trackCloseServiceFilter = () => {
    trackMobileSearchEvent(generateCloseFilterAction(SERVICE_FILTER));
};

export const trackOpenDatesFilter = (mobile) => {
    trackSearchEventWithMediaType(mobile, generateOpenFilterAction(DATES_FILTER));
};

export const trackApplyDatesFilter = (mobile) => {
    trackSearchEventWithMediaType(mobile, generateApplyFilterAction(DATES_FILTER));
};

export const trackClearDatesFilter = () => {
    trackDesktopSearchEvent(generateClearFilterAction(DATES_FILTER));
};

export const trackCloseDatesFilter = () => {
    trackMobileSearchEvent(generateCloseFilterAction(DATES_FILTER));
};

export const trackOpenPetsFilter = (mobile) => {
    trackSearchEventWithMediaType(mobile, generateOpenFilterAction(PETS_FILTER));
};

export const trackApplyPetsFilter = (mobile) => {
    trackSearchEventWithMediaType(mobile, generateApplyFilterAction(PETS_FILTER));
};

export const trackClearPetsFilter = () => {
    trackDesktopSearchEvent(generateClearFilterAction(PETS_FILTER));
};

export const trackClosePetsFilter = () => {
    trackMobileSearchEvent(generateCloseFilterAction(PETS_FILTER));
};

export const trackOpenAdvancedFilter = (mobile) => {
    trackSearchEventWithMediaType(mobile, generateOpenFilterAction(ADVANCED_FILTER));
};

export const trackApplyAdvancedFilter = (mobile) => {
    trackSearchEventWithMediaType(mobile, generateApplyFilterAction(ADVANCED_FILTER));
};

export const trackClearAdvancedFilter = () => {
    trackDesktopSearchEvent(generateClearFilterAction(ADVANCED_FILTER));
};

export const trackCloseAdvancedFilter = () => {
    trackMobileSearchEvent(generateCloseFilterAction(ADVANCED_FILTER));
};

export const trackSearchPopupEvent = (action, eventLabel) => {
    trackEvent('Search-2 - Search Popup', action, eventLabel);
};

export const trackMobileSearchPopupEvent = (action, eventLabel) => {
    trackEvent('Search-2 - Search Popup mobile', action, eventLabel);
};
