import styled from 'styled-components';

const NavBarLogoLink = styled.a`
    display: flex;
    align-items: center;
    height: 2.5rem;
    cursor: pointer;
`;

const PsNavBarLogo = () => (
    <NavBarLogoLink href={'/'}>
        <img
            width={'127px'}
            height={'30px'}
            src={'https://static1.pawshakecdn.com/global/logo.png'}
            alt={'Pawshake logo'}
            title={'Pawshake'}
            style={{ display: 'block' }}
        />
    </NavBarLogoLink>
);

export default PsNavBarLogo;
