import { BaseApi } from '../Core/BaseApi';

class ConversationApi extends BaseApi {
    getConversationMessages = (jwtToken, conversationId, pageNumber, pageSize, latestMessageId) => {
        const append = latestMessageId ? `&latestMessageId=${latestMessageId}` : '';

        return this.performAuthenticatedGetRequest(
            jwtToken,
            `/conversation/${conversationId}/messages?page[number]=${pageNumber}&page[size]=${pageSize}${append}`
        );
    };

    getConversationParticipants = (jwtToken, conversationId) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/conversation/${conversationId}/participants`);
    };

    getConversationAllowedInteractions = (jwtToken, conversationId) => {
        return this.performAuthenticatedGetRequest(jwtToken, `/conversation/${conversationId}/allowed-interactions`);
    };

    sendTextMessage = (jwtToken, conversationId, message, recipientUserId) => {
        return this.sendCommand(jwtToken, 'Pawshake.Conversation.WriteModel.SendTextMessage.SendTextMessage', {
            conversationId,
            messageContent: message,
            messageRecipient: recipientUserId,
        });
    };

    sendImage = (jwtToken, conversationId, base64EncodedImage) => {
        return this.sendCommand(jwtToken, 'Pawshake.Conversation.WriteModel.SendImage.SendImage', {
            conversationId,
            base64EncodedImage,
        });
    };

    archiveConversation = (jwtToken, conversationId) => {
        return this.sendCommand(jwtToken, 'Pawshake.Conversation.WriteModel.ArchiveConversation.ArchiveConversation', {
            conversationId,
        });
    };

    unarchiveConversation = (jwtToken, conversationId) => {
        return this.sendCommand(
            jwtToken,
            'Pawshake.Conversation.WriteModel.UnarchiveConversation.ManualUnarchiveConversation',
            {
                conversationId,
            }
        );
    };
}

export default new ConversationApi();
