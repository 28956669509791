import styled from 'styled-components';
import Colors from '../../../../styles/colors';

const Badge = styled.div`
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    background-color: ${Colors.danger.default};
    color: white;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    border-radius: 9999px;
    border: 1px solid;
    border-color: white;
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.4;
    box-sizing: border-box;
`;

const PsBadge = ({ amount }: { amount: number }) => <Badge className='badge'>{amount}</Badge>;

export default PsBadge;
