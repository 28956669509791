import React from 'react';

const DefaultDatePickerNavigationHeader = (props) => {
    const { nextMonth, previousMonth, onPreviousClick, onNextClick, className, onMonthNavigation } = props;

    return (
        <div className={className}>
            <div
                className={'DayPicker-NavButton DayPicker-NavButton--prev'}
                onClick={() => {
                    if (onMonthNavigation) {
                        onMonthNavigation(previousMonth);
                    }
                    onPreviousClick();
                }}
            />
            <div
                className={'DayPicker-NavButton DayPicker-NavButton--next'}
                onClick={() => {
                    if (onMonthNavigation) {
                        onMonthNavigation(nextMonth);
                    }
                    onNextClick();
                }}
            />
        </div>
    );
};

export default DefaultDatePickerNavigationHeader;
