import styled from 'styled-components';
import Breakpoints from '../../../../../styles/breakpoints';
import { SearchIcon } from '../../../../../styles/icons';
import PsNavBarButton from '../../templates/navbar-button';
import PsNavBarBecomeSitterLink from '../navbar/become-sitter-link';
import PsNavBarInboxLink from '../navbar/inbox-link';
import PsNavBarNotificationsLink from '../navbar/notifications-link';
import ProfileActions from './profile-actions';
import ProfileActionsDropdown from './profile-actions-dropdown';

const DesktopButton = styled.div`
    display: none;

    @media ${Breakpoints.md} {
        && {
            display: flex;
        }
    }
`;

const UserMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media ${Breakpoints.md} {
        && {
            display: none;
        }
    }
`;

const MainActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    @media ${Breakpoints.md} {
        && {
            flex-direction: row;
            margin-bottom: 0;
        }
    }
`;

const UserActions = ({
    application,
    isASitter,
    hasACompletedSitterApplication,
    userImage,
    userName,
    messageUnreadCounter,
    openSearch,
    closeDrawer,
    logout,
    t,
    trackEvent,
}: {
    application: any;
    isASitter: boolean;
    hasACompletedSitterApplication: boolean;
    userImage: string;
    userName: string;
    messageUnreadCounter?: number;
    openSearch: () => void;
    closeDrawer: () => void;
    logout: () => void;
    t: (key: string) => string;
    trackEvent: (fsCategory: any, gaCategory: any, action: any, eventLabel: any) => void;
}) => {
    const onSearchButtonClick = () => {
        trackEvent('SEARCH_SITTERS_HEADER', 'Homepage - Search Popup', 'Search', 'Authenticated');
        openSearch();
    };

    return (
        <>
            <MainActionsWrapper>
                <PsNavBarButton isMain={true} onClick={onSearchButtonClick}>
                    <div className='icon_wrapper'>
                        <SearchIcon />
                    </div>
                    {t('user.menu.searchSitters')}
                </PsNavBarButton>
                {!isASitter && <PsNavBarBecomeSitterLink t={t} closeDrawer={closeDrawer} />}
            </MainActionsWrapper>
            <DesktopButton>
                <ProfileActionsDropdown
                    t={t}
                    userImage={userImage}
                    userName={userName}
                    isASitter={isASitter}
                    hasACompletedSitterApplication={hasACompletedSitterApplication}
                    application={application}
                    logout={logout}
                />
            </DesktopButton>
            <UserMenuWrapper>
                <ProfileActions
                    t={t}
                    application={application}
                    isASitter={isASitter}
                    messageUnreadCounter={messageUnreadCounter}
                    hasACompletedSitterApplication={hasACompletedSitterApplication}
                    logout={logout}
                    includeSubmenu={true}
                    closeDrawer={closeDrawer}
                />
            </UserMenuWrapper>
            <DesktopButton>
                <PsNavBarInboxLink badge={messageUnreadCounter ?? 0} locale={application.locale.toString()} />
            </DesktopButton>
            <DesktopButton>
                <PsNavBarNotificationsLink locale={application.locale.toString()} />
            </DesktopButton>
        </>
    );
};

export default UserActions;
