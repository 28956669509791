import React, { HTMLProps } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import PsBadge from '../components/badge';
import ActionItemStyle from '../constants/action-item-style';

export type PsNavBarLinkProps = {
    isMain?: boolean;
    badge?: number;
} & HTMLProps<HTMLAnchorElement>;

export const NavBarLink = styled.div`
    ${ActionItemStyle}
    border: none;

    &.drawerItem + .badge {
        right: 0.25rem;
        top: calc(50% - 10px); //10px = half of a badge
    }
`;

const PsNavBarLink = React.forwardRef<HTMLAnchorElement, PsNavBarLinkProps>((props: PsNavBarLinkProps, ref) => {
    const { className, isMain, href, as, badge, children, ...rest } = props;

    if (!as) {
        return (
            <a href={href} ref={ref} {...rest} style={{ position: 'relative' }}>
                <NavBarLink className={className ?? '' + (isMain ? 'main' : '')}>{children}</NavBarLink>
                {!!badge && badge !== 0 && <PsBadge amount={badge} />}
            </a>
        );
    }

    return (
        <Link href={href ?? ''} as={as}>
            <a ref={ref} {...rest} style={{ position: 'relative' }}>
                <NavBarLink className={className ?? '' + (isMain ? 'main' : '')}>{children}</NavBarLink>
                {!!badge && badge !== 0 && <PsBadge amount={badge} />}
            </a>
        </Link>
    );
});

export default PsNavBarLink;
