import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { TOGGLE_FAVORITE_SITTER_REQUEST } from './constants';
import { jwtTokenSelect } from '../User/Authentication/selectors';
import { favoriteSittersFetched, toggleFavoriteSitterFailure, toggleFavoriteSitterSuccess } from './actions';
import FavoritesApi from '../../Pawshake/Favorites/FavoritesApi';
import { USER_JWT_TOKEN_SUCCESS } from '../User/Authentication/constants';

function* favoriteSittersRequestFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(FavoritesApi.getFavorites, jwtToken);

    if (response.status === 200) {
        yield put(favoriteSittersFetched(response.data));
    }
}

function* favoriteSittersRequestFlowWatcher() {
    yield takeLatest(USER_JWT_TOKEN_SUCCESS, favoriteSittersRequestFlow);
}

function* toggleFavoriteSitterRequestFlow({ payload }) {
    const jwtToken = yield select(jwtTokenSelect);
    const { sitterId, isFavorite } = payload;

    const response = yield call(FavoritesApi.toggleUserFavoriteSitter, jwtToken, sitterId, isFavorite);

    if (response.status === 204) {
        yield put(toggleFavoriteSitterSuccess(payload));
    } else {
        yield put(toggleFavoriteSitterFailure(payload));
    }
}

function* toggleFavoriteSitterRequestFlowWatcher() {
    yield takeLatest(TOGGLE_FAVORITE_SITTER_REQUEST, toggleFavoriteSitterRequestFlow);
}

export default [fork(toggleFavoriteSitterRequestFlowWatcher), fork(favoriteSittersRequestFlowWatcher)];
