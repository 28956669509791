import { call, fork, put, select, takeLatest, cancelled } from 'redux-saga/effects';
import {
    RENEW_POLICE_CHECK_REQUEST,
    RENEW_POLICE_CHECK_SUCCESS,
    REQUEST_POLICE_CHECK_REQUEST,
    REQUEST_POLICE_CHECK_SUCCESS,
    SITTER_LISTING_POLICE_CHECK_REQUEST,
} from './constants';
import { showFailedToast, showSavingToast, showSuccessToast } from '../../Dashboard/Sitter/Availability/sagas';
import { jwtTokenSelect } from '../../User/Authentication/selectors';
import ProfileSitterListingApi from '../../../Pawshake/Profile/SitterListing/ProfileSitterListingApi';
import {
    sitterListingPoliceCheckSuccess,
    sitterListingPoliceCheckFailure,
    requestPoliceCheckSuccess,
    requestPoliceCheckFailure,
    renewPoliceCheckFailure,
    renewPoliceCheckSuccess,
} from './actions';

function* requestPoliceCheckRequestFlow({ payload }) {
    const hide = showSavingToast('profile:profile.sitterListing.policeCheck.invitePending.pending');

    try {
        const jwtToken = yield select(jwtTokenSelect);
        const response = yield call(ProfileSitterListingApi.requestPoliceCheck, jwtToken);

        hide();
        if (response.status === 204) {
            yield put(requestPoliceCheckSuccess());
            showSuccessToast('profile:profile.sitterListing.policeCheck.invitePending.done', payload);
        } else {
            yield put(requestPoliceCheckFailure());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* requestPoliceCheckRequestFlowWatcher() {
    yield takeLatest(REQUEST_POLICE_CHECK_REQUEST, requestPoliceCheckRequestFlow);
}

function* renewPoliceCheckRequestFlow({ payload }) {
    const hide = showSavingToast('profile:profile.sitterListing.policeCheck.invitePending.pending');

    try {
        const jwtToken = yield select(jwtTokenSelect);
        const response = yield call(ProfileSitterListingApi.renewPoliceCheck, jwtToken);

        hide();
        if (response.status === 204) {
            yield put(renewPoliceCheckSuccess());
            showSuccessToast('profile:profile.sitterListing.policeCheck.invitePending.done', payload);
        } else {
            yield put(renewPoliceCheckFailure());
            showFailedToast();
        }
    } finally {
        if (yield cancelled()) {
            hide();
        }
    }
}

function* renewPoliceCheckRequestFlowWatcher() {
    yield takeLatest(RENEW_POLICE_CHECK_REQUEST, renewPoliceCheckRequestFlow);
}

function* sitterListingPoliceCheckRequestFlow() {
    const jwtToken = yield select(jwtTokenSelect);
    const response = yield call(ProfileSitterListingApi.getPoliceCheck, jwtToken);
    if (response.status === 200) {
        yield put(sitterListingPoliceCheckSuccess(response.data));
    } else {
        yield put(sitterListingPoliceCheckFailure(response.status));
    }
}

function* sitterListingPoliceCheckRequestFlowWatcher() {
    yield takeLatest(
        [SITTER_LISTING_POLICE_CHECK_REQUEST, REQUEST_POLICE_CHECK_SUCCESS, RENEW_POLICE_CHECK_SUCCESS],
        sitterListingPoliceCheckRequestFlow
    );
}

export default [
    fork(requestPoliceCheckRequestFlowWatcher),
    fork(renewPoliceCheckRequestFlowWatcher),
    fork(sitterListingPoliceCheckRequestFlowWatcher),
];
