import { createAction } from 'redux-actions';
import {
    CONVERSATION_FIRST_PAGE_MESSAGES_LOADED,
    CONVERSATION_ALLOWED_INTERACTIONS_REQUEST,
    CONVERSATION_ALLOWED_INTERACTIONS_SUCCESS,
    CONVERSATION_ALLOWED_INTERACTIONS_FAILURE,
    CONVERSATION_MESSAGES_REQUEST,
    CONVERSATION_MESSAGES_SUCCESS,
    CONVERSATION_MESSAGES_FAILURE,
    CONVERSATION_PARTICIPANTS_REQUEST,
    CONVERSATION_PARTICIPANTS_SUCCESS,
    CONVERSATION_PARTICIPANTS_FAILURE,
    SEND_TEXT_MESSAGE_REQUEST,
    SEND_TEXT_MESSAGE_SUCCESS,
    SEND_TEXT_MESSAGE_FAILURE,
    SEND_IMAGE_REQUEST,
    SEND_IMAGE_SUCCESS,
    SEND_IMAGE_FAILURE,
    RESET_CONVERSATION_AND_BOOKING_STORES,
    ARCHIVE_CONVERSATION_REQUEST,
    ARCHIVE_CONVERSATION_SUCCESS,
    ARCHIVE_CONVERSATION_FAILURE,
    UNARCHIVE_CONVERSATION_REQUEST,
    UNARCHIVE_CONVERSATION_SUCCESS,
    UNARCHIVE_CONVERSATION_FAILURE,
} from './constants';

export const conversationFirstPageMessagesLoaded = createAction(CONVERSATION_FIRST_PAGE_MESSAGES_LOADED);

export const conversationMessagesRequest = createAction(CONVERSATION_MESSAGES_REQUEST);
export const conversationMessagesSuccess = createAction(CONVERSATION_MESSAGES_SUCCESS);
export const conversationMessagesFailure = createAction(CONVERSATION_MESSAGES_FAILURE);

export const conversationParticipantsRequest = createAction(CONVERSATION_PARTICIPANTS_REQUEST);
export const conversationParticipantsSuccess = createAction(CONVERSATION_PARTICIPANTS_SUCCESS);
export const conversationParticipantsFailure = createAction(CONVERSATION_PARTICIPANTS_FAILURE);

export const resetConversationAndBooking = createAction(RESET_CONVERSATION_AND_BOOKING_STORES);

export const conversationAllowedInteractionsRequest = createAction(CONVERSATION_ALLOWED_INTERACTIONS_REQUEST);
export const conversationAllowedInteractionsSuccess = createAction(CONVERSATION_ALLOWED_INTERACTIONS_SUCCESS);
export const conversationAllowedInteractionsFailure = createAction(CONVERSATION_ALLOWED_INTERACTIONS_FAILURE);

export const sendTextMessageRequest = createAction(SEND_TEXT_MESSAGE_REQUEST);
export const sendTextMessageSuccess = createAction(SEND_TEXT_MESSAGE_SUCCESS);
export const sendTextMessageFailure = createAction(SEND_TEXT_MESSAGE_FAILURE);

export const sendImageRequest = createAction(SEND_IMAGE_REQUEST);
export const sendImageSuccess = createAction(SEND_IMAGE_SUCCESS);
export const sendImageFailure = createAction(SEND_IMAGE_FAILURE);

export const archiveConversationRequest = createAction(ARCHIVE_CONVERSATION_REQUEST);
export const archiveConversationSuccess = createAction(ARCHIVE_CONVERSATION_SUCCESS);
export const archiveConversationFailure = createAction(ARCHIVE_CONVERSATION_FAILURE);

export const unarchiveConversationRequest = createAction(UNARCHIVE_CONVERSATION_REQUEST);
export const unarchiveConversationSuccess = createAction(UNARCHIVE_CONVERSATION_SUCCESS);
export const unarchiveConversationFailure = createAction(UNARCHIVE_CONVERSATION_FAILURE);
