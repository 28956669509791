import { createAction } from 'redux-actions';
import {
    FEATURED_TOP_REVIEW_FAILURE,
    FEATURED_TOP_REVIEW_SUCCESS,
    TOP_REVIEWS_FAILURE,
    TOP_REVIEWS_REQUEST,
    TOP_REVIEWS_SERVICE_FAILURE,
    TOP_REVIEWS_SERVICE_SUCCESS,
    TOP_REVIEWS_SUCCESS,
} from './constants';

export const topReviewRequest = createAction(TOP_REVIEWS_REQUEST);
export const topReviewSuccess = createAction(TOP_REVIEWS_SUCCESS);
export const topReviewFailure = createAction(TOP_REVIEWS_FAILURE);

export const featuredTopReviewSuccess = createAction(FEATURED_TOP_REVIEW_SUCCESS);
export const featuredTopReviewFailure = createAction(FEATURED_TOP_REVIEW_FAILURE);

export const topReviewServiceSuccess = createAction(TOP_REVIEWS_SERVICE_SUCCESS);
export const topReviewServiceFailure = createAction(TOP_REVIEWS_SERVICE_FAILURE);
