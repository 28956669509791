import createRequestActionStrings from '../../utils/createRequestActionStrings';

export const [TOP_REVIEWS_SUCCESS, TOP_REVIEWS_REQUEST, TOP_REVIEWS_FAILURE] =
    createRequestActionStrings('TOP_REVIEWS');

export const [FEATURED_TOP_REVIEW_REQUEST, FEATURED_TOP_REVIEW_SUCCESS, FEATURED_TOP_REVIEW_FAILURE] =
    createRequestActionStrings('FEATURED_TOP_REVIEW');

export const [TOP_REVIEWS_SERVICE_SUCCESS, TOP_REVIEWS_SERVICE_FAILURE] =
    createRequestActionStrings('TOP_REVIEWS_SERVICE');
